import styled, { css, createGlobalStyle, keyframes } from 'styled-components';

import { lighten } from 'polished';

import Tooltip from '../Tooltip';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  noMargin?: boolean;
  isErrored: boolean;
  inactive?: boolean;
  display?: number;
}

export const Container = styled.div<ContainerProps>`
  span {
    color: #f00;
  }

  span.loader svg {
    color: #000b3a !important;
    animation: ${rotate} 0.5s linear infinite;
    display: inline-block;
    position: inline-block;
    font-size: 18px;
  }

  .moneySymbol {
    font-weight: 700;
    margin-right: 16px;
    color: #455671 !important;
  }

  width: 100%;
  padding: 15px;
  color: #455571;

  font-size: 15px;
  border-radius: 5px;
  margin-top: ${props => (props.noMargin ? '' : '20px')};

  background-color: ${props => (props.inactive ? '#f2f2f2' : '#fff')};
  border: 1px solid ${lighten(0.4, '#455571')};
  box-shadow: 0px 3px 17px rgba(44, 40, 40, 0.11);

  transition: border 0.6s ease, color 0.6s ease;
  display: ${props => (props.display === 0 ? 'none' : 'flex')};

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030 !important;

      /* input {
        color: #000b3a;
      } */
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #11cdef;
      border-color: #11cdef !important;

      input {
        color: #000b3a !important;
      }
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #11cdef;
      border-color: #455571;

      input {
        color: #000b3a;
      }
    `}



  input {
    flex: 1;
    background: transparent;
    border: 0;
    transition: color 0.6s ease;
    max-width: calc(100% - 23px);
  }

  svg {
    margin-right: 16px;
    min-width: 20px;
    /* color: #455571; */
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
    color: #c53030;
    font-size: 20px;
    cursor: pointer;
  }

  span {
    background: #c53030;
    color: #fff;

    &:before {
      border-color: #c53030 transparent;
    }
  }
`;

export default createGlobalStyle`
  .invisible {
    display: none !important;
  }
`;
