/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

interface ContainerProps {
  current?: number;
  color?: string;
}

export const Container = styled.li<ContainerProps>`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative !important;

  a {
    text-decoration: none !important;
    padding: 15px 15px;
    display: flex;
    align-items: center;
    width: 100% !important;
    border-radius: 5px;
    transition: background-color 0.7s ease;
    margin: 5px 0;

    ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    justify-content: center;
    padding: 15px 15px;
  `}

    ${media.lessThan('medium')`
        /* screen width is less than 768px (medium) */
        `}

        @media (max-width: 1000px) {
          display:flex !important;
          align-items: center !important;
          justify-content: center;
      }



    &:hover {
      background-color: #e6f3ff;
      @media (max-width: 1000px) {
        background-color: #070f33 !important;
      }
    }

    ${props =>
      props.current === 1 &&
      css`
        background-color: #e6f3ff;
        @media (max-width: 1000px) {
          background-color: #070f33 !important;
        }
        transition: background-color 2s ease;
      `}

    span {
      padding-left: 8px;
      font-size: 14px;
      color: #172b4d;
    }
  }

  svg {
    ${props =>
      props.color !== undefined &&
      css`
        color: ${props.color} !important;
      `}
    font-size: 22px;
  }

`;

export const test = styled.div``;
