/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { keyframes, css } from 'styled-components';

interface ContainerProps {
  backgroundColor?: string;
  disabled: boolean;
  loading: number;
  success: number;
  linkValidation: number;
  textColor?: string;
  backgroundHoverColor?: string;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadIcon = styled.div`
  svg {
    display: inline-block;
    animation: ${rotate} 1.3s linear infinite;
    position: inline-block;
  }
`;

export const Container = styled.button<ContainerProps>`
  width: 254px;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px 0;

  ${props =>
    props.linkValidation === 0 &&
    css`
      background: #c53030 !important;
      border-color: #c53030 !important;
      cursor: default !important;
      &:hover {
        color: #fff !important;
      }
    `}

  ${props =>
    props.success === 1 &&
    css`
      background: #00b583 !important;
      border-color: #00a376 !important;
      cursor: default !important;
      transition: background 0.5s linear, color 0.5s linear,
        border-radius 0.3s ease-in 0.1s, width 0.3s linear, padding 0s linear !important;
      color: #fff !important;
      padding: 10px 13px 4px 11px !important;
      font-size: 28px;
      border-radius: 50%;
      cursor: default !important;
      width: 52px !important;

      &:hover {
        background: #00b583 !important;
        border-color: #00a376 !important;
        cursor: default !important;
        color: #fff !important;
      }
    `}

  ${props =>
    props.loading === 1 &&
    css`
      transition: background 0.5s linear, color 0.5s linear,
        border-radius 0.3s ease-in 0.1s, width 0.3s linear, padding 0s linear !important;
      background-position: left;
      color: #fff !important;
      padding: 10px 13px 4px 11px !important;
      font-size: 28px;
      /* border-radius: 50%; */
      cursor: default !important;
      /* width: 52px !important; */

      &:hover {
        color: #fff !important;
        background-position: right !important;
      }
    `}


  background-size: 400% 250%;
  background-position: right bottom;
  /* background: linear-gradient(to top left, #000b3a 50%, #11cdef 50%); */
  background: #000b3a;
  border: 1px solid #000b3a;

  transition: background 0.5s linear, color 0.5s linear,
    border-radius 0.1s linear, width 0.3s linear 0.1s, padding 0s linear;

  ${props =>
    props.backgroundColor !== null &&
    css`
      background: ${props.backgroundColor};
      border-color: ${props.backgroundColor};
    `}

  color: ${props => (props.textColor ? props.textColor : '#eee')};

  &:hover {
    /* background-position: left; */
    color: #fff;
    background: ${props =>
      props.backgroundHoverColor ? props.backgroundHoverColor : '#000b3a'};
  }
`;
