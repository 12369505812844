import { Reducer } from 'redux';
// import produce from 'immer';
import { IOrderProgressState } from './types';

const ordersReveal = localStorage.getItem('@Hub:ordersReveal');

const reveal = ordersReveal ? JSON.parse(ordersReveal) : true;

const INITIAL_STATE: IOrderProgressState = {
  items: [],
  reveal,
};

const orderProgress: Reducer<IOrderProgressState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case 'CHANGE_ORDERS_REVEAL': {
      // return produce(state, draft => {
      //   draft.reveal;
      // });
      localStorage.setItem('@Hub:ordersReveal', JSON.stringify(!state.reveal));
      return {
        ...state,
        reveal: !state.reveal,
      };
    }
    default: {
      return state;
    }
  }
};

export default orderProgress;
