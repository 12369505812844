/* eslint-disable react/require-default-props */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { BiLoaderAlt } from 'react-icons/bi';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

// eslint-disable-next-line import/no-unresolved
import { Container, Error } from './styles';
import {
  cep,
  cnpj,
  phone,
  name as nameMaske,
  date,
  time,
  money,
  number,
} from './mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  noMargin?: boolean;
  mask?:
    | 'cep'
    | 'phone'
    | 'name'
    | 'date'
    | 'time'
    | 'money'
    | 'number'
    | 'cnpj';
  display?: boolean;
  required?: boolean;
  invisible?: boolean;
  cepLoad?: boolean;
  inactive?: boolean;
}

const Input: React.FC<InputProps> = ({
  required,
  mask,
  noMargin,
  name,
  display,
  cepLoad,
  inactive,
  invisible,
  icon: Icon,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleKeyUp = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (mask === 'cep') {
        cep(e);
      }
      if (mask === 'phone') {
        phone(e);
      }
      if (mask === 'name') {
        nameMaske(e);
      }
      if (mask === 'date') {
        date(e);
      }
      if (mask === 'time') {
        time(e);
      }
      if (mask === 'money') {
        money(e);
      }
      if (mask === 'number') {
        number(e);
      }
      if (mask === 'cnpj') {
        cnpj(e);
      }
    },
    [mask],
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      display={display || display === undefined ? 1 : 0}
      inactive={inactive}
      noMargin={noMargin}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      {...(invisible ? "className='InVisible'" : '')}
    >
      {Icon && mask !== 'money' && <Icon size={20} />}
      {mask === 'money' && <span className="moneySymbol">R$</span>}

      <input
        onFocus={handleInputFocus}
        name={name}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        onKeyUp={handleKeyUp}
        ref={inputRef}
        {...rest}
      />

      {cepLoad && (
        <span className="loader">
          <BiLoaderAlt />
        </span>
      )}

      {required ? <span>*</span> : ''}

      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};

export default Input;
