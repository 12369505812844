/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';

import { IUsersInfoState, IUser } from './types';

const INITIAL_STATE: IUsersInfoState = {
  pendingUsers: 0,
  loading: true,
  aprovingUser: false,
  reprovingUser: true,
  editUser: true,
};

const usersInfo: Reducer<IUsersInfoState> = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line consistent-return
  return produce(state, draft => {
    function filterByActive(obj: IUser): boolean {
      if (obj.active === false && obj.reproved === false) {
        return true;
      }
      return false;
    }

    switch (action.type) {
      case 'ADD_USERS_INFO': {
        const { apiUsersInfo } = action.payload;

        const pedingUsersFilter = apiUsersInfo.filter(filterByActive);

        // eslint-disable-next-line no-param-reassign
        draft.aprovingUser = true;
        draft.loading = false;
        draft.pendingUsers = pedingUsersFilter.length;
        draft.users = apiUsersInfo;

        break;
      }

      case 'EDIT_USER': {
        const { editUserInfo } = action.payload;

        draft.userEditProfile = editUserInfo;

        break;
      }

      case 'OPEN_APROVE_USERS': {
        draft.aprovingUser = false;
        break;
      }

      case 'CLOSE_APROVE_USERS': {
        draft.aprovingUser = true;
        break;
      }

      case 'OPEN_REPROVE_USERS': {
        draft.reprovingUser = false;
        break;
      }

      case 'CLOSE_REPROVE_USERS': {
        draft.reprovingUser = true;
        break;
      }

      case 'OPEN_EDIT_USERS': {
        draft.editUser = false;
        break;
      }

      case 'CLOSE_EDIT_USERS': {
        draft.editUser = true;
        break;
      }

      case 'REMOVE_USERS_INFO': {
        break;
      }

      default: {
        break;
      }
    }
  });
};

export default usersInfo;
