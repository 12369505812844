import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Content = styled.div`
  /* max-width: 100%; */
  padding: 40px;

  .softcap-badge {
    top: -28px !important;
    right: -18px !important;
  }
`;

export const LoadingStatus = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f9;

  svg {
    animation: ${rotate} 1.3s linear infinite;
    color: #000b3a;
    font-size: 80px;
  }
`;

export const display = styled.div`
  /* max-width: 100%; */
  padding: 40px;
`;

export const Table = styled.table`
  margin-top: 30px;
  background-color: #fff;
  width: 100%;
  padding: 20px 0px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(44, 40, 40, 0.11) !important;
  border-spacing: 0px !important;

  tr:first-child {
    background-color: #f1f3f9;
  }

  th {
    border: none !important;
    font-size: 11px;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    padding: 5px 20px;
  }
`;
