/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { ButtonHTMLAttributes } from 'react';
import { ImSpinner2 } from 'react-icons/im';
import { FaCheck } from 'react-icons/fa';

import { Container, LoadIcon } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  success?: boolean;
  linkValidation?: boolean;
  isResetPassword?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  linkValidation,
  isResetPassword,
  success,
  ...rest
}) => (
  <Container
    success={Number(success)}
    loading={loading ? Number(loading) : 0}
    linkValidation={Number(linkValidation)}
    disabled={
      loading
        ? true
        : linkValidation === false && isResetPassword === true
        ? true
        : success || false
    }
    type="button"
    {...rest}
  >
    {loading ? (
      <LoadIcon>
        <ImSpinner2 />
      </LoadIcon>
    ) : success ? (
      <FaCheck />
    ) : linkValidation === false && isResetPassword === true ? (
      'Link inválido'
    ) : (
      children
    )}
  </Container>
);

export default Button;
