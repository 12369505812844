/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import { CgMenuRight, CgMenuLeft } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FiMenu } from 'react-icons/fi';
import { Container, Logo, LogoImg, LogoMenu, MainMenu } from './styles';

import UserMenu from './Lists/UserMenu';
import AdminMenu from './Lists/AdminMenu';

import logoImg from '../../../assets/logo-hub-dark.svg';
import { IState } from '../../../store';
import { IUserInfoState } from '../../../store/modules/user/types';
import { Link } from 'react-router-dom';

const Menu: React.FC = () => {
  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);
  const toggleUserMenuStatus = localStorage.getItem(
    '@Hub:toggleUserMenuStatus',
  );

  let toggleUserMenuResult = 'true';

  if (toggleUserMenuStatus) {
    toggleUserMenuResult = toggleUserMenuStatus;
  } else {
    toggleUserMenuResult = 'true';
  }

  const toggleUserMenu = JSON.parse(toggleUserMenuResult);

  const [menuToggle, setMenuToggle] = useState(toggleUserMenu);
  const [menuReveal, setMenuReveal] = useState(false);

  const toggleMenu = (): void => {
    setMenuToggle(!toggleUserMenu);
    localStorage.setItem(
      '@Hub:toggleUserMenuStatus',
      JSON.stringify(!toggleUserMenu),
    );
  };

  const path = window.location.pathname;
  let pageLink = path.split('/')[2];

  if (pageLink === '' || pageLink === undefined) {
    pageLink = 'home';
  }

  return (
    <Container menuToggle={!menuToggle ? 1 : 0}>

        <Logo menuToggle={!menuToggle ? 1 : 0}>
          <Link to="/">
          <LogoImg menuToggle={!menuToggle ? 1 : 0}>
            <img src={logoImg} alt="Logo Hub" />
          </LogoImg>
          </Link>
          <LogoMenu
            className="menuToggle"
            onClick={toggleMenu}
            menuToggle={!menuToggle ? 1 : 0}
          >
            {!menuToggle ? <CgMenuRight /> : <CgMenuLeft />}
          </LogoMenu>

          <LogoMenu
            className="menuReveal"
            onClick={() => setMenuReveal(!menuReveal)}
            menuToggle={!menuToggle ? 1 : 0}
          >
            <FiMenu />
          </LogoMenu>
        </Logo>

      <MainMenu
        className={`${
          menuReveal ? 'displaymenu' : 'hidemenu'
        } animated-toglemenu`}
        menuToggle={!menuToggle ? 1 : 0}
      >
        {userData.user.permission > 1 ? (
          <AdminMenu />
        ) : userData.user.permission === 1 ? (
          <UserMenu />
        ) : (
          <>
            <SkeletonTheme color="#ddd" highlightColor="#eee">
              <Skeleton count={5} height={50} />
            </SkeletonTheme>
          </>
        )}
      </MainMenu>
    </Container>
  );
};

export default Menu;
