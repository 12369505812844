/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';

import { IUser, IUserInfoState } from './types';

const userInfoStorage = localStorage.getItem('@Hub:user');

const storageUser: IUser = userInfoStorage ? JSON.parse(userInfoStorage) : true;

const INITIAL_STATE: IUserInfoState = {
  user: storageUser,
  loading: true,
};

const userInfo: Reducer<IUserInfoState> = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line consistent-return
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_USER_INFO': {
        const { apiUserInfo } = action.payload;

        draft = {
          loading: false,
          user: apiUserInfo,
        };

        return draft;
      }
      case 'REMOVE_USER_INFO': {
        draft = {
          user: storageUser,
          loading: false,
        };

        return draft;
      }

      default: {
        // return INITIAL_STATE;
        break;
      }
    }
  });
};

export default userInfo;
