/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  AllHTMLAttributes,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { Container, Divisor } from './styles';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import SimpleButton from '../../../../SimpleButton';
import Modal from '../../../../Modal';
import { addSpecificListInfo } from '../../../../../store/modules/specificList/actions';

type IModal = AllHTMLAttributes<HTMLAllCollection> & {
  modalStatus: boolean;
  productId: string;
  listId: string;
};

const DeleteProduct: React.FC<IModal> = ({
  modalStatus,
  productId,
  listId,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('use effect alterar estado chamado');
    const modalChange = async (): Promise<void> => {
      if (modalStatus) {
        await setModal(true);
        await setModal(false);
      }
    };

    modalChange();
  }, [modalStatus]);

  const handleSubmit = useCallback(
    async (id: string) => {
      setLoading(true);
      await api.delete(`/list/product?id=${id}`).then(async () => {
        toast.success(`Produto deletado com sucesso!`);
        setModal(true);
        await api.get(`/list?id=${listId}`).then(obj => {
          // console.log(obj.data);
          dispatch(addSpecificListInfo(obj.data));
          window.location.reload();
        });
      });
      await setLoading(false);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast],
  );
  return (
    <Modal title="Deletar Produto" modalSize="50%" modalClose={modal} closable>
      <Container>
        <Form ref={formRef} onSubmit={() => handleSubmit(productId)}>
          <h3 className="modal-question text-center">
            Deseja realmente deletar este produto?
          </h3>
          <Divisor className="modal-form_button-divisor">
            <div className="modal-simplebuttons">
              <div>
                <SimpleButton
                  className="modal-simplebutton"
                  loading={loading}
                  type="submit"
                >
                  Deletar
                </SimpleButton>
              </div>
              <div>
                <button
                  type="button"
                  className="modalbutton-cancelar"
                  onClick={() => setModal(true)}
                >
                  não, cancelar.
                </button>
              </div>
            </div>
          </Divisor>
        </Form>
      </Container>
    </Modal>
  );
};

export default DeleteProduct;
