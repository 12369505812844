import React, { createContext, ReactNode, useState } from 'react';

interface ListProductsProviderProps {
  children: ReactNode;
}

interface ListProductsContextData {
  listProductsArray: any;
  generateListProducts: (listProducts: any) => void;
}

export const ListProductsContext = createContext({} as ListProductsContextData);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function ListProductsProvider({ children }: ListProductsProviderProps) {
  const [listProductsArray, setListProductsArray] = useState([]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const generateListProducts = (listProducts: any) => {
    setListProductsArray(listProducts);
    // console.log("context atualizado")
  };

  return (
    <ListProductsContext.Provider
      value={{ listProductsArray, generateListProducts }}
    >
      {children}
    </ListProductsContext.Provider>
  );
}
