/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadIcon = styled.div``;

export const Container = styled.div`
  background: none;
  border: none;
  margin-right: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      animation: ${rotate} 1.3s linear infinite;
    }
  }

  &:hover {
    color: #000b3a;
  }
`;
