/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { HtmlHTMLAttributes, useEffect, useState } from 'react';

import { Container } from './styles';

type DivProps = HtmlHTMLAttributes<HTMLDivElement> & {
  title: string;
  color?: string;
  textColor?: string;
  modalClose?: boolean;
  closable?: boolean;
  modalSize?: string;
  isAprove?: boolean;
};

const Modal: React.FC<DivProps> = ({
  title,
  color,
  children,
  textColor,
  modalClose,
  modalSize,
  isAprove,
  closable = true,
  ...rest
}) => {
  const [closeModal, setCloseModal] = useState(modalClose);

  const changeModalState = (): void => {
    setCloseModal(!closeModal);
  };

  useEffect(() => {
    setCloseModal(modalClose);
  }, [modalClose]);

  return (
    <Container
      isAprove={isAprove ? 1 : 0}
      closeModal={Number(closeModal)}
      badgeColor={color}
      textColor={textColor}
      modalSize={modalSize}
      {...rest}
    >
      {closable && (
        <button
          onClick={() => changeModalState()}
          type="button"
          className="softcap-modal_background-close"
        >
          {' '}
        </button>
      )}
      <div className="softcap-modal" role="button">
        <div className="softcap-modal_title">
          <h3>{title}</h3>
          {closable && (
            <button
              type="button"
              onClick={() => changeModalState()}
              className="softcap-modal_close-button"
            >
              X
            </button>
          )}
        </div>
        <div className="softcap-modal_content">{children}</div>
      </div>
    </Container>
  );
};

export default Modal;
