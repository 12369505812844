/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  AllHTMLAttributes,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { useDispatch } from 'react-redux';

import { FiBox, FiCalendar, FiClock } from 'react-icons/fi';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { Container, Divisor } from './styles';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import SimpleButton from '../../../../SimpleButton';
import Modal from '../../../../Modal';
import Input from '../../../../Input';
import { addListInfo } from '../../../../../store/modules/list/actions';

type IModal = AllHTMLAttributes<HTMLAllCollection> & {
  modalStatus: boolean;
};

const AddList: React.FC<IModal> = ({ modalStatus }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const modalChange = async (): Promise<void> => {
      if (modalStatus) {
        await setModal(true);
        await setModal(false);
      }
    };

    modalChange();
  }, [modalStatus]);

  const handleSubmit = useCallback(
    async (data): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome do lote é obrigatório'),
          startDate: Yup.string().required('A Data de Inicio é obrigatória'),
          endDate: Yup.string().required('A Data de termino é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const startTimeSplit = data.startTime.split(':');
        const endTimeSplit = data.endTime.split(':');

        if (!data.startTime) {
          data.startTime = '00:00';
        }

        if (!data.endTime) {
          data.endTime = '00:00';
        }

        const sSDate = data.startDate.split('/');
        const sEDate = data.endDate.split('/');

        if (
          Number(sSDate[0]) > 31 ||
          Number(sSDate[1] > 12) ||
          Number(sEDate[0]) > 31 ||
          Number(sEDate[1] > 12)
        ) {
          addToast({
            type: 'alert',
            title: `Informe uma data válida`,
          });
          throw new Error('erro de data');
        }

        if (
          Number(startTimeSplit[0]) > 23 ||
          Number(startTimeSplit[1] > 59) ||
          Number(endTimeSplit[0]) > 23 ||
          Number(endTimeSplit[1] > 59)
        ) {
          addToast({
            type: 'alert',
            title: `Informe um horário valido`,
          });
          throw new Error('erro de tempo');
        }

        const finalStartDate = sSDate[2].concat(
          '-',
          sSDate[1],
          '-',
          sSDate[0],
          ' ',
          data.startTime,
          ':00',
        );

        const finalEndDate = sEDate[2].concat(
          '-',
          sEDate[1],
          '-',
          sEDate[0],
          ' ',
          data.endTime,
          ':00',
        );

        const apiRequestBody = {
          name: data.name,
          start: finalStartDate,
          end: finalEndDate,
          groups: 'sem grupo',
        };

        await api.post(`/list`, apiRequestBody).then(async function editList() {
          await api.get(`/list/lists`).then(lists => {
            dispatch(addListInfo(lists.data));
          });

          setModal(true);

          addToast({
            type: 'success',
            title: `Lista '${data.name}' criada com sucesso`,
          });
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let description;

          if (errors.name) {
            description = errors.name;
          }
          if (errors.startDate) {
            description = errors.startDate;
          }

          if (errors.endDate) {
            description = errors.endDate;
          }

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }
          // const errorMessage = err.response.data.message
          // if (
          //   errorMessage === 'the start date must come before the end date'
          // ) {
          //   addToast({
          //     type: 'alert',
          //     title: 'A data de termino não pode ser anterior a data de inicio',
          //   });
          // }
          // if (
          //   errorMessage === "You can't create an List with end/start in a past date"
          // ) {
          //   addToast({
          //     type: 'alert',
          //     title: 'Você não pode criar uma lista no passado',
          //   });
          // }
      } finally {
        setLoading(false);
      }
    },
    [addToast, dispatch],
  );

  return (
    <Modal
      title="Adicionar nova lista"
      modalSize="50%"
      modalClose={modal}
      closable
    >
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Divisor>
            <div className="w-100">
              <Input name="name" icon={FiBox} placeholder="Nome" />
              <Input
                name="groups"
                icon={FiBox}
                placeholder="groups"
                value="sem grupo"
                display={false}
              />
            </div>
            <div className="w-100" style={{ marginTop: 20 }}>
              <h3>Data e Hora de inicio:</h3>
            </div>
            <div className="double">
              <Input
                name="startDate"
                mask="date"
                icon={FiCalendar}
                placeholder="Data de começo"
              />
            </div>
            <div className="double">
              <Input
                name="startTime"
                icon={FiClock}
                mask="time"
                placeholder="Horário de começo"
              />
            </div>
            <div className="w-100" style={{ marginTop: 20 }}>
              <h3>Data e Hora de termino:</h3>
            </div>
            <div className="double">
              <Input
                name="endDate"
                icon={FiCalendar}
                mask="date"
                placeholder="Data de fim"
              />
            </div>
            <div className="double">
              <Input
                name="endTime"
                icon={FiClock}
                mask="time"
                placeholder="Horário de fim"
              />
            </div>
          </Divisor>
          <Divisor className="modal-form_button-divisor">
            <div className="modal-simplebuttons">
              <div>
                <SimpleButton
                  className="modal-simplebutton"
                  loading={loading}
                  type="submit"
                >
                  CONFIRMAR
                </SimpleButton>
              </div>
            </div>
          </Divisor>
        </Form>
      </Container>
    </Modal>
  );
};

export default AddList;
