import styled from 'styled-components';

export const Content = styled.div`
  /* max-width: 100%; */
  padding: 40px;
`;

export const display = styled.div`
  /* max-width: 100%; */
  padding: 40px;
`;
