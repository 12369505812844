import styled, { css, createGlobalStyle } from 'styled-components';

import { lighten } from 'polished';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  span {
    color: #f00;
  }

  width: 100%;
  padding: 15px;
  color: #455571;

  font-size: 15px;
  border-radius: 5px;
  margin-top: 20px;

  background-color: #fff;
  border: 1px solid ${lighten(0.4, '#455571')};
  box-shadow: 0px 3px 17px rgba(44, 40, 40, 0.11);

  transition: border 0.6s ease, color 0.6s ease;
  display: flex;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030 !important;

      /* input {
        color: #000b3a;
      } */
    `}

  select {
    flex: 1;
    background: transparent;
    border: 0;
    transition: color 0.6s ease;
  }

  svg {
    margin-right: 16px;
    /* color: #455571; */
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
    color: #c53030;
    font-size: 20px;
    cursor: pointer;
  }

  span {
    background: #c53030;
    color: #fff;

    &:before {
      border-color: #c53030 transparent;
    }
  }
`;

export default createGlobalStyle`
  .invisible {
    display: none !important;
  }
`;
