/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { IUser } from './types';

export function addUserInfo(apiUserInfo: IUser | undefined) {
  return {
    type: 'ADD_USER_INFO',
    payload: {
      apiUserInfo,
    },
  };
}

export function removeUserInfo() {
  return {
    type: 'REMOVE_USER_INFO',
  };
}
