/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex, Icon, Text, theme, Tooltip } from '@chakra-ui/react';
import { FaRegCalendarAlt } from 'react-icons/fa';

import Chart from 'react-apexcharts';
import { OrdersContext } from '../../../../context/Orders';

interface IOrdersGraphProps {
  monthName: string;
}

export function OrdersGraph({ monthName }: IOrdersGraphProps) {
  const [currentYearArray, setCurrentYearArray] = useState([0, 0, 0, 0, 0]);
  const [lastYearArray, setLastYearArray] = useState([0, 0, 0, 0, 0]);
  const [monthOrdersQtd, setMonthOrdersQtd] = useState(0);
  const { orders, currentMonth, currentYear } = useContext(OrdersContext);

  useEffect(() => {
    const day5 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() === new Date().getFullYear() &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() <= 5
      );
    });
    const day10 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() === new Date().getFullYear() &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 5 &&
        new Date(order.created_at).getDate() <= 10
      );
    });
    const day15 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() === new Date().getFullYear() &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 10 &&
        new Date(order.created_at).getDate() <= 15
      );
    });
    const day20 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() === new Date().getFullYear() &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 15 &&
        new Date(order.created_at).getDate() <= 20
      );
    });
    const day25 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() === new Date().getFullYear() &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 20 &&
        new Date(order.created_at).getDate() <= 25
      );
    });
    const day31 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() === new Date().getFullYear() &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 25 &&
        new Date(order.created_at).getDate() <= 31
      );
    });

    setCurrentYearArray([
      Number(day5?.length),
      Number(day10?.length),
      Number(day15?.length),
      Number(day20?.length),
      Number(day25?.length),
      Number(day31?.length),
    ]);
  }, [orders]);

  useEffect(() => {
    const monthOrders = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() === new Date().getFullYear() &&
        new Date(order.created_at).getMonth() === new Date().getMonth()
      );
    });

    setMonthOrdersQtd(Number(monthOrders?.length));
  }, []);

  useEffect(() => {
    const day5 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() ===
          new Date().getFullYear() - 1 &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() <= 5
      );
    });
    const day10 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() ===
          new Date().getFullYear() - 1 &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 5 &&
        new Date(order.created_at).getDate() <= 10
      );
    });
    const day15 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() ===
          new Date().getFullYear() - 1 &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 10 &&
        new Date(order.created_at).getDate() <= 15
      );
    });
    const day20 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() ===
          new Date().getFullYear() - 1 &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 15 &&
        new Date(order.created_at).getDate() <= 20
      );
    });
    const day25 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() ===
          new Date().getFullYear() - 1 &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 20 &&
        new Date(order.created_at).getDate() <= 25
      );
    });
    const day31 = orders?.filter((order: any) => {
      return (
        new Date(order.created_at).getFullYear() ===
          new Date().getFullYear() - 1 &&
        new Date(order.created_at).getMonth() === currentMonth &&
        new Date(order.created_at).getDate() > 25 &&
        new Date(order.created_at).getDate() <= 31
      );
    });

    setLastYearArray([
      Number(day5?.length),
      Number(day10?.length),
      Number(day15?.length),
      Number(day20?.length),
      Number(day25?.length),
      Number(day31?.length),
    ]);
  }, [orders]);

  const options = {
    colors: ['#008FA0', '#fff'],
    chart: {
      width: '100%',
      stacked: false,
      id: 'basic-bar',
      stroke: {
        curve: 'smooth',
      },
      toolbar: {
        show: false,
      },
      // zoom: {
      //   enable: false,
      // },
      foreColor: theme.colors.white,
    },
    grid: {
      show: true,
      borderColor: theme.colors.whiteAlpha[500],
      strokeDashArray: 5,

      xaxis: {
        lines: {
          show: true,
          opacity: 0.2,
        },
      },
      yaxis: {
        lines: {
          show: true,
          opacity: 0.2,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      inverseOrder: true,
    },
    xaxis: {
      axisBorder: {
        color: theme.colors.transparent,
      },
      axisTicks: {
        // color: theme.colors.transparent
      },
      categories: [
        'até 5',
        '6 a 10',
        '11 a 15',
        '16 a 20',
        '21 a 25',
        '26 a 31',
      ],
    },
    fill: {
      // opacity: 0,
      gradient: {
        shade: 'dark',
        opacityFrom: 0.7,
        opacityTo: 0.3,
      },
    },
    // legend: {
    //   show: false,
    // }
  };

  const series = [
    { name: 'Ano anterior', data: lastYearArray },
    { name: 'Atual', data: currentYearArray },
  ];

  return (
    <Flex w="100%" minH="200px" h="100%" position="relative" pt={30}>
      {/* {monthOrdersQtd < 5 &&
      <Flex
          bg="blackAlpha.700"
          color="white"
          fontWeight="semibold"
          borderRadius="10px"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          w="calc(100% - 20px)"
          minH="100px"
          ml="10px"
          // mr="10px"
          top="0"
          left="0"
          right="0"
          bottom="40px"
          zIndex={2}
        >
          Aguardando mais pedidos para gerar o grafico
        </Flex>
      } */}
      <Box
        position="absolute"
        zIndex="2"
        bg="blue.500"
        bgGradient="linear(to-r, #1ABED2, #01ACC1)"
        w="calc(100% - 20px)"
        p={0}
        minH="100px"
        ml="20px"
        mr="20px"
        top="0"
        left="0"
        right="0"
        bottom="40px"
        borderRadius="10px"
        margin="0 auto"
      >
        <Chart options={options} series={series} type="area" height={150} />
      </Box>

      <Flex
        w="100%"
        h="100%"
        alignItems="flex-end"
        justifyContent="space-between"
        fontSize={11}
        p={4}
        pb={3}
        bg="white"
        borderRadius="10px"
        boxShadow="lg"
      >
        <Text display="flex" alignItems="center" color="gray.600">
          <Icon as={FaRegCalendarAlt} fontSize={15} mr={2} />
          {monthName}
        </Text>
        <Text>X: dias do mês | Y: quantidade de pedido</Text>
        <Text display="flex" color="gray.600">
          Volume de pedidos
        </Text>
      </Flex>
    </Flex>
  );
}
