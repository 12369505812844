import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .tamanho {
    max-width: 15% !important;
    min-width: 15% !important;
  }

  .addproducts .double,
  .addproducts .third {
    div {
      margin-top: 2px;
    }

    h5 {
      margin-top: 10px;
      margin-bottom: 4px;
    }
  }

  .third {
    max-width: 31%;
    min-width: 31%;
  }

  .selectedProduct {
    background-color: #3b80ff;
    color: #fff;
    font-weight: 600;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-radius: 5px;

    span {
      display: flex;
      align-items: center;

      svg {
        margin-right: 20px;
      }
    }

    button {
      border-radius: 50px;
      background-color: #fff;
      border: 1px solid #001741;
      color: #001741;
      cursor: pointer;
      padding: 3px 10px;
      /* width: 30px; */
      height: 30px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 6px;
      }
    }
  }

  .productsListTitle {
    margin-top: 30px;
    padding: 20px 20px 0 20px;
    min-width: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    min-width: 100%;
    font-weight: 600;

    li {
      min-width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: left;

      div {
        max-width: 24%;
        min-width: 24%;
      }
    }
  }

  .productsList {
    margin-top: 20px;
    padding-top: 0px;
    padding: 20px;
    min-width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 300px;
    overflow: scroll;

    button {
      min-width: 100%;
      text-decoration: none !important;
      background-color: transparent;
      border: none;
    }

    button li {
      min-width: 100%;
      border: 1px solid #ddd;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 15px;
      text-decoration: none !important;
      color: #3b80ff !important;
      cursor: pointer;
      font-size: 14px;
    }

    li div {
      max-width: 24%;
      min-width: 24%;
      text-align: center;
      text-align: left;
    }

    button:hover li {
      background-color: #3b80ff !important;
      color: #fff !important;
    }

    button li div {
      text-decoration: none !important;
    }

    > li {
      padding: 15px;
      min-width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
`;

export const Divisor = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.double {
    width: 48% !important;
  }

  .new-company {
    margin-top: 5px;
    border: none;
    background: none;
    text-decoration: underline;
    color: #0074b8;
    margin-top: 15px;
  }
`;
