/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Flex } from '@chakra-ui/react';
import MUIDataTable from 'mui-datatables';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';

import { HiEye } from 'react-icons/hi';
import { options, columns } from './tableConfig';
import ActionButton from '../../../Table/ActionButton';
import { OrdersContext } from '../../../../context/Orders';

const columnsConfig = [
  columns.id,
  columns.number,
  columns.autor,
  columns.feitoEm,
  columns.formaPag,
  columns.atualizacao,
  {
    name: '',
    options: {
      filter: false,
      draggable: false,
      searchable: false,
      viewColumns: false,
      customBodyRender: (value: any, tableMeta: any) => {
        return (
          <div className="table-icons">
            <Link
              to={`/admin/order-detail?id=${tableMeta.rowData[0]}`}
              data-tip="Ver Pedido"
            >
              <ActionButton color="#bbb">
                <HiEye />
              </ActionButton>
            </Link>

            <ReactTooltip html />
          </div>
        );
      },
    },
  },
];

export function OrdersTable() {
  const { orders } = useContext(OrdersContext);
  const [orderHistory, setOrderHistory] = useState<any[]>([]);

  useEffect(() => {
    const tablePendentContent =
      orders
        ?.map(function pendentOrder(order: any) {
          const timeZone = 'America/Sao_Paulo';
          const orderDate = format(
            utcToZonedTime(order.created_at, timeZone),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );

          const orderUpdatedDate = format(
            utcToZonedTime(order.updated_at, timeZone),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );

          const orderNumber = order.order_number;
          const rest = 5 - String(orderNumber).length;

          return [
            order.id,
            '0'.repeat(rest > 0 ? rest : 0) + order.order_number,
            order.user_info.company?.name,
            orderDate,
            order.tipo_pagamento_info?.name,
            orderUpdatedDate,
          ];
        })
        .slice(0, 5) ?? [];

    setOrderHistory(tablePendentContent);
  }, []);

  return (
    <Flex
      bg="white"
      borderRadius="10px"
      pt={5}
      justifyContent="space-between"
      flexDir="column"
      boxShadow="lg"
    >
      <MUIDataTable
        title=""
        data={orderHistory}
        columns={columnsConfig}
        options={options}
      />

      <Link to="/admin/orders">
        <Box
          w="100%"
          bg="#000B3A"
          color="white"
          fontWeight="bold"
          textTransform="uppercase"
          textAlign="center"
          fontSize={14}
          p={2}
          borderRadius="0 0 10px 10px"
        >
          Ver todos
        </Box>
      </Link>
    </Flex>
  );
}
