/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MUIDataTable from 'mui-datatables';
// import { useSelector } from 'react-redux';
import { FiEdit, FiImage, FiPlus, FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { formatPrice } from '../../../../utils/format';
import ActionButton from '../../../../components/Table/ActionButton';

import api from '../../../../services/api';

import { Content } from './styles';

import 'react-tabs/style/react-tabs.css';

import Dashboard from '../../../../components/Dashboard';
import TableLoading from '../../../../components/Table/Loading';
import Modal from '../../../../components/Modal';
import SimpleButton from '../../../../components/SimpleButton';
import ImageButton from '../../../../components/Table/ImageButton';
import { useToast } from '../../../../hooks/toast';
import { IState } from '../../../../store';
import { IProductsInfoState } from '../../../../store/modules/products/types';
import AddProduct from '../../../../components/Dashboard/Admin/Products/AddProduct';
import EditProduct from '../../../../components/Dashboard/Admin/Products/EditProduct';
import { IUserInfoState } from '../../../../store/modules/user/types';

const Products: React.FC = () => {
  const productsData = useSelector<IState, IProductsInfoState>(
    state => state.productsInfo,
  );

  useEffect(() => {
    !productsData.products ? setTableLoad(true) : setTableLoad(false);
  }, [productsData.products]);

  const [newProductModal, setNewProductModal] = useState<boolean>(false);
  const [editProductModal, setEditProductModal] = useState<boolean>(false);
  const [editProductInfoList, setEditProductInfoList] = useState<[]>([]);

  const [productTypeList, setProductTypeList] = useState<any>([]);
  const [productList, setProductList] = useState<any>([]);
  const [productInfo, setProductInfo] = useState<any>({});
  const [tableLoad, setTableLoad] = useState<boolean>(true);
  const [modalClose2, setModalClose2] = useState<boolean>(true);
  const [modalClose3, setModalClose3] = useState<boolean>(true);
  const [editProductInfo, setEditProductInfo] = useState<any>({});

  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);

  const { addToast } = useToast();

  const handleProductImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // setTableLoad(true);
      if (e.target.files) {
        const data = new FormData();

        data.append('product_image', e.target.files[0]);
        data.append('id', e.target.name);

        api.patch('/product/image', data).then(() => {
          addToast({
            type: 'success',
            title: 'Imagem alterada!',
          });

          setModalClose2(true);

          api.get(`/product/`).then(response => {
            setProductList(response.data);
            // setTableLoad(false);
          });
        });
      }
    },
    [addToast],
  );

  const deleteProduct = (id: string, name: string): void => {
    // setTableLoad(true);
    if (id) {
      api.delete(`/product?id=${id}`).then(() => {
        api.get(`/product/`).then(response => {
          setProductList(response.data);
          // setTableLoad(false);
        });
        setModalClose3(true);
        addToast({
          type: 'success',
          title: `produto "${name}" removida com sucesso!`,
        });
      });
    }
  };

  const deleteProductImage = useCallback(
    (e: any) => {
      // setTableLoad(true);
      if (e) {
        e.persist();
        // // console.log(e.target.id);
        api.delete(`/product/delete-image?id=${e.target.id}`).then(() => {
          api.get(`/product/`).then(response => {
            setProductList(response.data);
            // setTableLoad(false);
          });
          setModalClose2(true);
          addToast({
            type: 'success',
            title: 'Imagem removida com sucesso!',
          });
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    setTableLoad(true);
    api.get(`/product/type`).then(response => {
      setProductTypeList(response.data);
    });

    api.get(`/product/`).then(response => {
      setProductList(response.data);
      setTableLoad(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal2 = (product: any): void => {
    const handleModalClose = async (): Promise<void> => {
      await setEditProductInfo(product);
      await setModalClose2(true);
      await setModalClose2(false);
    };

    handleModalClose();
  };

  const openModal3 = (user: any): void => {
    const handleModalClose = async (): Promise<void> => {
      await setEditProductInfo(user);
      await setModalClose3(true);
      await setModalClose3(false);
    };

    handleModalClose();
  };

  const peixes = productList
    .filter(function usersFilter(obj: any) {
      if (obj.type === 1) {
        return true;
      }
      return false;
    })
    .map(function usersMapping(obj: any) {
      if (!obj.name) {
        obj.name = '-';
      }

      if (!obj.scientific_name) {
        obj.scientific_name = '-';
      }

      if (!obj.family) {
        obj.family = '-';
      }

      if (!obj.size) {
        obj.size = '-';
      }

      return [
        obj.id,
        obj.image_url,
        obj.type,
        obj.family,
        obj.name,
        obj.scientific_name,
        obj.size,
        formatPrice(obj.default_price),
        obj.exclusive,
        // obj.id,
      ];
    });

  const invertebrados = productList
    .filter(function usersFilter(obj: any) {
      if (obj.type === 2) {
        return true;
      }
      return false;
    })
    .map(function usersMapping(obj: any) {
      if (!obj.name) {
        obj.name = '-';
      }

      if (!obj.scientific_name) {
        obj.scientific_name = '-';
      }

      if (!obj.family) {
        obj.family = '-';
      }

      if (!obj.size) {
        obj.size = '-';
      }

      return [
        obj.id,
        obj.image_url,
        obj.type,
        obj.family,
        obj.name,
        obj.scientific_name,
        obj.size,
        formatPrice(obj.default_price),
        obj.exclusive,
      ];
    });

  const corais = productList
    .filter(function usersFilter(obj: any) {
      if (obj.type === 3) {
        return true;
      }
      return false;
    })
    .map(function usersMapping(obj: any) {
      if (!obj.name) {
        obj.name = '-';
      }

      if (!obj.scientific_name) {
        obj.scientific_name = '-';
      }

      if (!obj.family) {
        obj.family = '-';
      }

      if (!obj.size) {
        obj.size = '-';
      }

      return [
        obj.id,
        obj.image_url,
        obj.type,
        obj.family,
        obj.name,
        obj.scientific_name,
        obj.size,
        formatPrice(obj.default_price),
        obj.exclusive,
      ];
    });

  const produtos = productList
    .filter(function usersFilter(obj: any) {
      if (obj.type === 4) {
        return true;
      }
      return false;
    })
    .map(function usersMapping(obj: any) {
      if (!obj.name) {
        obj.name = '-';
      }

      if (!obj.size) {
        obj.size = '-';
      }

      return [
        obj.id,
        obj.image_url,
        obj.type,
        obj.name,
        obj.size,
        formatPrice(obj.default_price),
        obj.exclusive,
      ];
    });

  const productColumns = [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    {
      name: 'image',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    {
      name: 'type',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    'Nome',
    'Tamanho',
    'Ultimo Valor',
    {
      name: '',
      options: {
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div className="table-icons">
              <ActionButton
                color="#e9b300"
                data-tip="Editar"
                onClick={() => handleEditProductModal(tableMeta.rowData)}
              >
                <FiEdit />
              </ActionButton>

              {tableMeta.rowData[1] === null ? (
                <Tooltip
                  // direction="up"
                  content="inserir imagem"
                  tagName="span"
                  // eventOn="onHover"
                  // eventOff="onMouseOut"
                  // useHover={false}
                  className="newOrder_productName"
                >
                  <ImageButton
                    htmlIdFor={`productImageInput${tableMeta.rowData[0]}`}
                    onMouseEnter={
                      () =>
                        setProductInfo({
                          image: tableMeta.rowData[1],
                          id: tableMeta.rowData[0],
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    color="#cccccc"
                  >
                    <FiImage />
                    <input
                      type="file"
                      name={tableMeta.rowData[0]}
                      id={`productImageInput${tableMeta.rowData[0]}`}
                      onChange={handleProductImage}
                    />
                  </ImageButton>
                </Tooltip>
              ) : (
                <Tooltip
                  // direction="up"
                  content={
                    productInfo.image ? (
                      <img src={productInfo.image} alt={productInfo.id} />
                    ) : (
                      'carregando..'
                    )
                  }
                  tagName="span"
                  className="newOrder_productName"
                >
                  <ActionButton
                    onMouseEnter={
                      () =>
                        setProductInfo({
                          image: tableMeta.rowData[1],
                          id: tableMeta.rowData[0],
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    color="#16a500"
                    name={tableMeta.rowData[0]}
                    data-tip={`<img src=${tableMeta.rowData[1]} title=${tableMeta.rowData[4]} class='table-img'><p style='text-align: center;'>(clique para editar imagem)<p>`}
                    onClick={() => openModal2(tableMeta.rowData)}
                  >
                    <FiImage />
                  </ActionButton>
                </Tooltip>
              )}

              <ActionButton
                color="#666"
                data-tip="Excluir"
                onClick={() => openModal3(tableMeta.rowData)}
              >
                <FiTrash2 />
              </ActionButton>
            </div>
          );
        },
      },
    },
  ];

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    {
      name: 'image',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    {
      name: 'type',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    'Familia',
    'Nome',
    'Nome cientifico',
    'Tamanho',
    'Ultimo Valor',
    {
      name: '',
      options: {
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div className="table-icons">
              <ActionButton
                color="#e9b300"
                data-tip="Editar"
                onClick={() => handleEditProductModal(tableMeta.rowData)}
              >
                <FiEdit />
              </ActionButton>

              {tableMeta.rowData[1] === null ? (
                <Tooltip
                  // direction="up"
                  content="inserir imagem"
                  tagName="span"
                  // eventOn="onHover"
                  // eventOff="onMouseOut"
                  // useHover={false}
                  className="newOrder_productName"
                >
                  <ImageButton
                    htmlIdFor={`productImageInput${tableMeta.rowData[0]}`}
                    onMouseEnter={
                      () =>
                        setProductInfo({
                          image: tableMeta.rowData[1],
                          id: tableMeta.rowData[0],
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    color="#cccccc"
                  >
                    <FiImage />
                    <input
                      type="file"
                      name={tableMeta.rowData[0]}
                      id={`productImageInput${tableMeta.rowData[0]}`}
                      onChange={handleProductImage}
                    />
                  </ImageButton>
                </Tooltip>
              ) : (
                <Tooltip
                  // direction="up"
                  content={
                    productInfo.image ? (
                      <img src={productInfo.image} alt={productInfo.id} />
                    ) : (
                      'carregando..'
                    )
                  }
                  tagName="span"
                  className="newOrder_productName"
                >
                  <ActionButton
                    onMouseEnter={
                      () =>
                        setProductInfo({
                          image: tableMeta.rowData[1],
                          id: tableMeta.rowData[0],
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    color="#16a500"
                    name={tableMeta.rowData[0]}
                    data-tip={`<img src=${tableMeta.rowData[1]} title=${tableMeta.rowData[4]} class='table-img'><p style='text-align: center;'>(clique para editar imagem)<p>`}
                    onClick={() => openModal2(tableMeta.rowData)}
                  >
                    <FiImage />
                  </ActionButton>
                </Tooltip>
              )}

              <ActionButton
                color="#666"
                data-tip="Excluir"
                onClick={() => openModal3(tableMeta.rowData)}
              >
                <FiTrash2 />
              </ActionButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
    filterType: 'dropdown' as any,
    // filter: false,
    print: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    customToolbar: () => {
      return (
        <button
          type="button"
          className="table-button_new"
          onClick={handleAddListProductModal}
        >
          <span>
            <FiPlus /> Novo Produto
          </span>
        </button>
      );
    },
    textLabels: {
      body: {
        noMatch: 'Desculpe, nenhum resultado encontrado',
        toolTip: 'Ordenar',
      },
      pagination: {
        next: 'Próxima pagina',
        previous: 'Pagina anterior',
        rowsPerPage: 'Resultados por pagina:',
        displayRows: 'de', // 1-10 of 30
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'Filtros',
        all: 'Todos',
        reset: 'Resetar',
      },
      viewColumns: {
        title: 'Mostrar colunas',
      },
      selectedRows: {
        text: 'resultado(s) detelado(s)',
        delete: 'Deletar',
      },
    },
  };

  const handleAddListProductModal = useCallback(async (id): Promise<void> => {
    await setNewProductModal(false);
    await setNewProductModal(true);
  }, []);

  const handleEditProductModal = useCallback(async (id): Promise<void> => {
    await setEditProductInfoList(id);
    await setEditProductModal(false);
    await setEditProductModal(true);
  }, []);

  return (
    <Dashboard>
      <Content>
        {tableLoad ? (
          <TableLoading />
        ) : (
          <Tabs defaultIndex={0}>
            <TabList>
              {productTypeList
                .filter((type: any) => {
                  if (type.name === 'Produtos') {
                    if (userData.user.permission < 3 && !userData.loading) {
                      return false;
                    }
                  }
                  return true;
                })
                .map(function usersMapping(obj: any) {
                  return <Tab key={obj.id}>{obj.name}</Tab>;
                })}
            </TabList>

            <TabPanel>
              <MUIDataTable
                title=""
                data={peixes}
                columns={columns}
                options={options}
              />
            </TabPanel>
            <TabPanel>
              <MUIDataTable
                title=""
                data={corais}
                columns={columns}
                options={options}
              />
            </TabPanel>
            <TabPanel>
              <MUIDataTable
                title=""
                data={invertebrados}
                columns={columns}
                options={options}
              />
            </TabPanel>
            {userData.user.permission >= 3 && !userData.loading && (
              <TabPanel>
                <MUIDataTable
                  title=""
                  data={produtos}
                  columns={productColumns}
                  options={options}
                />
              </TabPanel>
            )}
          </Tabs>
        )}
      </Content>
      <Modal
        title={`${editProductInfo[3]} (${editProductInfo[4]})`}
        modalClose={modalClose2}
        closable
      >
        <img
          className="modal-product-image"
          src={editProductInfo[1]}
          alt={editProductInfo[3]}
        />
        <div className="modal-imagebuttons">
          <div>
            <form>
              <label htmlFor={`editimagebutton${editProductInfo[0]}`}>
                <span className="modalbutton-image_edit">Alterar imagem</span>
                <input
                  className="hide"
                  type="file"
                  name={editProductInfo[0]}
                  id={`editimagebutton${editProductInfo[0]}`}
                  onChange={handleProductImage}
                />
              </label>
            </form>
          </div>
          <div>
            <button
              type="button"
              className="modalbutton-image_delete"
              onClick={deleteProductImage}
              id={editProductInfo[0]}
            >
              Excluir
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title={`Excluir: ${editProductInfo[4]}`}
        modalClose={modalClose3}
        closable
      >
        <div className="flex-column-center">
          {editProductInfo[1] ? (
            <img
              className="table-img margin-center margin-bottom-10"
              src={editProductInfo[1]}
              alt={editProductInfo[4]}
            />
          ) : (
            ''
          )}
        </div>
        <div className="text-center">
          Deseja mesmo Excluir <b>{editProductInfo[4]}</b>?
        </div>
        <div className="modal-imagebuttons">
          <div>
            <SimpleButton
              backgroundHoverColor="#a50000"
              onClick={() =>
                deleteProduct(editProductInfo[0], editProductInfo[3])
              }
            >
              Excluir
            </SimpleButton>
          </div>
          <div>
            <button
              type="button"
              className="modalbutton-cancelar"
              onClick={() => setModalClose3(true)}
              id={editProductInfo[0]}
            >
              não, cancelar
            </button>
          </div>
        </div>
      </Modal>
      {editProductModal && (
        <EditProduct
          modalStatus={editProductModal}
          productInfo={editProductInfoList}
        />
      )}
      <AddProduct modalStatus={newProductModal} />
    </Dashboard>
  );
};

export default Products;
