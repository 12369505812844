/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';

interface ContainerProps {
  badgeColor?: string;
  textColor?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;

  span {
    ${props =>
      props.badgeColor &&
      css`
        background-color: ${props.badgeColor} !important;
      `}

    ${props =>
      props.textColor &&
      css`
        color: ${props.textColor} !important;
      `}
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    display: inline-block !important;
    position: absolute;
    min-width: 10px;
    min-height: 15px;
    padding: 3px 7px;
    font-size: 12px !important;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: rgb(212, 19, 13);
    border-radius: 10px;
    top: -2px;
    right: -2px;
    transform: scale(1, 1);
    opacity: 1 !important;
  }
`;

export const Other = styled.div``;
