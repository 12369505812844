import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoHub from '../../assets/logo-hub.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';
import GlobalStyle, { Container, Content } from './styles';
import api from '../../services/api';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [tokenData, setTokenData] = useState(false);

  const location = useLocation();

  useEffect(() => {
    async function tokenResult(): Promise<void> {
      const tokenSearch = await api.get(
        `/password/search-token${location.search}&type=6`,
      );
      setTokenData(tokenSearch.data);
    }
    tokenResult();
  }, [location.search]);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      const token = location.search.replace('?token=', '');
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('Nova senha obrigatória'),
          password_confirmation: Yup.string()
            .required('Confirmação da senha obrigatória')
            .oneOf(
              [Yup.ref('password')],
              'A Confirmação da senha deve ser igual a nova senha',
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { password, password_confirmation } = data;

        await api.post('/password/reset', {
          password,
          password_confirmation,
          token,
        });

        addToast({
          type: 'success',
          title: 'Senha alterada com sucesso!',
        });

        history.push('/');

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          let description;

          if (errors.password === 'Nova senha obrigatória') {
            description = 'A nova senha é obrigatória';
          } else if (
            errors.password_confirmation ===
            'A Confirmação da senha deve ser igual a nova senha'
          ) {
            description = 'A confirmação de senha deve ser igual a nova senha';
          }

          addToast({
            type: 'alert',
            title: 'Preencha os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao resetar a senha',
          description:
            'Ocorreu um erro ao resetar sua senha, tente novamente mais tarde',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, location.search],
  );

  return (
    <>
      <Container>
        <Content>
          <img src={logoHub} alt="Hub" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Alterar Senha</h1>
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Nova senha"
            />

            <Input
              name="password_confirmation"
              icon={FiLock}
              type="password"
              placeholder="Confirmação da senha"
            />

            <Button
              linkValidation={!!tokenData}
              isResetPassword
              loading={loading}
              type="submit"
            >
              {' '}
              Alterar Senha{' '}
            </Button>
          </Form>

          <p>
            Não deseja mais trocar a senha? <Link to="/">Fazer Login.</Link>
          </p>
        </Content>
      </Container>
      <GlobalStyle />
    </>
  );
};

export default SignIn;
