/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import ReactTooltip from 'react-tooltip';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { formatPrice } from '../../../../utils/format';
import ActionButton from '../../../../components/Table/ActionButton';

import api from '../../../../services/api';

import { Content } from './styles';

import 'react-tabs/style/react-tabs.css';

import Dashboard from '../../../../components/Dashboard';
import TableLoading from '../../../../components/Table/Loading';
import { IState } from '../../../../store';
import {
  ISpecificListState,
  IProduct,
} from '../../../../store/modules/specificList/types';

import { addSpecificListInfo } from '../../../../store/modules/specificList/actions';
import AddListProduct from '../../../../components/Dashboard/Admin/Lists/AddListProduct';
import DeleteProduct from '../../../../components/Dashboard/Admin/Lists/DeleteProduct';
import { ListProductsContext } from '../../../../context/ListProducts';
import { ListProductsTable } from '../../../../components/Dashboard/Admin/Lists/ListProductsTable';

const ListProducts: React.FC = () => {
  const [tableLoad, setTableLoad] = useState<boolean>(true);
  const [listState, setListState] = useState<any>({ products: [] });
  const [listIdInfo, setListIdInfo] = useState<string | null>();
  const [finalList, setFinalList] = useState<any>([]);
  const [thisListId, setThisListId] = useState<any>('');
  const [math, setMath] = useState(0);

  const { generateListProducts, listProductsArray } =
    useContext(ListProductsContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setTableLoad(true);
    setTimeout(() => setTableLoad(false), 3000);
    // console.log(math)
  }, [finalList]);

  useEffect(() => {
    setTableLoad(true);

    async function apiListInfo(): Promise<void> {
      const urlParams = new URLSearchParams(window.location.search);
      const listId = urlParams.get('id');
      setThisListId(listId);

      setListIdInfo(listId);

      await api.get(`/list?id=${listId}`).then(response => {
        dispatch(addSpecificListInfo(response.data));
        generateListProducts(response.data);
        setTableLoad(false);
      });
    }

    apiListInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addListProductModal, setAddListProductModal] = useState<any>({
    id: '',
    status: false,
  });
  const [deleteListProductModal, setDeleteListProductModal] = useState<any>({
    id: '',
    status: false,
  });

  // const [deleteModal, setDeleteModal] = useState<any>({
  //   listInfo: {},
  //   status: false,
  // });

  const handleChange = useCallback(
    async (data): Promise<void> => {
      try {
        setTableLoad(true);
        await api.put(`/list/product`, data).then(async response => {
          await api.get(`/list?id=${response.data.list_id}`).then(lists => {
            dispatch(addSpecificListInfo(lists.data));
          });

          toast.success(`produto ${response.data.name} atualizado com sucesso`);
        });
      } catch (err) {
        const product = { id: data.id };
        await api.put(`/list/product`, product).then(async response => {
          await api.get(`/list?id=${response.data.list_id}`).then(lists => {
            setListState({ products: [] });
            dispatch(addSpecificListInfo(lists.data));
          });

          toast.error(`O produto ${response.data.name} não foi atualizado`);
        });
      } finally {
        setTableLoad(false);
      }
    },
    [dispatch],
  );

  const handleAddListProductModal = useCallback(async (id): Promise<void> => {
    await setAddListProductModal({ id: '', status: false });
    await setAddListProductModal({ id, status: true });
  }, []);

  const handleDeleteListProductModal = useCallback(
    async (id): Promise<void> => {
      await setDeleteListProductModal({ id: '', status: false });
      await setDeleteListProductModal({ id, status: true });
    },
    [],
  );

  const list = useSelector<IState, ISpecificListState>(
    state => state.specificList,
  );

  useEffect(() => {
    setTableLoad(true);
    const listDetail = list.list || { products: [] };

    setListState(listDetail);

    const listProducts = listProductsArray?.products?.map(function usersMapping(
      obj: IProduct,
    ) {
      return [
        obj.id,
        math,
        obj.product_id,
        obj.product_info?.family || '-',
        <input
          name="name"
          defaultValue={obj.name}
          onBlur={e =>
            e.currentTarget.value !== e.currentTarget.defaultValue &&
            handleChange({ id: obj.id, name: e.currentTarget.value })
          }
        />,
        <input
          name="descricao"
          defaultValue={obj.descricao || '-'}
          onBlur={e =>
            e.currentTarget.value !== e.currentTarget.defaultValue &&
            handleChange({ id: obj.id, descricao: e.currentTarget.value })
          }
        />,
        <input
          name="qtd"
          defaultValue={obj.qtd}
          onBlur={e =>
            e.currentTarget.value !== e.currentTarget.defaultValue &&
            handleChange({ id: obj.id, qtd: e.currentTarget.value })
          }
        />,
        <input
          name="valor"
          defaultValue={obj.valor}
          onBlur={e =>
            e.currentTarget.value !== e.currentTarget.defaultValue &&
            handleChange({ id: obj.id, valor: e.currentTarget.value })
          }
        />,
        <input
          name="valor_desconto"
          defaultValue={obj.valor_desconto || '0.00'}
          onBlur={e =>
            e.currentTarget.value !== e.currentTarget.defaultValue &&
            handleChange({ id: obj.id, valor_desconto: e.currentTarget.value })
          }
        />,
        obj.valor,
        obj.valor_desconto,
      ];
    });

    // const sortedProductsList = listProducts?.sort(function (a: any, b: any) {
    //   return b[2] - a[2];
    // });

    const columns = [
      {
        name: 'id',
        options: {
          searchable: true,
          display: false,
          filter: true,
          draggable: false,
          viewColumns: true,
          sort: false,
        },
      },
      {
        name: 'math',
        options: {
          searchable: true,
          display: false,
          filter: true,
          draggable: false,
          viewColumns: true,
          sort: false,
        },
      },
      {
        name: 'product_id',
        options: {
          display: false,
          filter: false,
          draggable: false,
          searchable: false,
          viewColumns: false,
          sort: false,
        },
      },
      'Familia',
      { name: 'Nome', options: { sort: true } },
      'Descrição',
      'Qtd.',
      'valor (R$)',
      'com desconto (R$)',
      {
        name: 'valor',
        options: {
          display: false,
          filter: false,
          draggable: false,
          searchable: false,
          viewColumns: false,
          sort: false,
        },
      },
      {
        name: 'valor_desconto',
        options: {
          display: false,
          filter: false,
          draggable: false,
          searchable: false,
          viewColumns: false,
          sort: false,
        },
      },
      {
        name: '',
        options: {
          filter: false,
          draggable: false,
          searchable: false,
          viewColumns: false,
          // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          customBodyRender: (value: any, tableMeta: any) => {
            return (
              <div className="table-icons">
                <ActionButton
                  color="#666"
                  data-tip="Excluir"
                  onClick={() =>
                    handleDeleteListProductModal(tableMeta.rowData[0])
                  }
                >
                  <FiTrash2 />
                </ActionButton>

                <ReactTooltip html />
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filterType: 'dropdown' as any,
      count: 1000,
      pagination: false,
      rowsPerPage: 1000,
      rowsPerPageOptions: [10, 15, 20],
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      selectableRowsHideCheckboxes: true,
      download: false,
      customToolbar: () => {
        return (
          <button
            type="button"
            className="table-button_new"
            onClick={() => handleAddListProductModal(listIdInfo)}
          >
            <span>
              <FiPlus /> Novo Produto
            </span>
          </button>
        );
      },
      textLabels: {
        body: {
          noMatch: 'Desculpe, nenhum resultado encontrado',
          toolTip: 'Ordenar',
        },
        pagination: {
          next: 'Próxima pagina',
          previous: 'Pagina anterior',
          rowsPerPage: 'Resultados por pagina:',
          displayRows: 'de', // 1-10 of 30
        },
        toolbar: {
          search: 'Buscar',
          downloadCsv: 'Download CSV',
          print: 'Imprimir',
          viewColumns: 'Ver Colunas',
          filterTable: 'Filtrar Tabela',
        },
        filter: {
          title: 'Filtros',
          all: 'Todos',
          reset: 'Resetar',
        },
        viewColumns: {
          title: 'Mostrar colunas',
        },
        selectedRows: {
          text: 'resultado(s) detelado(s)',
          delete: 'Deletar',
        },
      },
    };

    setFinalList({ listProducts, columns, options });
    setMath(math + 1);
    setTableLoad(false);
  }, [listProductsArray]);

  return (
    <Dashboard>
      <Content>
        {tableLoad ? (
          <TableLoading />
        ) : (
          <>{tableLoad ? '' : <ListProductsTable finalList={finalList} />}</>
        )}
      </Content>
      {/* {/* <EditList modalStatus={editModal.status} listInfo={editModal.listInfo} /> */}
      <AddListProduct
        modalStatus={addListProductModal.status}
        listId={thisListId}
      />
      <DeleteProduct
        modalStatus={deleteListProductModal.status}
        listId={thisListId}
        productId={deleteListProductModal.id}
      />
    </Dashboard>
  );
};

export default ListProducts;
