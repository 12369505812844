/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Grid,
  Box,
  Button,
  Icon,
  Stack,
  Text,
  Tooltip,
  Spinner,
} from '@chakra-ui/react';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiTruck } from 'react-icons/fi';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import Modal from '../../../Modal';
import Input from '../../../Input';
import Select from '../../../Select';
import { OrdersContext } from '../../../../context/Orders';

interface AproveUserFormData {
  order_id: string;
  autor: string;
  rastreio?: string;
  transportadora: number;
}

type DeliveryData = {
  id: string;
  autor: string;
};

interface DeliveryProps {
  data: DeliveryData;
}

export function Delivery({ data }: DeliveryProps) {
  const formRef = useRef<FormHandles>(null);

  const [isModalClose, setIsModalClose] = useState(true);
  const [shippingCompany, setShippingCompany] = useState('');
  const [deliveryCode, setDeliveryCode] = useState('');
  const [isDeliveryCompanysLoaded, setIsDeliveryCompanysLoaded] =
    useState(false);
  const [deliveryCompanys, setDeliveryCompanys] = useState([
    { id: 1, name: 'Padrão' },
  ]);

  const { updateOrderCount } = useContext(OrdersContext);

  const openModal = async () => {
    await setIsModalClose(true);
    setIsModalClose(false);
  };

  const handleSubmit = useCallback(
    async (data: AproveUserFormData): Promise<void> => {
      // console.log(data)
      const requestData = {
        order_id: data.order_id,
        status: '3',
        transportadora: Number(data.transportadora),
        rastreio: Number(data.transportadora) === 1 ? null : data.rastreio,
      };

      try {
        api.put('/order/update', requestData).then(response => {
          // console.log(response.data)
          setIsModalClose(true);
          toast.success(`Pedido de ${data.autor} marcado como enviado`, {
            autoClose: 4000,
          });
          updateOrderCount();
        });
      } catch {
        toast.error(
          'Não foi possivel liberar o pedido, tente novamente mais tarde',
        );
      }
    },
    [],
  );

  useEffect(() => {
    async function fatchDeliveryCompanys() {
      if (!isModalClose) {
        try {
          await api.get('/order/transportadoras').then(response => {
            if (response.data.length > 0) {
              setDeliveryCompanys(response.data);
              setIsDeliveryCompanysLoaded(true);
            }
          });
        } catch {
          toast.error(
            `Não foi possivel carregar a lista de Empresas para entrega`,
          );
          setIsDeliveryCompanysLoaded(true);
        }
      }
    }

    fatchDeliveryCompanys();
  }, [isModalClose]);

  return (
    <>
      <Tooltip
        label="Marcar como enviado"
        bg="black"
        hasArrow
        placement="left"
        p={2}
        borderRadius="5px"
        color="white"
      >
        <Box>
          <Icon
            onClick={() => openModal()}
            cursor="pointer"
            as={FiTruck}
            color="#ff9900"
            fontSize="1.2rem"
          />
        </Box>
      </Tooltip>
      <Modal
        title={`Confirmar envio (${data.autor})`}
        modalClose={isModalClose}
        closable
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input display={false} readOnly name="order_id" value={data.id} />
          <Input display={false} readOnly name="autor" value={data.autor} />
          <Stack>
            <Text>Selecione uma transportadora</Text>
            {isDeliveryCompanysLoaded ? (
              <Select
                name="transportadora"
                placeholder="Selecione uma opção"
                onChange={e => {
                  setDeliveryCode('');
                  setShippingCompany(e.target.value);
                }}
              >
                <option>Selecione uma opção</option>
                {deliveryCompanys.map(deliveryCompany => {
                  return (
                    <option key={deliveryCompany.id} value={deliveryCompany.id}>
                      {deliveryCompany.name}
                    </option>
                  );
                })}
              </Select>
            ) : (
              <Spinner color="#172b4d" />
            )}

            {shippingCompany && (
              <>
                <Text mt={5}>
                  {shippingCompany === '1'
                    ? 'Deseja confirmar envio?'
                    : 'Informe o código de rastreio'}
                </Text>
                <Grid
                  templateColumns={
                    shippingCompany === '1' ? '1fr' : '3.4fr 1fr'
                  }
                  gap={2}
                >
                  <Box>
                    <Input
                      noMargin
                      style={{ marginTop: '0px' }}
                      name="rastreio"
                      onChange={e => setDeliveryCode(e.target.value)}
                      placeholder="Ex: BR302112340"
                      display={shippingCompany !== '1'}
                    />
                  </Box>
                  <Button
                    type="submit"
                    disabled={
                      !(shippingCompany === '1' || deliveryCode.length > 4)
                    }
                    h="100%"
                    minH="50px"
                    bg="#000a3a"
                    px={5}
                    color="white"
                    _hover={{ bgColor: '#253271' }}
                  >
                    {shippingCompany !== '1' &&
                    deliveryCode.length > 0 &&
                    deliveryCode.length < 5 ? (
                      <Spinner color="#fff" />
                    ) : (
                      <Text>Confirmar</Text>
                    )}
                  </Button>
                </Grid>
              </>
            )}
          </Stack>
        </Form>
      </Modal>
    </>
  );
}
