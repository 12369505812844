import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: auto;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  background-color: #fff;
  padding: 20px 15px;

  @media (max-width: 1000px) {
    padding: 0px 0px;
  }
`;

export const RightSide = styled.div`
  flex-grow: 1;
  overflow: auto;
  width: 100%;

  .loading-container {
    display: flex;
    min-width: 100%;
    justify-content: center;
    padding-top: 70px;
  }
`;

export default createGlobalStyle`
  html {
    min-height: 100%;
    height: 100%;
  }
  body {
    background-color: #F1F3F9 !important;
    min-height: 100%;
    height: 100%;


    div#root {
      min-height: 100%;
      height: 100%;
    }
  }


`;
