import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const NewsBox = styled.div`
  /* width: 31.33%; */
  /* margin: 0.83%; */
  padding: 0 0 25px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(44, 40, 40, 0.11);
  background-color: #ffffff;
  min-height: 320px;
  position: relative;

  h3 {
    margin: 27px 0px 3px 30px;
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: #43425d;
  }

  span {
    margin: 0px 0px 19px 30px;
    font-size: 13px;
    text-align: left;
    color: #8898aa;
  }

  p {
    margin: 19px 30px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 300;
    text-align: left;
    color: #172b4d;
  }

  a {
    margin: 19px 0px 0 30px;
    position: absolute;
    text-decoration: none;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #7764e4;
    bottom: 30px;
  }

  img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }
`;
