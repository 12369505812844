interface IColumnsObject {
  name: string;
  options?: {
    display?: boolean;
    filter?: boolean;
    draggable?: boolean;
    searchable?: boolean;
    viewColumns?: boolean;
    sort?: boolean;
  };
}

interface IColumns {
  id: IColumnsObject;
  number: string;
  autor: string;
  feitoEm: string;
  formaPag: string;
  atualizacao: string;
  transporte: string;
  status: string;
}

export const options = {
  filterType: 'dropdown' as any,
  // filter: false,
  print: false,
  selectableRowsHideCheckboxes: true,
  download: false,
  textLabels: {
    body: {
      noMatch: 'Desculpe, nenhum resultado encontrado',
      toolTip: 'Ordenar',
    },
    pagination: {
      next: 'Próxima pagina',
      previous: 'Pagina anterior',
      rowsPerPage: 'Resultados por pagina:',
      displayRows: 'de', // 1-10 of 30
    },
    toolbar: {
      search: 'Buscar',
      downloadCsv: 'Download CSV',
      print: 'Imprimir',
      viewColumns: 'Ver Colunas',
      filterTable: 'Filtrar Tabela',
    },
    filter: {
      title: 'Filtros',
      all: 'Todos',
      reset: 'Resetar',
    },
    viewColumns: {
      title: 'Mostrar colunas',
    },
    selectedRows: {
      text: 'resultado(s) detelado(s)',
      delete: 'Deletar',
    },
  },
};

export const columns: IColumns = {
  id: {
    name: 'id',
    options: {
      display: false,
      filter: false,
      draggable: false,
      searchable: false,
      viewColumns: false,
      sort: false,
    },
  },
  number: 'Pedido',
  autor: 'Autor',
  status: 'Status',
  feitoEm: 'Pedido feito em',
  formaPag: 'Forma Pag.',
  atualizacao: 'Atualização',
  transporte: 'Transporte',
};
