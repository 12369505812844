/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { ButtonHTMLAttributes } from 'react';
// import { Link } from 'react-router-dom';

import { Container } from './styles';

type IButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  color?: string;
  link?: string;
};

const ActionButton: React.FC<IButtonProps> = ({
  children,
  loading,
  color,
  // link,
  ...rest
}) => (
  <Container
    type="button"
    loading={loading ? Number(loading) : 0}
    customColor={color !== undefined ? color : 'none'}
    {...rest}
  >
    {children}
  </Container>
);

export default ActionButton;
