import Prismic from '@prismicio/client';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getPrismicClient(req?: unknown) {
  const prismic = Prismic.client(process.env.REACT_APP_PRISMIC_ENDPOINT ?? '', {
    req,
    accessToken: process.env.REACT_APP_PRISMIC_ACCESS_TOKEN,
  });

  // console.log(process.env.REACT_APP_PRISMIC_ENDPOINT)
  // console.log(process.env.REACT_APP_PRISMIC_ACCESS_TOKEN)

  return prismic;
}
