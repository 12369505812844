import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Content = styled.div`
  /* max-width: 100%; */
  padding: 40px;

  .softcap-badge {
    top: -28px !important;
    right: -18px !important;
  }
`;

export const LoadingStatus = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f9;

  svg {
    animation: ${rotate} 1.3s linear infinite;
    color: #000b3a;
    font-size: 80px;
  }
`;

export const display = styled.div`
  /* max-width: 100%; */
  padding: 40px;
`;
