import styled, { css, keyframes } from 'styled-components';
import logoImgMin from '../../../assets/logo-hub-min.svg';
import logoImg from '../../../assets/logo-hub.svg';

interface ContainerProps {
  menuToggle?: number;
}

export const Container = styled.div<ContainerProps>`
  height: 100%;

  @media (max-width: 1000px) {
    width: 100%;
    background-color: #000b3a;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Logo = styled.div<ContainerProps>`
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 50px;

  @media (max-width: 1000px) {
    margin-bottom: 0px;
    padding-left: 75px;
    padding-top: 20px;
  }

  ${props =>
    props.menuToggle === 0 &&
    css`
      flex-direction: row;
      align-items: center;
    `}
`;

const logoOpacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const LogoImg = styled.div<ContainerProps>`
  padding-right: 50px;
  min-width: 190px;
  transition: min-width 0.5s linear, padding-right 0.5s linear;
  margin-left: auto;

  ${props =>
    props.menuToggle === 0 &&
    css`
      padding-right: 0px;
      min-width: 20px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      min-width: 50px !important;
      min-height: 50px !important;
      padding-right: 10px !important;
      background: url(${logoImgMin}) no-repeat center !important;
      animation: ${logoOpacity} 0.7s linear;

      img {
        transition: width 0.5s linear, opacity 0s linear !important;
        width: 12px !important;
        height: 20px !important;
        /* display: none; */
        opacity: 0 !important;
      }
    `}

  @media (max-width: 1000px) {
    min-width: 150px;
    min-height: 80px;
    padding-right: 0px;
    background: url(${logoImg}) no-repeat center;
    background-size: 50% 50%;
    img {
      display: none;
    }
  }

  img {
    transition: width 0.2s linear, opacity 0.5s linear 0.2s;
    opacity: 1;
    margin-top: 10px;
    margin-left: 10px;
    width: 73px;
    /* min-height: 35px; */
  }
`;
export const LogoMenu = styled.div<ContainerProps>`
  color: #172b4d;
  font-size: 25px;
  display: flex;
  align-items: center;
  cursor: pointer !important;

  @media (max-width: 1000px) {
    margin-left: auto;
    padding-right: 40px;
    color: #fff;
  }

  ${props =>
    props.menuToggle === 0 &&
    css`
      position: absolute;
      right: -50px;
      svg {
        color: #11cdef;
      }
    `}
`;

export const MainMenu = styled.ul<ContainerProps>`
  transition: min-width 0.5s linear !important;
  min-width: 200px;
  ${props =>
    props.menuToggle === 0 &&
    css`
      transition: min-width 0.5s linear !important;
      min-width: 70px;
      display: flex;
      flex-direction: column;
      align-items: left;
      max-width: 70px;
    `}
  li {
    ${props =>
      props.menuToggle === 0 &&
      css`
        /* screen width is less than 768px (medium) */
        display: flex;
        flex-direction: row;
        align-items: center;
      `}

    a {
      padding-left: 20px !important;
      ${props =>
        props.menuToggle === 0 &&
        css`
          /* screen width is less than 768px (medium) */
          /* justify-content: center; */
          padding: 15px 15px;
        `}

      span {
        transition: opacity 0.5s linear 0.3s, font-size 0.5s linear;
        opacity: 1;
        ${props =>
          props.menuToggle === 0 &&
          css`
            transition: opacity 0.5s linear, font-size 0s linear;
            opacity: 1;
            /* screen width is less than 768px (medium) */
            font-size: 0px;

            @media (max-width: 1000px) {
              font-size: 15px !important;
            }
          `}
      }
    }
  }

  @media (max-width: 1000px) {
    min-width: 100% !important;
    width: 100%;
    color: #fff;
    background-color: #0a164d;
    overflow: hidden;
    /* max-height: 0px; */
    /* display: none; */

    li {
      a span {
        color: #fff;
      }
    }
  }
`;
