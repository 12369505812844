/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useState } from 'react';
import {
  FiArrowLeft,
  FiMail,
  FiLock,
  FiAlertCircle,
  FiUser,
  FiPhone,
  FiSmartphone,
} from 'react-icons/fi';

import Switch from 'react-switch';

import { FaRegBuilding, FaWhatsapp } from 'react-icons/fa';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import logoHub from '../../assets/logo-hub.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';

import GlobalStyle, { Container, Content } from './styles';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
  password_confirmation?: string;
  phone?: string;
  mobile_phone: string;
  whatsapp: string | boolean;
  company_name: string;
}

const SignUp: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const switchToggle = (): void => {
    setSwitchStatus(!switchStatus);
  };

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      // eslint-disable-next-line no-param-reassign
      data.whatsapp = switchStatus;
      if (data.phone === '') {
        // eslint-disable-next-line no-param-reassign
        delete data.phone;
      }
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail valido'),
          password: Yup.string().min(6, 'A senha deve ter no mínimo 6 digitos'),
          mobile_phone: Yup.string().required('Celular/Whatsapp Obrigátorio'),
          phone: Yup.string().notOneOf(
            [Yup.ref('mobile_phone')],
            'Os números de telefone não podem ser iguais',
          ),
          company_name: Yup.string().required('Empresa obrigatória'),
          whatsapp: Yup.boolean().required('whatsapp obrigatório'),
          password_confirmation: Yup.string()
            .required('Confirmação da senha obrigatória')
            .oneOf(
              [Yup.ref('password')],
              'A Confirmação da senha deve ser igual a nova senha',
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // eslint-disable-next-line no-param-reassign
        delete data.password_confirmation;

        await api.post('/users', data);

        history.push('/register-success');

        addToast({
          type: 'success',
          title: 'Solicitação feita com sucesso!',
          description: 'Aguarde até que um administrador avalie o seu cadastro',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          let description;

          if (errors.password === 'A senha deve ter no mínimo 6 digitos') {
            description = 'A senha deve ter no mínimo 6 digitos';
          } else if (
            errors.password_confirmation ===
            'A Confirmação da senha deve ser igual a nova senha'
          ) {
            description = 'A confirmação de senha deve ser igual a nova senha';
          } else if (
            errors.phone === 'Os números de telefone não podem ser iguais' &&
            data.mobile_phone !== ''
          ) {
            description = 'Os números de telefone não podem ser iguais';
          }

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }

        // if (err.response.data.message === 'Email adress already used.') {
        //   addToast({
        //     type: 'error',
        //     title: 'Erro ao Solicitar Cadastro',
        //     description: 'Já existe um usuário cadastrado com esse e-mail.',
        //   });
        //   return;
        // }
        addToast({
          type: 'error',
          title: 'Erro ao Solicitar Cadastro',
          description:
            'Não foi possivel fazer o cadastro, tente novamente mais tarde',
        });
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, switchStatus],
  );

  return (
    <>
      <Container>
        <Content>
          <img src={logoHub} alt="Hub" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>CADASTRO</h1>
            <p>
              <FiAlertCircle size="16" />
              Toda solicitação de cadastro será enviada para aprovação da
              administração!
            </p>

            <Input
              mask="name"
              name="name"
              icon={FiUser}
              placeholder="Nome"
              required
            />

            <Input name="email" icon={FiMail} placeholder="E-mail" required />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
              required
            />

            <Input
              name="password_confirmation"
              icon={FiLock}
              type="password"
              placeholder="Confirmar Senha"
              required
            />

            <Input
              name="company_name"
              icon={FaRegBuilding}
              placeholder="Nome da Empresa"
              required
            />

            <Input
              mask="phone"
              name="mobile_phone"
              icon={switchStatus ? FaWhatsapp : FiSmartphone}
              placeholder={switchStatus ? 'Whatsapp' : 'Celular'}
              required
            />

            <label>
              <span>Esse numero é whatsapp?</span>
              <Switch
                onColor="#52c75d"
                onChange={() => switchToggle()}
                checked={switchStatus}
              />
            </label>
            <Input
              mask="phone"
              name="phone"
              icon={FiPhone}
              placeholder="Telefone:"
            />

            <Button loading={loading} type="submit">
              {' '}
              Cadastrar{' '}
            </Button>

            <p>
              <span>*</span> campos obrigatórios
            </p>
          </Form>

          <p>
            <Link to="/">
              <FiArrowLeft /> Voltar para login
            </Link>
          </p>
        </Content>
      </Container>
      <GlobalStyle />
    </>
  );
};

export default SignUp;
