/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Text } from '@chakra-ui/react';

interface OrderTitleProps {
  title: string;
  month?: string;
  mt?: number | string;
}

export function OrderTitle({ title, mt, month, ...rest }: OrderTitleProps) {
  return (
    <Text
      {...rest}
      mt={mt}
      mb={5}
      color="#172B4D"
      fontWeight="bold"
      fontSize="25px"
      textTransform="uppercase"
      display="flex"
      alignItems="center"
    >
      {title}
      {month && (
        <Text as="span" color="gray.500" fontSize={14} ml={3}>
          ({month})
        </Text>
      )}
    </Text>
  );
}
