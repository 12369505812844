import React, { useContext, useEffect, useState } from 'react'
import { Grid, Stack } from '@chakra-ui/react'

import { OrdersContext } from '../../context/Orders'
import { OrderTitle } from './Admin/Home/OrderTitle'
import { OrdersTable } from './UsersOrdersTable'

interface IMonthsName {
  [key: number]: string;
}

export function UserOrdersView() {

  const [pendentOrders, setPendentOrders] = useState<object[] | undefined>()
  const [sendedOrders, setSendedOrders] = useState<object[] | undefined>()
  const [deliveredOrders, setDeliveredOrders] = useState<object[] | undefined>()

  const { orders, currentMonth, currentYear } = useContext(OrdersContext)

  useEffect(() => {
    setPendentOrders(
      orders?.filter((order: any) => {
        return order.status === 1 &&
        new Date(order.created_at).getFullYear() === currentYear &&
        new Date(order.created_at).getMonth() === currentMonth

      })
    )
    setSendedOrders(
      orders?.filter((order: any) => {
        return order.status === 2 &&
        new Date(order.created_at).getFullYear() === currentYear &&
        new Date(order.created_at).getMonth() === currentMonth
      })
    )
    setDeliveredOrders(
      orders?.filter((order: any) => {
        return order.status > 2 &&
        new Date(order.created_at).getFullYear() === currentYear &&
        new Date(order.created_at).getMonth() === currentMonth
      })
    )
  }, [orders, currentMonth, currentYear])

  return (
    <>
    <Stack p={10} pt={5}>
      <OrderTitle title="Ultimos Pedidos"/>
      <OrdersTable />
    </Stack>
    </>
  )
}
