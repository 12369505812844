/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';

import { IProductsInfoState } from './types';

const INITIAL_STATE: IProductsInfoState = {
  loading: true,
  editingImage: true,
  creatingProduct: true,
  deletingProduct: true,
  editingProduct: true,
};

const usersInfo: Reducer<IProductsInfoState> = (
  state = INITIAL_STATE,
  action,
) => {
  // eslint-disable-next-line consistent-return
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_PRODUCTS_INFO': {
        const { apiProductsInfo } = action.payload;

        draft.loading = false;
        draft.products = apiProductsInfo;

        break;
      }

      case 'EDIT_PRODUCT': {
        // const { editProductInfo } = action.payload;

        break;
      }

      case 'OPEN_EDIT_PRODUCT': {
        draft.editingProduct = false;
        break;
      }

      case 'CLOSE_EDIT_PRODUCT': {
        draft.editingProduct = true;
        break;
      }

      default: {
        break;
      }
    }
  });
};

export default usersInfo;
