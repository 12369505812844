import { all, call, select, takeLatest, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import {
  addProductToCartRequest,
  addProductToCartSuccess,
  decreaseProductToCartSuccess,
  pageCartCheck,
  updateProductToCartSuccess,
} from './actions';
import { IState } from '../..';
import api from '../../../services/api';

interface IProductResponse {
  id: string;
  qtd: number;
  name: string;
}

type checkProductsStockRequest = ReturnType<typeof addProductToCartRequest>;
type IPageCheckProductsStock = ReturnType<typeof pageCartCheck>;

function* pageCheckProductsStock({ payload }: IPageCheckProductsStock): any {
  const { cartInfo } = payload;

  yield cartInfo?.items.map(async item => {
    const availableStockResponse: any = await call(
      api.get,
      `list/product?id=${item.product.id}`,
    );

    if (item.quantidade > availableStockResponse.data.qtd) {
      await put(
        updateProductToCartSuccess(
          item.product.id,
          availableStockResponse.data.qtd,
        ),
      );
      toast.error(
        `O Produto ${item.product.name} sofreu alteração de estoque!`,
      );
    }
  });
}

function* checkProductsStock({ payload }: checkProductsStockRequest): any {
  const { productInfo } = payload;

  const currentQuantity: number = yield select((state: IState) => {
    // // console.log(state.cart.items);
    return (
      state.cart.items.find(item => item.product.id === productInfo?.id)
        ?.quantidade ?? 0
    );
  });

  const availableStockResponse: AxiosResponse<IProductResponse> = yield call(
    api.get,
    `list/product?id=${productInfo?.id}`,
  );

  if (availableStockResponse.data.qtd > currentQuantity) {
    yield put(addProductToCartSuccess(productInfo));
    if (currentQuantity === 0) {
      toast.success(`Produto ${productInfo?.name} adicionado!`);
    }
    // // console.log(currentQuantity);
  } else if (availableStockResponse.data.qtd < currentQuantity) {
    yield put(
      updateProductToCartSuccess(
        productInfo?.id,
        availableStockResponse.data.qtd,
      ),
    );
    toast.error(`Esta quantidade não esta mais disponivel`);
    toast.success(`Quantidade atualizada`);
    // // console.log(currentQuantity);
  } else {
    // console.log('quantidade maior');
    toast.warning(`Quantidade maxima em estoque já adicionada!`);
  }
}


function* checkProductsStockToRemove({
  payload,
}: checkProductsStockRequest): any {
  const { productInfo } = payload;
  // console.log(productInfo);

  const currentQuantity: number = yield select((state: IState) => {
    // // console.log(state.cart.items);
    return (
      state.cart.items.find(item => item.product.id === productInfo?.id)
        ?.quantidade ?? 0
    );
  });

  const availableStockResponse: AxiosResponse<IProductResponse> = yield call(
    api.get,
    `list/product?id=${productInfo?.id}`,
  );

  if (currentQuantity === 0) {
    toast.warning(`Este item não esta no carrinho!`);
  } else if (availableStockResponse.data.qtd >= currentQuantity) {
    yield put(decreaseProductToCartSuccess(productInfo?.id));
    if (currentQuantity === 1) {
      toast.success(`Produto removido com sucesso`);
    }
    // // console.log(currentQuantity);
  } else {
    // // console.log('quantidade maior');
    yield put(
      updateProductToCartSuccess(
        productInfo?.id,
        availableStockResponse.data.qtd,
      ),
    );
    toast.error(
      `Esta quantidade não está mais disponivel em estoque, quantidade atualizada.`,
    );
  }
}

export default all([
  takeLatest('ADD_TO_CART_REQUEST', checkProductsStock),
  takeLatest('DECREASE_TO_CART_REQUEST', checkProductsStockToRemove),
  takeLatest('PAGE_CART_CHECK', pageCheckProductsStock),
]);
