import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiArrowLeft, FiMail, FiXCircle } from 'react-icons/fi';

import SuccessImage from '../../assets/illustrations/aprovado.png';

import api from '../../services/api';

import logoHub from '../../assets/logo-hub.svg';

import GlobalStyle, { Container, Content } from './styles';

const MailConfirmation: React.FC = () => {
  const [tokenData, setTokenData] = useState(false);

  const location = useLocation();

  useEffect(() => {
    async function tokenResult(): Promise<void> {
      const tokenSearch = await api.get(
        `/password/search-token${location.search}&type=1`,
      );
      await api.post(`/users/mail-verification${location.search}&type=1`);
      setTokenData(tokenSearch.data);
    }
    tokenResult();
  }, [location.search]);

  return (
    <>
      <Container>
        <Content>
          <img src={logoHub} alt="Hub" />
          {!tokenData ? (
            <div>
              <h3>Link Invalido!</h3>
              <FiXCircle className="error-icon" />
            </div>
          ) : (
            <div className="loginBox-alert">
              <h3>Email Confirmado com sucesso!</h3>
              <img src={SuccessImage} alt="aguardando informação" />
              <p className="alert-message">
                <span>
                  <FiMail />
                </span>
                Assim que a nossa equipe liberar o seu cadastro você será
                notificado por e-mail!
              </p>
            </div>
          )}
          <p>
            <Link to="/">
              <FiArrowLeft /> <span>VOLTAR</span>
            </Link>
          </p>
        </Content>
      </Container>
      <GlobalStyle />
    </>
  );
};

export default MailConfirmation;
