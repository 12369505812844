/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  badgeColor?: string;
  textColor?: string;
  modalSize?: string;
  closeModal: number;
  isAprove: number;
}

const fadeIn = keyframes`
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  70% {
    transform: translateY(0px);
    opacity: 0.3;
  }

  80% {
  }

  100% {
    margin-bottom: translateY(0px);
    opacity: 1;

  }
`;
const backgroundFadeIn = keyframes`
  from {
    background-color: rgba(0, 10, 58, 0);
  }

  to {
    background-color: rgba(0, 10, 58, 0.8);
  }
`;

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  animation: ${backgroundFadeIn} 0.3s linear;

  ${props =>
    props.closeModal === 1
      ? css`
          display: none !important;
        `
      : ''}

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 10, 58, 0.8);

  button.softcap-modal_background-close {
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2001;
  }

  div.softcap-modal {
    z-index: 2002;
    animation: ${fadeIn} 0.6s ease-in;
    background-color: #fff;
    border-radius: 10px;
    min-height: 200px;
    max-height: 90vh;
    ${props =>
    props.closeModal === 1
      ? css`
          overflow-y: none !important;
        `
      : css`
      overflow-y: scroll !important;
    `}
    /* overflow-x: initial !important; */

    ${props =>
      props.modalSize
        ? css`
            width: ${props.modalSize} !important;
          `
        : ''}
    width: 600px;

    div.softcap-modal_content {
      padding: 20px;
      font-size: 17px;
    }

    div.softcap-modal_title {
      display: flex;
      flex-flow: row no-wrap;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      background-color: #e8ecef;
      font-weight: 800;
      font-size: 0.9rem;
      padding: 15px;
      border-radius: 10px 10px 0 0;

      button.softcap-modal_close-button {
        border: none;
        background-color: rgba(0, 10, 58, 1);
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding-top: -10px;
        font-weight: 700;
        font-size: 13px;
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const Other = styled.div``;
