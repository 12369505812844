/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FiHash } from 'react-icons/fi';
import Input from '../../../../Input';
import getValidationErrors from '../../../../../utils/getValidationErrors';

import { IState } from '../../../../../store';

import { Container, Divisor } from './styles';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import { addCompanysInfo } from '../../../../../store/modules/companys/actions';
import {
  addUsersInfo,
  closeReproveUsers,
} from '../../../../../store/modules/users/actions';
import { IUsersInfoState } from '../../../../../store/modules/users/types';
import SimpleButton from '../../../../SimpleButton';

interface AproveUserFormData {
  id: string;
  data_id: string;
  active: boolean;
  name: string;
  email: string;
  company_name: string;
  mobile_phone: string;
  whatsapp: boolean;
  phone?: string;
  group?: number;
}

const ReproveUser: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const profileLoad = async (): Promise<void> => {
      await api.get(`/company`).then(response => {
        dispatch(addCompanysInfo(response.data));

        setLoading(false);
      });
    };

    profileLoad();
  }, [addToast, dispatch]);

  const { userEditProfile, reprovingUser } = useSelector<
    IState,
    IUsersInfoState
  >(state => state.usersInfo);

  const handleSubmit = useCallback(
    async (data: AproveUserFormData): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          user_id: Yup.string()
            .required('Usuário não identificado.')
            .uuid('ID Inválido.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .put(`/users/reprove-user`, data)
          .then(async function aproveUser() {
            await api.get(`/users/list-users?permission=100`).then(users => {
              dispatch(addUsersInfo(users.data));
            });

            dispatch(closeReproveUsers());

            addToast({
              type: 'success',
              title: `Usuário ${userEditProfile?.name} desabilitado com sucesso`,
            });
          });

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let description;

          if (
            errors.company_id === 'Selecione uma empresa' ||
            errors.company_id === 'company_id must be a valid UUID'
          ) {
            description = 'Você precisa selecionar uma empresa';
          }

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }
      } finally {
        setLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast, dispatch, userEditProfile?.name],
  );

  return (
    <>
      {!reprovingUser && (
        <Container>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="user_id"
              icon={FiHash}
              placeholder="id"
              value={userEditProfile?.id}
              display={false}
              readOnly
            />
            <h3 className="modal-question text-center">
              Deseja mesmo reprovar o login de
              <b>
                {' '}
                {userEditProfile?.name}({userEditProfile?.email})
              </b>
              ?
            </h3>
            <Divisor className="modal-form_button-divisor">
              <div className="modal-simplebuttons">
                <div>
                  <SimpleButton
                    className="modal-simplebutton"
                    backgroundHoverColor="#a50000"
                    loading={loading}
                    type="submit"
                  >
                    Reprovar
                  </SimpleButton>
                </div>
                <div>
                  <button
                    type="button"
                    className="modalbutton-cancelar"
                    onClick={() => dispatch(closeReproveUsers())}
                  >
                    não, cancelar
                  </button>
                </div>
              </div>
            </Divisor>
          </Form>
        </Container>
      )}
    </>
  );
};

export default ReproveUser;
