export function cep(
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 9;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  e.currentTarget.value = value;
  return e;
}

export function phone(
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 15;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d{5})(\d)/, '$1-$2');
  e.currentTarget.value = value;
  return e;
}

export function name(
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> {
  let { value } = e.currentTarget;
  value = value.replace(/\d/g, '');
  value = value.replace(/(?:^|\s)(?!da|de|do)\S/g, l => l.toUpperCase());
  e.currentTarget.value = value;
  return e;
}

export function date(
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 10;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/, '$1/$2');
  value = value.replace(/(\d{2})(\d)/, '$1/$2');
  e.currentTarget.value = value;
  return e;
}

export function time(
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 5;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/, '$1:$2');
  e.currentTarget.value = value;
  return e;
}

export function money(
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 100;
  let { value } = e.currentTarget;
  value = value.replace(/(?!,)\D/g, '');
  value = value.replace(/(<=\..*),/g, '');
  value = value.replace(/(<=,\d\d).*/g, '');
  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  const onlyNumbers = Number(value.replace(/\D/gim, ''));

  // e.currentTarget.value = value;

  // const currencyFractionDigits = new Intl.NumberFormat('pt-BR', {
  //   style: 'currency',
  //   currency: 'BRL',
  // }).resolvedOptions().maximumFractionDigits;
  // console.log(e)

  // let finalValue = (onlyNumbers).toLocaleString('pt-BR', { maximumFractionDigits: currencyFractionDigits });

  // if (finalValue.length > 3) {
  //   var i = finalValue.length - 2
  //   finalValue = finalValue.substr(0, i).concat(",").concat(finalValue.substr(i));
  // }

  function formatReal( int: number ) {
      var tmp = int+'';
      tmp = tmp.replace(/(?!,)\D/g, '');
      if(tmp.length > 2) {
        tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
      }
      if( tmp.length > 6 ) {
        tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
      }
      tmp = tmp.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      return tmp;
  }

  e.currentTarget.value = formatReal(onlyNumbers)
  return e;
}

export function number(
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 100;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  e.currentTarget.value = value;
  return e;
}

export function cnpj(
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 19;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, ''); // Remove tudo o que não é dígito
  value = value.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
  value = value.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca uma barra entre o oitavo e o nono dígitos
  value = value.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos

  e.currentTarget.value = value;
  return e;
}
