/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Tooltip from 'react-tooltip-lite';

// import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { FiImage, FiShoppingCart } from 'react-icons/fi';
import { zonedTimeToUtc } from 'date-fns-tz';
import { parseISO, isAfter, format } from 'date-fns';

import { Flex, Heading } from '@chakra-ui/react';
import logoImgMin from '../../../../assets/logo-hub-min.svg';
import imageAguardando from '../../../../assets/illustrations/esperando.png';

import { formatPrice } from '../../../../utils/format';

import { Content } from './styles';

import 'react-tabs/style/react-tabs.css';

import Dashboard from '../../../../components/Dashboard';
import { IState } from '../../../../store';
import { IListState } from '../../../../store/modules/list/types';
import TableLoading from '../../../../components/Table/Loading';
import api from '../../../../services/api';
import { addListInfo } from '../../../../store/modules/list/actions';
import ProductListOptions from '../../../../components/ProductListOptions';
import { item } from '../../../../store/modules/cart/types';
import { IUserInfoState } from '../../../../store/modules/user/types';

const NewOrder: React.FC = () => {
  const dispatch = useDispatch();
  const listData = useSelector<IState, IListState>(state => state.list);
  const [tableLoad, setTableLoad] = useState<boolean>(true);
  const [hasList, setHasList] = useState<boolean>(false);

  const cartItem = useSelector<IState, item[]>(state => state.cart.items);
  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);

  const totalValue = cartItem
    .map(items => {
      return items;
    })
    .reduce(
      (total: any, itemInfo: any) =>
        total +
        Number(itemInfo.product.discount_value || itemInfo.product.value) *
          itemInfo.quantidade,
      0,
    );

  const totalDicsValue = cartItem
    .map(items => {
      return items;
    })
    .reduce(
      (total: any, itemInfo: any) =>
        total +
        (itemInfo.product.discount_value
          ? (Number(itemInfo.product.value) -
              Number(itemInfo.product.discount_value)) *
            itemInfo.quantidade
          : 0),
      0,
    );

  useEffect(() => {
    setTableLoad(true);

    api.get(`/list/available`).then(response => {
      dispatch(addListInfo(response.data));
      setTableLoad(false);
      if (response.data.length > 0) {
        setHasList(true);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const activeUsersList = usersList.filter();

  const listMap = listData?.list
    .flatMap(function listMapping(obj: any) {
      return obj.products;
    })
    .concat();

  const discountList = listMap
    ?.filter(function productsFilter(obj: any) {
      if (obj.qtd > 0 && obj.valor_desconto) {
        return true;
      }
      return false;
    })
    .map(function productsMapping(obj: any) {
      return [
        obj.id,
        obj.valor_desconto ? (
          <div className="newOrder_promologo">
            <Tooltip
              direction="up"
              content="Super oferta HUB"
              tagName="span"
              className="target"
            >
              <img
                // data-tip="<b>SUPER PROMOÇÃO HUB</b>"
                src={logoImgMin}
                alt={obj.name}
              />
            </Tooltip>{' '}
            {obj.product_info.family}
          </div>
        ) : (
          obj.product_info.family
        ),
        obj.product_info.image_url ? (
          <Tooltip
            // direction="up"
            content={<img src={obj.product_info.image_url} alt={obj.name} />}
            tagName="span"
            eventOn="onClick"
            eventOff="onMouseOut"
            useHover={false}
            className="target newOrder_productName"
          >
            <FiImage /> {obj.name}
          </Tooltip>
        ) : (
          obj.name
        ),
        obj.product_info.size,
        obj.valor_desconto ? (
          <span className="desconto">
            <del>{formatPrice(obj.valor)}</del>{' '}
            {formatPrice(obj.valor_desconto)}
          </span>
        ) : (
          <span className="semdesconto">{formatPrice(obj.valor)}</span>
        ),
        obj.qtd,
        obj.valor,
        obj.valor_desconto,
        obj.product_info.family,
        obj.product_info.scientific_name,
        obj.name,
        obj.product_info.image_url,
        obj.product_info.type,
      ];
    });

  const normalList = listMap
    ?.filter(function productsFilter(obj: any) {
      if (obj.qtd > 0 && !obj.valor_desconto) {
        return true;
      }
      return false;
    })
    .map(function productsMapping(obj: any) {
      return [
        obj.id,
        obj.product_info.family,
        obj.product_info.image_url ? (
          <Tooltip
            // direction="up"
            content={<img src={obj.product_info.image_url} alt={obj.name} />}
            tagName="span"
            eventOn="onClick"
            eventOff="onMouseOut"
            useHover={false}
            className="target newOrder_productName"
          >
            {obj.name}
          </Tooltip>
        ) : (
          obj.name
        ),
        obj.product_info.size,
        obj.valor_desconto ? (
          <span className="desconto">
            <del>{formatPrice(obj.valor)}</del>{' '}
            {formatPrice(obj.valor_desconto)}
          </span>
        ) : (
          <span className="semdesconto">{formatPrice(obj.valor)}</span>
        ),
        obj.qtd,
        obj.valor,
        obj.valor_desconto,
        obj.product_info.family,
        obj.product_info.scientific_name,
        obj.name,
        obj.product_info.image_url,
        obj.product_info.type,
      ];
    });

  const list = [...discountList, ...normalList];

  const listPeixes = list.filter((obj: any) => {
    if (obj[12] === 1) {
      return true;
    }
    return false;
  });

  const listInvertebrados = list.filter((obj: any) => {
    if (obj[12] === 2) {
      return true;
    }
    return false;
  });

  const listCorais = list.filter((obj: any) => {
    if (obj[12] === 3) {
      return true;
    }
    return false;
  });

  const listProdutos = list.filter((obj: any) => {
    if (obj[12] === 4) {
      return true;
    }
    return false;
  });

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    // {
    //   name: 'active',
    //   options: {
    //     display: false,
    //     filter: false,
    //     draggable: false,
    //     searchable: false,
    //     viewColumns: false,
    //   },
    // },
    'Familia',
    'Nome',
    'Tamanho',
    'Valor Uni.',
    'QTD. Disponível',
    {
      name: 'value',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'discount_value',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'family',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'scientific_name',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'name',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: true,
        viewColumns: false,
      },
    },
    {
      name: 'image_url',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'type',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: '',
      options: {
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        customBodyRender: (value: any, tableMeta: any): any => {
          const productInfo = {
            id: tableMeta.rowData[0],
            name: tableMeta.rowData[10],
            size: tableMeta.rowData[3],
            value: tableMeta.rowData[6],
            discount_value: tableMeta.rowData[7],
            family: tableMeta.rowData[8],
            scientific_name: tableMeta.rowData[9],
            image_url: tableMeta.rowData[11],
            type: tableMeta.rowData[12],
          };

          return <ProductListOptions productInformation={productInfo} />;
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown' as any,
    filter: false,
    fixedHeader: true,
    pagination: false,
    rowsPerPage: 5000,
    print: false,
    search: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: 'Desculpe, nenhum resultado encontrado',
        toolTip: 'Ordenar§',
      },
      pagination: {
        next: 'Próxima pagina',
        previous: 'Pagina anterior',
        rowsPerPage: 'Resultados por pagina:',
        displayRows: 'de', // 1-10 of 30
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'Filtros',
        reset: 'Resetar',
      },
      viewColumns: {
        title: 'Mostrar colunas',
      },
      selectedRows: {
        text: 'resultado(s) detelado(s)',
        delete: 'Deletar',
      },
    },
  };

  let discountWithPercentValue = 0;

  if (userData.user.company?.desconto) {
    discountWithPercentValue =
      totalValue * (userData.user.company?.desconto / 100);
  }

  const finalOrderDate = listData.list.reduce((lastValue: any, value: any) => {
    const testDate = zonedTimeToUtc(parseISO(value.end), 'America/Sao_Paulo');
    // console.log(`o ultimo valor: ${lastValue}`);
    const endDateIsAfterTestedDate = isAfter(
      zonedTimeToUtc(testDate, 'America/Sao_Paulo'),
      zonedTimeToUtc(lastValue, 'America/Sao_Paulo'),
    );

    if (lastValue === 0) {
      return testDate;
    }

    if (endDateIsAfterTestedDate) {
      return testDate;
    }

    return lastValue;
  }, 0);

  const formatedFinalOrderDate = format(finalOrderDate, 'dd/MM/yyyy - HH:mm');

  const finalValue = totalValue - discountWithPercentValue;

  return (
    <Dashboard>
      <Content className="neworder-content">
        {!hasList ? (
          <Flex alignItems="center" justifyContent="center" flexDir="column">
            <Heading as="h3" fontSize={25} my={10} fontWeight="medium">
              Aguarde a liberação de uma nova lista.
            </Heading>
            <img
              // data-tip="<b>SUPER PROMOÇÃO HUB</b>"
              src={imageAguardando}
              style={{ maxWidth: '500px', width: '70%' }}
              alt="aguardando lista ativa"
            />
          </Flex>
        ) : (
          <>
            <h3
              style={{
                marginBottom: '35px',
                fontWeight: 'bold',
                fontSize: '22px',
              }}
            >
              Lista de Produtos <span>válida até {formatedFinalOrderDate}</span>
            </h3>
            {tableLoad ? (
              <TableLoading />
            ) : (
              <Tabs defaultIndex={0}>
                <TabList>
                  {listPeixes.length > 0 && <Tab>Peixes</Tab>}
                  {listInvertebrados.length > 0 && <Tab>Corais</Tab>}
                  {listCorais.length > 0 && <Tab>Invertebrados</Tab>}
                  {listProdutos.length > 0 && <Tab>Produtos</Tab>}
                </TabList>
                {listPeixes.length > 0 && (
                  <TabPanel>
                    <MUIDataTable
                      title=""
                      data={listPeixes}
                      columns={columns}
                      options={options}
                    />
                  </TabPanel>
                )}
                {listCorais.length > 0 && (
                  <TabPanel>
                    <MUIDataTable
                      title=""
                      data={listCorais}
                      columns={columns}
                      options={options}
                    />
                  </TabPanel>
                )}
                {listInvertebrados.length > 0 && (
                  <TabPanel>
                    <MUIDataTable
                      title=""
                      data={listInvertebrados}
                      columns={columns}
                      options={options}
                    />
                  </TabPanel>
                )}
                {listProdutos.length > 0 && (
                  <TabPanel>
                    <MUIDataTable
                      title=""
                      data={listProdutos}
                      columns={columns}
                      options={options}
                    />
                  </TabPanel>
                )}
              </Tabs>
            )}
            {totalValue !== 0.0 && (
              <div className="NewOrderCart">
                {!!userData.user.company?.desconto && (
                  <div className="NewOrderCart_discountTag">
                    Desconto de {userData.user.company?.desconto}% aplicado para{' '}
                    {userData.user.company.name}
                  </div>
                )}
                <div className="NewOrderCart_content">
                  <div className="NewOrderCart_content-left">
                    <p>TOTAL:</p>
                    <p className="NewOrderCart_total">
                      {formatPrice(finalValue)}
                    </p>
                    <p className="NewOrderCart_discount">
                      Economia de:{' '}
                      {formatPrice(totalDicsValue + discountWithPercentValue)}
                    </p>
                  </div>
                  <div className="NewOrderCart_content-right">
                    <Link to="/dashboard/cart">
                      {' '}
                      <FiShoppingCart /> Ver Pedido
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Content>
      {/* <ReactTooltip html /> */}
    </Dashboard>
  );
};

export default NewOrder;
