import React, { useCallback, useRef, useState } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoHub from '../../assets/logo-hub.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';
import GlobalStyle, { Container, Content } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail valido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        window.location.reload();

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
          });

          formRef.current?.setErrors(errors);

          return;
        }

        // if (err.response.data !== undefined) {
        //   if (err.response.data.message === 'Mail confirmation pending.') {
        //     addToast({
        //       type: 'error',
        //       title: 'Erro ao efetuar login',
        //       description: 'Você precisa confirmar o seu e-mail antes de logar',
        //     });
        //   } else if (err.response.data.message === 'JWT Token is missing') {
        //     // // console.log('aguardando aprovação...');
        //   } else {
        //     addToast({
        //       type: 'error',
        //       title: 'Erro ao efetuar login',
        //       description: 'Login ou Senha inválidos',
        //     });
        //   }
        // }
      } finally {
        setLoading(false);
      }
    },

    [signIn, addToast],
  );

  return (
    <>
      <Container>
        <Content>
          <img src={logoHub} alt="Hub" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>LOGIN</h1>
            <p>Bem-vindo, faça login para continuar.</p>

            <Input name="email" icon={FiMail} placeholder="E-mail" />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
            />

            <Link to="/forgot-password">Esqueci minha senha</Link>
            <Button loading={loading} type="submit">
              {' '}
              Entrar{' '}
            </Button>
          </Form>

          <p>
            Não tem acesso? <Link to="/signup">Solicitar Cadastro.</Link>
          </p>
        </Content>
      </Container>
      <GlobalStyle />
    </>
  );
};

export default SignIn;
