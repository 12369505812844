/* eslint-disable import/no-duplicates */
import React, { HTMLAttributes, useState, useEffect, useCallback } from 'react';

import { FiCalendar } from 'react-icons/fi';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import Slider from "react-slick";

import {IoIosArrowDroprightCircle, IoIosArrowDropleftCircle} from "react-icons/io"


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';
import deliveryTruck from '../../../assets/icons/delivery-truck.svg';
import timeBox from '../../../assets/icons/time-box.svg';
import successCheck from '../../../assets/icons/success-check.svg';

import { IOrder } from '../../../store/modules/orderprogress/types';

import {
  Container,
  ToggleButton,
  Orders,
  OrderBox,
  OrderInfo,
  OrderDescription,
  OrderIcon,
  OrderProgress,
} from './styles';
import { changeOrdersReveal } from '../../../store/modules/orderprogress/actions';
import { IState } from '../../../store';
import api from '../../../services/api';
import Icon from '@chakra-ui/icon';
import { Skeleton, SkeletonCircle } from '@chakra-ui/skeleton';
import { Box } from '@chakra-ui/layout';

type orderProps = HTMLAttributes<HTMLElement> & {
  type?: string;
  reveal?: number;
};

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <Icon
      as={IoIosArrowDropleftCircle}
      fontSize={25}
      color="white"
      opacity="0.75"
      _hover={{opacity: "1", color: "white"}}
      className={className}
      style={{
      ...style
      }}
      onClick={onClick}
    />
  );
}
function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <Icon
      as={IoIosArrowDroprightCircle}
      fontSize={25}
      color="white"
      opacity="0.75"
      _hover={{opacity: "1", color: "white"}}
      className={className}
      style={{
      ...style
      }}
      onClick={onClick}
    />
  );
}

const OrderProgressStatus: React.FC<orderProps> = () => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(true)

  const ordersReavelState = useSelector<IState>(
    state => state.orderProgress.reveal,
  );

  useEffect(() => {
    try {
    api.get('order/main').then(response => {
      setOrders(response.data);
    });
    } finally {
      setLoadingOrders(false)
    }
  }, []);

  const handleChangeOrdersReaveal = useCallback(() => {
    dispatch(changeOrdersReveal());
  }, [dispatch]);

  var settings = {
    dots: true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: 5,
    slidesToScroll: 1,
    touchThreshold:100,
    initialSlide: 0,
    infinite: false,
    centerMode: false,
    // rtl: true,
    // nextArrow: <Icon as={IoIosArrowDropright} fontSize={15}/>,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1744,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1111,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 704,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const fakeOrders = ["1", "2", "3", "4"]

  return (
    <Container>
      <h2>
        Pedidos em andamento{' '}
        <ToggleButton onClick={() => handleChangeOrdersReaveal()}>
          {ordersReavelState ? 'Esconder' : 'Exibir'}
        </ToggleButton>
      </h2>
      <Orders reveal={Number(ordersReavelState)}>
      {!loadingOrders ?
      <Slider {...settings}>

        {orders.map((order, index) => {
          // const orderIdFull = order.id;
          // const pos = orderIdFull.indexOf('-');
          // const orderId = orderIdFull.substring(0, pos);

          const originalOrderNumber = order.order_number;
          const rest = 5 - String(originalOrderNumber).length;
          const orderNumber =
            '0'.repeat(rest > 0 ? rest : 0) + originalOrderNumber;

          const updatedAtBr = utcToZonedTime(
            order.updated_at,
            'America/Sao_Paulo',
          );

          const dateFormatted = format(updatedAtBr, 'dd/MM/yyyy', {
            locale: ptBR,
          });
          const hourFormatted = format(updatedAtBr, 'HH:mm', {
            locale: ptBR,
          });

          let statusName = 'Aguardando';
          let statusColor = '#aaa';
          let statusIcon = timeBox;
          let statusBarProgress = '00';
          if (order.status === 2) {
            statusName = 'Em Separação';
            statusColor = '#7764E4';
            statusIcon = timeBox;
            statusBarProgress = '20';
          } else if (order.status === 3) {
            statusName = 'Em Transporte';
            statusColor = '#11CDEF';
            statusIcon = deliveryTruck;
            statusBarProgress = '50';
          } else if (order.status === 4) {
            statusName = 'Entregue';
            statusColor = '#00CB7F';
            statusIcon = successCheck;
            statusBarProgress = '100';
          }


          return (
            <div data-index={index} key={order.id}>
            <Link to={`/dashboard/order-detail?id=${order.id}`} >
              <OrderBox >
                <OrderInfo>
                  <OrderDescription>
                    <p>Pedido #{orderNumber}</p>
                    <h3>{statusName}</h3>
                  </OrderDescription>
                    <OrderIcon type={statusColor}>
                      <img src={statusIcon} alt="Icone" />
                    </OrderIcon>
                </OrderInfo>
                <OrderProgress type={statusColor}>
                  <p>
                    <FiCalendar /> Atualizado <span> {dateFormatted}</span> ás{' '}
                    <span>{hourFormatted}</span>
                  </p>
                  <progress max="100" value={statusBarProgress} />
                </OrderProgress>
              </OrderBox>
            </Link>
            </div>
          );
        })}
      </Slider> :
        <Box>
        <Slider {...settings}>
        {fakeOrders.map( key => {
            return (
              <Link to={`/`} key={key}>
                <OrderBox >
                  <OrderInfo>
                    <OrderDescription>
                      <Skeleton height="20px" width="120px" mb="5px"><p>Pedido</p></Skeleton>
                      <Skeleton><h3>nome</h3></Skeleton>
                    </OrderDescription>
                    <SkeletonCircle w="50px" h="50px" />
                  </OrderInfo>
                  <OrderProgress type="#ccc">
                    <p>
                    <Skeleton justifySelf="center" height="10px" width="10px" mr="5px" mb="5px" /> <Skeleton justifySelf="center" height="10px" width="120px" mb="5px">Atualizado <span> s</span> ás{' '}</Skeleton>
                    </p>
                    <Skeleton justifySelf="center" height="7px" width="100%" mt="10px" borderRadius="50px" />
                  </OrderProgress>
                </OrderBox>
                </Link>
            )
          })}
          </Slider>
      </Box>
      }
      </Orders>
    </Container>
  );
};

export default OrderProgressStatus;
