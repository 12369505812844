/* eslint-disable no-nested-ternary */
import React, { LabelHTMLAttributes } from 'react';
// import { Link } from 'react-router-dom';

import { Container } from './styles';

type IlabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  loading?: boolean;
  color?: string;
  link?: string;
  htmlIdFor: string;
  inputOnChange?: string;
};

const ImageButton: React.FC<IlabelProps> = ({
  children,
  loading,
  color,
  htmlIdFor,
  inputOnChange,
  // link,
  ...rest
}) => (
  <form>
    <Container
      loading={loading ? Number(loading) : 0}
      customColor={color !== undefined ? color : 'none'}
      htmlFor={htmlIdFor}
      {...rest}
    >
      {children}
    </Container>
  </form>
);

export default ImageButton;
