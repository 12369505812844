/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { IEditUser, IUser } from './types';

export function addUsersInfo(apiUsersInfo: IUser[] | undefined) {
  return {
    type: 'ADD_USERS_INFO',
    payload: {
      apiUsersInfo,
    },
  };
}

export function openAproveUsers() {
  return {
    type: 'OPEN_APROVE_USERS',
  };
}

export function closeAproveUsers() {
  return {
    type: 'CLOSE_APROVE_USERS',
  };
}

export function openReproveUsers() {
  return {
    type: 'OPEN_REPROVE_USERS',
  };
}

export function closeReproveUsers() {
  return {
    type: 'CLOSE_REPROVE_USERS',
  };
}

export function editUser(editUserInfo: IEditUser | undefined) {
  return {
    type: 'EDIT_USER',
    payload: {
      editUserInfo,
    },
  };
}

export function openEditUsers() {
  return {
    type: 'OPEN_EDIT_USERS',
  };
}

export function closeEditUsers() {
  return {
    type: 'CLOSE_EDIT_USERS',
  };
}

export function removeUsersInfo() {
  return {
    type: 'REMOVE_USERS_INFO',
  };
}
