/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { keyframes, css } from 'styled-components';

interface ContainerProps {
  loading: number;
  customColor: string;
}

const blink = keyframes`
  from {
    color: #ddd;
  }

  to {
    color: #eee;
  }
`;

export const LoadIcon = styled.div``;

export const Container = styled.label<ContainerProps>`
  background: none;
  border: none;
  width: auto;
  display: flex;
  margin-right: 15px;
  font-size: 20px;
  cursor: pointer;

  input {
    display: none;
  }

  ${props =>
    props.customColor !== 'none' &&
    css`
      svg {
        color: ${props.customColor} !important;
      }
    `}

  ${props =>
    props.loading === 1 &&
    css`
      color: #ccc;
    `}

  &:hover {
    color: #000b3a;
  }
`;
