import { createStore, applyMiddleware } from 'redux';
import storeSynchronize from 'redux-localstore';

import { composeWithDevTools } from 'redux-devtools-extension';

import createSagaMiddleware from 'redux-saga';

import rootReducer from './modules/rootReducer';

import { IOrderProgressState } from './modules/orderprogress/types';
import { IUserInfoState } from './modules/user/types';
import { IUsersInfoState } from './modules/users/types';
import { ICompanysState } from './modules/companys/types';
import { IProductsInfoState } from './modules/products/types';
import { ICartState } from './modules/cart/types';
import { IListState } from './modules/list/types';
import { ISpecificListState } from './modules/specificList/types';

import rootSaga from './modules/rootSaga';

export interface IState {
  orderProgress: IOrderProgressState;
  userInfo: IUserInfoState;
  usersInfo: IUsersInfoState;
  companysInfo: ICompanysState;
  productsInfo: IProductsInfoState;
  cart: ICartState;
  list: IListState;
  specificList: ISpecificListState;
}

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

storeSynchronize(store);

sagaMiddleware.run(rootSaga);

export default store;
