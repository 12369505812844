import React, { useContext, useEffect } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Content } from './styles';

import WaitingImage from '../../../assets/illustrations/esperando.png';

import Dashboard from '../../../components/Dashboard';
import api from '../../../services/api';
import { OrdersContext } from '../../../context/Orders';
import { OrdersView } from '../../../components/Dashboard/Admin/Home/OrdersView';

const DefaultUserDashboard: React.FC = () => {
  const { setOrders, orders, ordersLoading, setOrdersLoading } =
    useContext(OrdersContext);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function loadOrders() {
      try {
        api.get('/order').then(response => {
          setOrders(response.data);
        });
      } catch {
        setOrders([]);
      } finally {
        setOrdersLoading(false);
      }
    }

    loadOrders();
  }, []);

  return (
    <Dashboard>
      <div>
        {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                <table id="table-to-xls">
                    <tr>
                        teste
                    </tr>
                    <tr>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Age</th>
                    </tr>
                    <tr>
                        <td>Jill</td>
                        <td>Smith</td>
                        <td>50</td>
                    </tr>
                    <tr>
                        <td>Eve</td>
                        <td>Jackson</td>
                        <td>94</td>
                    </tr>
                </table> */}
      </div>

      {ordersLoading ? (
        <Flex w="100%" justifyContent="center" pt={10}>
          <Spinner color="#11cdef" />
        </Flex>
      ) : (
        <Content>
          {orders && orders?.length > 0 ? (
            <OrdersView />
          ) : (
            <div className="alert">
              <p>Aguardando um novo pedido.</p>
              <img src={WaitingImage} alt="aguardando informação" />
            </div>
          )}
        </Content>
      )}
    </Dashboard>
  );
};

export default DefaultUserDashboard;
