import React, { useEffect, useState } from 'react';
import {
  FiCircle,
  FiCheckCircle,
  FiAlertCircle,
  FiXCircle,
  FiArrowRight,
} from 'react-icons/fi';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { BiBlock } from 'react-icons/bi';
import api from '../../../../../services/api';

import Dashboard from '../../../../../components/Dashboard';
import { Content } from './styles';
import { useToast } from '../../../../../hooks/toast';

interface ItemTable {
  id: string;
  order: string;
  qtd: number;
  qtdPostada?: number;
  list_product: {
    id: string;
    product_info: {
      name: string;
      family: string;
      image_ur: string | null;
      size: string;
    };
  };
}

const Orders: React.FC = () => {
  const { params }: any = useRouteMatch();

  const [productsInfo, setProductsInfo] = useState<any>([]);
  const [number, setNumber] = useState(0);
  const [buttonOk, setButtonOk] = useState([]);

  const { addToast } = useToast();

  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSubmite = async () => {
    try {
      await api
        .put('/order/delivery', {
          products: productsInfo,
          orderId: params.id,
        })
        .then(response => {
          addToast({
            type: 'success',
            title: 'Pedido enviado com sucesso!',
          });
          history.push('/admin/orders');
        });
    } catch (err) {
      // const error = err.response?.data?.message;

      // let description = '';

      // if (error === 'this order already delivered') {
      //   description = 'esse pedido já foi separado';
      // }

      // addToast({
      //   type: 'error',
      //   title: 'Erro ao enviar pedido!',
      //   description,
      // });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function getProducts() {
      await api.get(`/order/products?order_id=${params.id}`).then(response => {
        response.data.map((item: any) => {
          const newItem = item;
          newItem.qtdPostada = undefined;
          return newItem;
        });
        setProductsInfo(response.data);
        setButtonOk(response.data);
      });
    }
    getProducts();
  }, []);

  useEffect(() => {
    let productsInfoVerify = productsInfo;
    productsInfoVerify = productsInfoVerify.filter((item: ItemTable) => {
      const verify = !item.qtdPostada && item.qtdPostada !== 0;
      return verify;
    });
    setButtonOk(productsInfoVerify);
  }, [number, productsInfo]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleMaxQtd = (id: string, products: any, zero = false) => {
    const newProductsInfo = products;
    newProductsInfo.map((item: ItemTable) => {
      const newItem = item;
      if (item.id === id) {
        newItem.qtdPostada = item.qtd;
        if (zero) {
          newItem.qtdPostada = 0;
        }
      }
      return newItem;
    });

    setProductsInfo(newProductsInfo);
    setNumber(number + 1);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleInputChange = (qtd: any, id: string, products: any) => {
    const newProductsInfo = products;
    newProductsInfo.map((item: ItemTable) => {
      const newItem = item;
      if (item.id === id) {
        if (qtd > item.qtd) {
          newItem.qtdPostada = item.qtd;
          addToast({
            type: 'alert',
            title: 'Item não adicionado',
            description: `Quantidade máxima atingida (${item.qtd} produto${
              item.qtd > 1 ? 's' : ''
            })`,
          });
        } else if (qtd < 0) {
          newItem.qtdPostada = 0;
        } else {
          newItem.qtdPostada = qtd;
        }
      }
      return newItem;
    });

    setProductsInfo(newProductsInfo);
    setNumber(number + 1);
  };

  return (
    <Dashboard>
      <Content>
        {
          // console.log(productsInfo)
        }
        <h2>
          Liberar Pedido #
          {productsInfo[0] && productsInfo[0].order_info.order_number}
        </h2>
        <table>
          <thead>
            <tr>
              <td>Grupo</td>
              <td>Nome</td>
              <td>Tamanho</td>
              <td>Qtd. Pedido</td>
              <td />
              <td>Qtd. Postada</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {productsInfo.map((itemTable: ItemTable) => {
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              function alertType() {
                if (!itemTable.qtdPostada && itemTable.qtdPostada !== 0) {
                  return <FiCircle style={{ color: '#888' }} />;
                }

                // eslint-disable-next-line eqeqeq
                if (itemTable.qtdPostada == 0) {
                  return <FiXCircle style={{ color: '#fd0000' }} />;
                }
                // eslint-disable-next-line eqeqeq
                if (itemTable.qtdPostada == itemTable.qtd) {
                  return <FiCheckCircle style={{ color: '#01e634' }} />;
                }

                if (itemTable.qtdPostada < itemTable.qtd) {
                  return <FiAlertCircle style={{ color: '#f3c000' }} />;
                }
                return undefined;
              }

              let trClass = 'qtdUndefined';

              // console.log(itemTable.qtdPostada)

              if (
                Number(itemTable.qtdPostada) !== Number(itemTable.qtd) &&
                Number(itemTable.qtdPostada) > 0
              ) {
                trClass = '';
              }

              if (
                String(itemTable.qtdPostada) !== '' &&
                Number(itemTable.qtdPostada) === 0
              ) {
                trClass = 'qtdZero';
              }

              if (Number(itemTable.qtdPostada) === Number(itemTable.qtd)) {
                trClass = 'qtdTotal';
              }

              return (
                <tr
                  className={trClass}
                  key={itemTable.id}
                  style={{ overflow: 'scroll' }}
                >
                  <td>{itemTable.list_product.product_info.family}</td>
                  <td>{itemTable.list_product.product_info.name}</td>
                  <td>{itemTable.list_product.product_info.size}</td>
                  <td>{itemTable.qtd}</td>
                  <td className="qtdButtons">
                    <button
                      onClick={() => {
                        handleMaxQtd(itemTable.id, productsInfo);
                      }}
                      type="button"
                      className="maxQtdButton"
                    >
                      {/* > */}
                      <FiArrowRight />
                    </button>
                    <button
                      onClick={() => {
                        handleMaxQtd(itemTable.id, productsInfo, true);
                      }}
                      type="button"
                      className="maxQtdButton"
                    >
                      {/* > */}
                      <BiBlock />
                    </button>
                  </td>
                  <td style={{ minWidth: '120px' }}>
                    <button
                      className="buttonMinus"
                      onClick={() =>
                        handleInputChange(
                          Number(itemTable.qtdPostada ?? 0) - 1,
                          itemTable.id,
                          productsInfo,
                          // eslint-disable-next-line prettier/prettier
                        )}
                      type="button"
                    >
                      -
                    </button>
                    <input
                      onChange={e =>
                        handleInputChange(
                          e.currentTarget.value,
                          itemTable.id,
                          productsInfo,
                          // eslint-disable-next-line prettier/prettier
                        )}
                      type="number"
                      value={itemTable.qtdPostada}
                    />
                    <button
                      className="buttonPlus"
                      onClick={() =>
                        handleInputChange(
                          Number(itemTable.qtdPostada ?? 0) + 1,
                          itemTable.id,
                          productsInfo,
                          // eslint-disable-next-line prettier/prettier
                        )}
                      type="button"
                    >
                      +
                    </button>
                  </td>
                  <td>{alertType()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="tableFooter">
          {buttonOk.length !== 0 ? (
            <>
              * você precisa preencher todos os campos antes de confirmar
              <button type="button" disabled>
                Confirmar
              </button>
            </>
          ) : (
            <button
              type="button"
              className="sendButtonOk"
              onClick={handleSubmite}
            >
              Confirmar
            </button>
          )}
        </div>
      </Content>
    </Dashboard>
  );
};

export default Orders;
