/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import MUIDataTable from 'mui-datatables';
import React from 'react';

interface ListProductsTableProps {
  finalList: any;
}

export function ListProductsTable({ finalList }: ListProductsTableProps) {
  return (
    <MUIDataTable
      title=""
      data={finalList.listProducts}
      columns={finalList.columns}
      options={finalList.options}
    />
  );
}
