/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FiFileText, FiMapPin, FiPercent, FiUser } from 'react-icons/fi';
import { AiOutlineFieldNumber } from 'react-icons/ai';
import { FaCity, FaMap, FaRegBuilding } from 'react-icons/fa';
// import cep from 'cep-promise';
import Input from '../../../../Input';
import getValidationErrors from '../../../../../utils/getValidationErrors';

import { IState } from '../../../../../store';

import { Container, Divisor } from './styles';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import {
  addCompanysInfo,
  closeAddCompany,
} from '../../../../../store/modules/companys/actions';
import Button from '../../../../Button';
import { IUsersInfoState } from '../../../../../store/modules/users/types';
import { ICompanysState } from '../../../../../store/modules/companys/types';

interface AproveUserFormData {
  id: string;
  data_id: string;
  active: boolean;
  name: string;
  email: string;
  company_name: string;
  mobile_phone: string;
  whatsapp: boolean;
  phone?: string;
  group?: number;
}

const AddCompany: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(false);
  const [cepLoad, setCepLoad] = useState(false);
  const [cepInfo, setCepInfo] = useState<any>();
  const dispatch = useDispatch();

  const handleCEP = useCallback(async (cepData: string): Promise<void> => {
    const cepNumber = cepData.replace(/[^\d]+/g, '');
    if (cepNumber.length === 8) {
      try {
        setCepLoad(true);
        const appKey = 'EJybPnLEOekct4fcpjys47ONsNrqPkCs';
        const appSecret = 'dluhaghugfjJ1RnOJ1Ctf82OmKIvaox5C8NffKmjcXqkvq3v';
        const cepApiResponse = await Promise.race([
          axios
            .create({ baseURL: 'https://webmaniabr.com/api/1/cep' })
            .get(`/${cepData}/?app_key=${appKey}&app_secret=${appSecret}`),
        ]);
        setCepInfo(cepApiResponse.data);
        // // console.log(cepApiResponse);
      } catch {
        // // console.log('não encontrado');
      } finally {
        setCepLoad(false);
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: AproveUserFormData): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da Empresa obrigatório'),
          razao_social: Yup.string().required('Razão Social Obrigatório'),
          responsavel: Yup.string().required('Responsavel Obrigátorio'),
          CNPJ: Yup.string().required('CPNJ Obrigatório'),
          inscricao_estadual: Yup.string().required(
            'Incrição estadual Obrigatória',
          ),
          cep: Yup.string().required('Cep Obrigatório'),
          endereco: Yup.string().required('Endereço Obrigatório'),
          numero: Yup.string().required('Numero Obrigatório'),
          // complemento: Yup.string().required('Complemento Obrigatório'),
          cidade: Yup.string().required('Cidade Obrigatório'),
          UF: Yup.string().required('UF Obrigatório'),
          desconto: Yup.number().required('Desconto Obrigatório'),
          bairro: Yup.string().required('Bairro Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // console.log('passou o validate');
        await api.post(`/company`, data).then(async function aproveUser() {
          await api.get(`/company`).then(companys => {
            dispatch(addCompanysInfo(companys.data));
          });

          dispatch(closeAddCompany());

          addToast({
            type: 'success',
            title: 'Empresa criada com sucesso!',
          });
        });

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let description;

          // console.log(errors);

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }
      } finally {
        setLoading(false);
      }
    },

    [addToast, dispatch, switchStatus],
  );

  useEffect(() => {
    const profileLoad = async (): Promise<void> => {
      await api.get(`/company`).then(response => {
        dispatch(addCompanysInfo(response.data));

        setLoading(false);
      });
    };

    profileLoad();
  }, [addToast, dispatch]);

  const { userEditProfile, aprovingUser } = useSelector<
    IState,
    IUsersInfoState
  >(state => state.usersInfo);

  const { addCompany } = useSelector<IState, ICompanysState>(
    state => state.companysInfo,
  );

  useEffect(() => {
    userEditProfile && setSwitchStatus(userEditProfile.whatsapp);
  }, [userEditProfile]);

  return (
    <>
      {!addCompany ? (
        <Container>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="name"
              mask="name"
              icon={FaRegBuilding}
              placeholder="Nome da empresa"
            />

            <Divisor>
              <div className="double">
                <Input
                  name="razao_social"
                  icon={FaRegBuilding}
                  placeholder="Razão Social"
                />
              </div>
              <div className="double">
                <Input
                  name="CNPJ"
                  icon={FiFileText}
                  mask="cnpj"
                  placeholder="CNPJ"
                />
              </div>
            </Divisor>
            <Divisor>
              <div className="double">
                <Input
                  name="responsavel"
                  mask="name"
                  icon={FiUser}
                  placeholder="Responsável"
                />
              </div>
              <div className="double">
                <Input
                  name="inscricao_estadual"
                  icon={FiFileText}
                  placeholder="Inscrição Estadual"
                />
              </div>
            </Divisor>

            <Divisor>
              <div className="double">
                <Input
                  name="desconto"
                  mask="number"
                  icon={FiPercent}
                  placeholder="Desconto (porcentagem)"
                />
              </div>
            </Divisor>

            <Divisor style={{ marginTop: 40 }}>
              <div className="double cepError">
                <Input
                  name="cep"
                  mask="cep"
                  onChange={e => {
                    const { value } = e.currentTarget;
                    handleCEP(value);
                  }}
                  icon={FiMapPin}
                  cepLoad={cepLoad}
                  placeholder="Cep"
                />
                <span>{cepInfo?.error && 'Cep não encontrado'} </span>
              </div>
              <div className="double">
                <Input
                  name="numero"
                  mask="number"
                  icon={AiOutlineFieldNumber}
                  placeholder="Numero"
                />
              </div>
            </Divisor>
            <Divisor>
              <div className="double">
                <Input
                  name="endereco"
                  icon={FaMap}
                  placeholder="Endereço"
                  value={cepInfo?.endereco ? cepInfo.endereco : ''}
                  inactive
                  readOnly
                />
              </div>
              <div className="double">
                <Input
                  name="bairro"
                  icon={FaMap}
                  placeholder="Bairro"
                  value={cepInfo?.bairro ? cepInfo.bairro : ''}
                  inactive
                  readOnly
                />
              </div>
            </Divisor>

            <Divisor>
              <div className="double">
                <Input
                  name="cidade"
                  icon={FaCity}
                  placeholder="Cidade"
                  value={cepInfo?.cidade ? cepInfo.cidade : ''}
                  inactive
                  readOnly
                />
              </div>
              <div className="double">
                <Input
                  name="UF"
                  icon={FaCity}
                  placeholder="Estado"
                  value={cepInfo?.uf ? cepInfo.uf : ''}
                  inactive
                  readOnly
                />
              </div>
            </Divisor>

            <Divisor className="modal-form_button-divisor">
              <Button loading={loading} type="submit">
                Criar
              </Button>
            </Divisor>
          </Form>
        </Container>
      ) : (
        ''
      )}
    </>
  );
};

export default AddCompany;
