/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { ICartState, product } from './types';

export function pageCartCheck(cartInfo: ICartState | undefined) {
  return {
    type: 'PAGE_CART_CHECK',
    payload: {
      cartInfo,
    },
  };
}

export function addProductToCartRequest(productInfo: product | undefined) {
  return {
    type: 'ADD_TO_CART_REQUEST',
    payload: {
      productInfo,
    },
  };
}

export function updateProductToCartRequest(qtd: number, productInfo: product | undefined) {
  return {
    type: 'UPDATE_TO_CART_REQUEST',
    payload: {
      productInfo,
      qtd
    },
  };
}

export function addProductToCartSuccess(productInfo: product | undefined) {
  return {
    type: 'ADD_TO_CART_SUCCESS',
    payload: {
      productInfo,
    },
  };
}

export function updateProductToCartSuccess(
  productId: string | undefined,
  qtd: number,
) {
  return {
    type: 'UPDATE_TO_CART_SUCCESS',
    payload: {
      productId,
      qtd,
    },
  };
}

export function cleanCart() {
  return {
    type: 'CLEAN_CART',
  };
}

export function addProductToCartFailure(productId: string) {
  return {
    type: 'ADD_TO_CART_FAILURE',
    payload: {
      productId,
    },
  };
}

export function decreaseProductToCartRequest(productInfo: product | undefined) {
  return {
    type: 'DECREASE_TO_CART_REQUEST',
    payload: {
      productInfo,
    },
  };
}

export function decreaseProductToCartSuccess(productId: string | undefined) {
  return {
    type: 'DECREASE_TO_CART_SUCCESS',
    payload: {
      productId,
    },
  };
}

export function decreaseProductToCartFailure(productId: string | undefined) {
  return {
    type: 'DECREASE_TO_CART_FAILURE',
    payload: {
      productId,
    },
  };
}
