import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Content = styled.div`
  /* max-width: 100%; */
  padding: 40px;
  max-width: 1520px;
  margin: 0 auto;
  overflow: scroll;

  h2 {
    margin-bottom: 2rem;
  }

  table {
    min-width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    color: #000b3a;
    border-spacing: 0;
    padding: 1rem 0;

    td:first-child {
      padding-left: 2rem;
    }
    td:last-child {
      padding-right: 1rem;
    }

    td {
      padding: 0.4rem 0.3rem;
    }

    tr:nth-child(even) {
      background-color: #f7fafc;
    }

    tr:hover {
      background-color: #dff1fd !important;
    }

    .qtdButtons {
      display: flex;
      align-items: center;

      button:last-child {
        margin-left: 0.4rem;
        border-color: #999;
        color: #444;
        /* padding: 0.25rem 1rem; */

        svg {
          color: #f00;
        }

        &:hover {
          border-color: #f00;
          background-color: #f00;

          svg {
            color: #fff;
          }
        }
      }
    }

    thead {
      background-color: #f1f3f9;
      color: #4c5c6f;
      font-size: 0.8rem;
    }

    tbody {
      svg {
        font-size: 1.5rem;
        color: #009ce1;
      }

      input {
        padding: 0.2rem 0.5rem;
        max-width: 3rem;
        border: 1px solid #172b4d;
        border-left: none;
        border-right: none;
        text-align: center;
      }

      button {
        background-color: #fff;
        border: 1px solid #172b4d;
        padding: 0.2rem 0.5rem;
      }

      .buttonMinus {
        border-radius: 5px 0 0 5px;
      }

      .buttonPlus {
        border-radius: 0 5px 5px 0;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  .maxQtdButton {
    background-color: transparent;
    border: 1px solid #999;
    border-radius: 10px;
    font-size: 0.8rem !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 1rem;

    transition: 0.3s filter 0.3, 0.3s background-color;

    svg {
      font-size: 1rem;
      color: #009ce1;
      transition: 0.3s color;
    }

    &:hover {
      background-color: #009ce1;
      border-color: #009ce1;

      svg {
        color: #fff;
      }
    }
  }

  .maxQtdButton:hover {
    filter: brightness(1.3);
  }

  @keyframes sendButton {
    0% {
      opacity: 0.5;
      cursor: not-allowed;
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .qtdZero {
    td {
      color: #f0000070;
    }
  }

  .qtdTotal {
    td {
      color: #00b557;
    }
  }

  .qtdUndefined {
    td {
      color: #172b4d70;
    }
  }

  .tableFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-top: 2rem;

    button {
      background-color: #000b3a;
      color: #fff;
      padding: 0.6rem 1.2rem;
      border: 0px;
      border-radius: 5px;
    }

    .sendButtonOk {
      animation: sendButton 1s;
    }

    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      margin-top: 1rem;
    }
  }
`;

export const LoadingStatus = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f9;

  svg {
    animation: ${rotate} 1.3s linear infinite;
    color: #000b3a;
    font-size: 80px;
  }
`;

export const display = styled.div`
  /* max-width: 100%; */
  padding: 40px;
`;
