/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect } from 'react';

import { FiHome, FiShoppingCart, FiBell, FiPower } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Container, Header, HeaderLeft, HeaderRight } from './styles';

import { toast } from 'react-toastify';

import defaultAvatar from '../../../assets/default-avatar.png';

import { useAuth } from '../../../hooks/auth';
import { IState } from '../../../store';
import { IUserInfoState } from '../../../store/modules/user/types';
import { Avatar, Flex, HStack, Text } from '@chakra-ui/react';
import { ICartState } from '../../../store/modules/cart/types';
import api from '../../../services/api';
import { updateProductToCartSuccess } from '../../../store/modules/cart/actions';

const HeaderComponent: React.FC = () => {
  const dispatch = useDispatch();
  const pathName = useLocation().pathname
  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);


  console.log(pathName)

  const cart = useSelector<IState, ICartState>(state => {
    return state.cart;
  });

  const handleUpdateCartItem = useCallback(
    (productInfo, qtd) => {
      dispatch(updateProductToCartSuccess(productInfo, qtd));
    },
    [dispatch],
  );

  useEffect(() => {
    cart.items.map(async (item: any) => {
      try {
        await api.get(`/list/product?id=${item.product.id}`).then(
          response => {
            if (!response.data) {
              handleUpdateCartItem(item.product.id, 0)
              toast.error(`Um dos produtos do carrinho não está mais em estoque! Revise o seu pedido`, {
                autoClose: 15000
              });
            }
            if (item.quantidade > response.data.qtd) {
              // console.log('entrou aqui')
              handleUpdateCartItem(item.product.id, response.data.qtd)
              // console.log("um produto foi atualizado")
              if (pathName !== "/dashboard/cart" && pathName !== "/dashboard/order-detail") {
                toast.warning(`Um dos produtos do carrinho sofreu alteração!`);
              }

              return
            }
            return
          }
        )
      } catch(error) {  toast.error(`algo deu errado!`) }
    })
  } ,[])

  const { signOut } = useAuth();

  const path = window.location.pathname;
  let pageLink = path.split('/')[2];

  if (pageLink === '' || pageLink === undefined) {
    pageLink = 'home';
  }

  const CartQtd = useSelector<IState, number>(state => {
    return state.cart.items.length;
  });

  return (
    <Container>
      <Header>
        <HeaderLeft>
          {/* <FiHome /> <Link to="/">Home</Link> - <Link to="/">Dashboard</Link> */}
        </HeaderLeft>
        <HeaderRight>
          <HStack spacing={5} fontSize={18}>
            <Flex fontSize={22} hidden={!(userData.user.permission === 1)} >
              <Link to="/dashboard/cart">
                <FiShoppingCart />
                {CartQtd > 0 && <div className="cartBadge"> {CartQtd} </div>}
              </Link>
            </Flex>
            <Flex fontSize={22}>
              <FiBell />
            </Flex>
            <Flex alignItems="center">
              {userData.loading ? (
                <SkeletonTheme color="#aaa" highlightColor="#eee">
                  <Skeleton circle width={40} height={40} />
                </SkeletonTheme>
              ) : (
                <Avatar mr={5} bg="#10cdef" src={userData.user.avatar_url} name={userData.user.name} />

              )}

              {!userData.loading ? (
               <Text whiteSpace="nowrap"> {userData.user.name} </Text>
              ) : (
                <SkeletonTheme color="#aaa" highlightColor="#eee">
                  <Skeleton width={100} height={10} />
                </SkeletonTheme>
              )}
            </Flex>

            <Flex cursor="pointer" color="#11cdef">
              <FiPower onClick={signOut}/>
            </Flex>

          </HStack>
        </HeaderRight>
      </Header>
    </Container>
  );
};

export default HeaderComponent;

