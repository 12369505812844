/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';

import { defineState } from 'redux-localstore';
import { ICartState } from './types';
import api from '../../../services/api';

const defaultState: ICartState = {
  items: [],
  loading: true,
  error: false,
};


// const initalState = defineState(defaultState)('cart');

 const INITIAL_STATE = defineState(defaultState)('cart');


const userInfo: Reducer<ICartState> = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line consistent-return
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_TO_CART_SUCCESS': {
        const { productInfo } = action.payload;

        const productInCartIndex = draft.items.findIndex(
          item => item.product.id === productInfo.id,
        );

        if (productInCartIndex >= 0) {
          draft.items[productInCartIndex].quantidade += 1;
        } else {
          draft.items.push({ product: productInfo, quantidade: 1 });
        }

        draft.loading = false;
        draft.error = false;

        return draft;
      }

      case 'UPDATE_TO_CART_SUCCESS': {
        const { productId, qtd } = action.payload;

        const productInCartIndex = draft.items.findIndex(
          item => item.product.id === productId,
        );

        if (draft.items[productInCartIndex] !== undefined) {
          draft.items[productInCartIndex].quantidade = qtd;
        }

        if (qtd === 0) {
          draft.items.splice(productInCartIndex, 1);
        }

        draft.loading = false;
        draft.error = false;

        return draft;
      }

      case 'DECREASE_TO_CART_SUCCESS': {
        const { productId } = action.payload;

        const productInCartIndex = draft.items.findIndex(
          item => item.product.id === productId,
        );

        if (productInCartIndex >= 0) {
          if (draft.items[productInCartIndex].quantidade === 1) {
            draft.items.splice(productInCartIndex, 1);
          } else {
            draft.items[productInCartIndex].quantidade -= 1;
          }
        } else {
          // draft.items.push({ product: productInfo, quantidade: 1 });
        }

        draft.loading = false;
        draft.error = false;

        return draft;
      }

      case 'CLEAN_CART': {
        draft.items = [];

        draft.loading = false;
        draft.error = false;

        return draft;
      }

      default: {
        // return INITIAL_STATE;
        break;
      }
    }
  });
};

export default userInfo;
