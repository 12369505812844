/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { ICompany } from './types';

export function addCompanysInfo(apiCompanysInfo: ICompany[] | undefined) {
  return {
    type: 'ADD_COMPANYS_INFO',
    payload: {
      apiCompanysInfo,
    },
  };
}

export function removeCompanysInfo() {
  return {
    type: 'REMOVE_COMPANYS_INFO',
  };
}

export function openAddCompany() {
  return {
    type: 'OPEN_ADD_COMPANY',
  };
}

export function closeAddCompany() {
  return {
    type: 'CLOSE_ADD_COMPANY',
  };
}
