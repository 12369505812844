/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';

import { FiCheckCircle, FiCircle, FiMinus, FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';

import { item, product } from '../../store/modules/cart/types';
import {
  addProductToCartRequest,
  decreaseProductToCartRequest,
} from '../../store/modules/cart/actions';
import { IState } from '../../store';

interface productsProps {
  productInformation: product;
}

const ProductListOptions: React.FC<productsProps> = ({
  // children,
  productInformation,
  // ...rest
}) => {
  const dispatch = useDispatch();
  const handleAddCartItem = useCallback(
    productInfo => {
      dispatch(addProductToCartRequest(productInfo));
    },
    [dispatch],
  );

  const handleRemoveCartItem = useCallback(
    productInfo => {
      dispatch(decreaseProductToCartRequest(productInfo));
    },
    [dispatch],
  );

  const hasFieldStockCheck = useSelector<IState, boolean>(state => {
    return state.cart.items
      .map(items => {
        return items.product.id.includes(productInformation.id);
      })
      .includes(true);
  });

  const stockInfo = useSelector<IState, item>(state => {
    return state.cart.items
      .filter(items => {
        return items.product.id.includes(productInformation.id);
      })
      .map(items => {
        return items;
      })[0];
  });

  return (
    <Container>
      <div className="newOrder_buttons">
        <button
          onClick={() => handleRemoveCartItem(productInformation)}
          className="newOrder_button_remove"
          type="button"
        >
          <FiMinus size={12} />
        </button>
        <input type="number" value={stockInfo?.quantidade || 0} />
        <button
          onClick={() => handleAddCartItem(productInformation)}
          type="button"
          className="newOrder_button_add"
          style={{ marginRight: '20px' }}
        >
          <FiPlus size={12} />
        </button>
        {hasFieldStockCheck ? (
          <FiCheckCircle size={28} className="newOrder_product_checkedIn" />
        ) : (
          <FiCircle size={28} className="newOrder_product_checkedOut" />
        )}
        {/* <FiCircle size={28} className="newOrder_product_checkedOut" /> */}
        {/* <FiCheckCircle
                size={28}
                className="newOrder_product_checkedOut"
              /> */}
      </div>
    </Container>
  );
};

export default ProductListOptions;
