/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MUIDataTable from 'mui-datatables';
import ReactTooltip from 'react-tooltip';
import { FiBox, FiCheck, FiEdit, FiPlus, FiX } from 'react-icons/fi';
import { parseISO, format, isBefore } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { zonedTimeToUtc } from 'date-fns-tz';
import { isAfter } from 'date-fns/esm';
import { Link } from 'react-router-dom';
// import { formatPrice } from '../../../../utils/format';
import { useDispatch, useSelector } from 'react-redux';
import ActionButton from '../../../../components/Table/ActionButton';

import api from '../../../../services/api';

import { Content } from './styles';

import 'react-tabs/style/react-tabs.css';

import Dashboard from '../../../../components/Dashboard';
import TableLoading from '../../../../components/Table/Loading';
import ReproveList from '../../../../components/Dashboard/Admin/Lists/ReproveList';
import { addListInfo } from '../../../../store/modules/list/actions';
import { IState } from '../../../../store';
import { IListState } from '../../../../store/modules/list/types';
import ActivateList from '../../../../components/Dashboard/Admin/Lists/ActivateList';
import EditList from '../../../../components/Dashboard/Admin/Lists/EditList';
import AddList from '../../../../components/Dashboard/Admin/Lists/AddList';
import { IUserInfoState } from '../../../../store/modules/user/types';

const Lists: React.FC = () => {
  const [tableLoad, setTableLoad] = useState<boolean>(true);
  const [tables, setTables] = useState<any>({});
  const [disableModal, setDisableModal] = useState<any>({
    id: '',
    status: false,
  });

  const [activateModal, setActivateModal] = useState<any>({
    id: '',
    status: false,
  });

  const [editModal, setEditModal] = useState<any>({
    listInfo: {},
    status: false,
  });

  const [addModal, setAddModal] = useState<boolean>(false);

  const dispatch = useDispatch();

  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);

  const handleDisableModal = useCallback(async (id): Promise<void> => {
    await setDisableModal({ id: '', status: false });
    await setDisableModal({ id, status: true });
  }, []);

  const handleActiveModal = useCallback(async (id): Promise<void> => {
    await setActivateModal({ id: '', status: false });
    await setActivateModal({ id, status: true });
  }, []);

  const handleEditModal = useCallback(
    async (id, name, start, end, groups): Promise<void> => {
      const listInfo = {
        id,
        name,
        start,
        end,
        groups,
      };
      await setEditModal({ listInfo: '', status: false });
      await setEditModal({ listInfo, status: true });
    },
    [],
  );

  const handleAddModal = useCallback(async (): Promise<void> => {
    await setAddModal(false);
    await setAddModal(true);
  }, []);

  useEffect(() => {
    async function loadLists(): Promise<void> {
      setTableLoad(true);

      api.get(`/list/lists`).then(response => {
        dispatch(addListInfo(response.data));
        // setListList(response.data);
        setTableLoad(false);
      });
    }

    loadLists();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { list } = useSelector<IState, IListState>(state => state.list);

  useEffect(() => {
    if (list.constructor.name === 'Array') {
      const lista = list
        .filter(function listFilter(obj: any) {
          const parsedDate = zonedTimeToUtc(
            parseISO(obj.end),
            'America/Sao_Paulo',
          );
          const endDate = isAfter(
            parsedDate,
            zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
          );
          if (endDate) {
            return true;
          }
          return false;
        })
        .map(function usersMapping(obj: any) {
          if (!obj.products) {
            obj.products = '-';
          }

          const firstDate = parseISO(obj.start);
          const finalFirstDate = format(
            zonedTimeToUtc(firstDate, 'America/Sao_Paulo'),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );
          const endDate = parseISO(obj.end);
          const finalEndDate = format(
            zonedTimeToUtc(endDate, 'America/Sao_Paulo'),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );

          const beforeDateVerify = isBefore(
            firstDate,
            zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
          );

          let inativo = 'inativo';
          let inativoClass = 'listInactive';

          if (!beforeDateVerify && obj.active === true) {
            inativo = 'aguardando...';
            inativoClass = 'listwaiting';
          }

          const productsTotal = obj.products.reduce(
            (total: any, valor: any) => total + valor.qtd,
            0,
          );

          return [
            obj.id,
            obj.products,
            obj.active,
            obj.name,
            finalFirstDate,
            finalEndDate,
            obj.active && beforeDateVerify ? (
              <span className="listActive">ativo</span>
            ) : (
              <span className={inativoClass}>{inativo}</span>
            ),
            obj.products.length,
            productsTotal,
            obj.groups,
            obj.start,
            obj.end,
            // obj.id,
          ];
        });

      const historico = list
        .filter(function listFilter(obj: any) {
          const parsedDate = zonedTimeToUtc(
            parseISO(obj.end),
            'America/Sao_Paulo',
          );
          const endDate = isBefore(
            parsedDate,
            zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
          );
          if (endDate) {
            return true;
          }
          return false;
        })
        .map(function usersMapping(obj: any) {
          if (!obj.end) {
            obj.end = '-';
          }

          if (!obj.products) {
            obj.products = '-';
          }

          if (!obj.start) {
            obj.start = '-';
          }

          const firstDate = parseISO(obj.start);
          const finalFirstDate = format(
            zonedTimeToUtc(firstDate, 'America/Sao_Paulo'),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );
          const endDate = parseISO(obj.end);
          const finalEndDate = format(
            zonedTimeToUtc(endDate, 'America/Sao_Paulo'),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );

          const productsTotal = obj.products.reduce(
            (total: any, valor: any) => total + valor.qtd,
            0,
          );

          return [
            obj.id,
            obj.products,
            obj.active,
            obj.name,
            finalFirstDate,
            finalEndDate,
            obj.active &&
            isAfter(
              endDate,
              zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
            ) ? (
              <span className="listActive">ativo</span>
            ) : (
              <span className="listInactive">inativo</span>
            ),
            obj.products.length,
            productsTotal,
            obj.groups,
            obj.start,
            obj.end,
            // obj.id,
          ];
        });

      setTables({ lista, historico });
    }
  }, [list]);

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    {
      name: 'image',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    {
      name: 'type',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    { name: 'Lote', options: { sort: true } },
    'Data de início',
    'Data de finalização',
    'status',
    'Produtos',
    'Itens',
    {
      name: 'groups',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    {
      name: 'start',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    {
      name: 'end',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        sort: false,
      },
    },
    {
      name: '',
      options: {
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <div className="table-icons">
                {userData.user.permission >= 3 && !userData.loading && (
                  <>
                    {tableMeta.rowData[2] ? (
                      <ActionButton
                        color="#e90000"
                        data-tip="Desativar"
                        onClick={() => handleDisableModal(tableMeta.rowData[0])}
                      >
                        <FiX />
                      </ActionButton>
                    ) : (
                      <ActionButton
                        color="#00a545"
                        data-tip="Ativar"
                        onClick={() => handleActiveModal(tableMeta.rowData[0])}
                      >
                        <FiCheck />
                      </ActionButton>
                    )}
                    <ActionButton
                      color="#e9b300"
                      data-tip="Editar Lista"
                      onClick={() =>
                        handleEditModal(
                          tableMeta.rowData[0],
                          tableMeta.rowData[3],
                          tableMeta.rowData[10],
                          tableMeta.rowData[11],
                          tableMeta.rowData[9],
                        )
                      }
                    >
                      <FiEdit />
                    </ActionButton>
                  </>
                )}

                <Link
                  to={`/admin/singleList?id=${tableMeta.rowData[0]}`}
                  data-tip="Editar Produtos"
                >
                  <ActionButton color="#e96900">
                    <FiBox />
                  </ActionButton>
                </Link>

                {/* <ActionButton
                color="#666"
                data-tip="Excluir"
                onClick={() => // console.log(tableMeta.rowData)}
              >
                <FiTrash2 />
              </ActionButton> */}

                <ReactTooltip html />
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown' as any,
    // filter: false,
    print: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    customToolbar: () => {
      return (
        <button
          type="button"
          onClick={handleAddModal}
          className="table-button_new"
        >
          <span>
            <FiPlus /> Nova lista
          </span>
        </button>
      );
    },
    textLabels: {
      body: {
        noMatch: 'Desculpe, nenhum resultado encontrado',
        toolTip: 'Ordenar',
      },
      pagination: {
        next: 'Próxima pagina',
        previous: 'Pagina anterior',
        rowsPerPage: 'Resultados por pagina:',
        displayRows: 'de', // 1-10 of 30
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'Filtros',
        all: 'Todos',
        reset: 'Resetar',
      },
      viewColumns: {
        title: 'Mostrar colunas',
      },
      selectedRows: {
        text: 'resultado(s) detelado(s)',
        delete: 'Deletar',
      },
    },
  };

  return (
    <Dashboard>
      <Content>
        {tableLoad ? (
          <TableLoading />
        ) : (
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab> Listas </Tab>
              {userData.user.permission >= 3 && !userData.loading && (
                <Tab> Histórico </Tab>
              )}
            </TabList>

            <TabPanel>
              <MUIDataTable
                title=""
                data={tables.lista}
                columns={columns}
                options={options}
              />
            </TabPanel>
            {userData.user.permission >= 3 && !userData.loading && (
              <TabPanel>
                <MUIDataTable
                  title=""
                  data={tables.historico}
                  columns={columns}
                  options={options}
                />
              </TabPanel>
            )}
          </Tabs>
        )}
      </Content>
      <EditList modalStatus={editModal.status} listInfo={editModal.listInfo} />
      <AddList modalStatus={addModal} />
      <ReproveList modalStatus={disableModal.status} listId={disableModal.id} />
      <ActivateList
        modalStatus={activateModal.status}
        listId={activateModal.id}
      />
    </Dashboard>
  );
};

export default Lists;
