import React, { createContext, useCallback, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import api from '../services/api';
import { removeUserInfo } from '../store/modules/user/actions';

interface User {
  id: string;
  name: string;
  avatar_url: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: User;
  signIn(credantials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Hub:token');
    const user = localStorage.getItem('@Hub:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('session', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@Hub:token', token);
    localStorage.setItem('@Hub:user', JSON.stringify(user));

    // setData({ token, user });

    // const redirectLocation = user.permission === 3 ? '/admin' : '/';

    // history.push(redirectLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Hub:token');
    localStorage.removeItem('@Hub:user');

    dispatch(removeUserInfo());

    setData({} as AuthState);
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
