/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { HtmlHTMLAttributes } from 'react';

import { Container } from './styles';

type DivProps = HtmlHTMLAttributes<HTMLDivElement> & {
  color?: string;
  textColor?: string;
  count?: number;
};

const Badge: React.FC<DivProps> = ({ color, count, textColor, ...rest }) => (
  <Container badgeColor={color} textColor={textColor}>
    <span {...rest}>{count}</span>
  </Container>
);

export default Badge;
