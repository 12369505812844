import axios from 'axios';

const token = localStorage.getItem('@Hub:token');

const tokenHeader = token ? { Authorization: `Bearer ${token}` } : '';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: tokenHeader,
});

export default api;
