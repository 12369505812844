/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

export function changeOrdersReveal() {
  return {
    type: 'CHANGE_ORDERS_REVEAL',
  };
}

export function changeOrdersRevealTwo() {
  return {
    type: 'CHANGE_ORDERS_REVEAL',
  };
}
