import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { lighten } from 'polished';

import containerBackground from '../../assets/sign-in-background.svg';

export default createGlobalStyle`
  body {
    background-color: #11cdef !important;
  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #11cdef;
  background: url('${containerBackground}') no-repeat top center #11cdef;
  padding-top: 120px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Content = styled.div`
  max-width: 475px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  form {
    background-color: #fdfdfd;
    border-radius: 10px;
    padding: 50px 35px;
    width: 100%;
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 10px 0;
      color: ${lighten(0.1, '#455571')};
    }

    a {
      margin-top: 10px;
      margin-left: auto;

      text-decoration: none;
      color: #172b4d;
      font-size: 13px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  > p {
    margin: 20px 0;

    color: rgba(0, 11, 58, 0.7);
    a {
      font-weight: 700;
      color: #000b3a;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
