/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { BsInboxes } from 'react-icons/bs';

import { FiHome, FiBox, FiFileText, FiUser } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import {Box, Tooltip, useBreakpointValue} from '@chakra-ui/react';
import api from '../../../../services/api';
import { IState } from '../../../../store';
import { addUsersInfo } from '../../../../store/modules/users/actions';
import { IUsersInfoState } from '../../../../store/modules/users/types';
import Badge from '../../../Badge';

import MenuButton from './MenuButton';
import { IUserInfoState } from '../../../../store/modules/user/types';

const AdminMenu: React.FC = () => {

   const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);


  // const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
  const dispatch = useDispatch();

  const usersData = useSelector<IState, IUsersInfoState>(
    state => state.usersInfo,
  );

  useEffect(() => {
    api.get(`/users/list-users?permission=100`).then(response => {
      dispatch(addUsersInfo(response.data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const path = window.location.pathname;
  let pageLink = path.split('/')[2];

  if (pageLink === '' || pageLink === undefined) {
    pageLink = 'home';
  }

  return (
    <>
      <MenuButton id="Home" link="/admin" color="#7764E4" current={pageLink}>
        <FiHome /> <span>Home</span>
      </MenuButton>
      {(userData.user.permission >= 3 && !userData.loading) &&
      <MenuButton
        id="users"
        text="Usuários"
        link="/admin/users"
        color="#efdc11"
        current={pageLink}
      >
        <FiUser />{' '}
        <span>
          Usuários
          {usersData.pendingUsers > 0 ? (
            <Badge
              count={usersData.pendingUsers}
              textColor="#fff"
              className="softcap-generic-badge softcap-badge radioactive-badge"
            />
          ) : (
            ''
          )}
        </span>
      </MenuButton>
      }
      <MenuButton
        id="products"
        text="Produtos"
        link="/admin/products"
        color="#11CDEF"
        current={pageLink}
      >
            <FiBox />
           <span>Produtos</span>
      </MenuButton>
      <MenuButton
        id="lists"
        text="Listas"
        link="/admin/lists"
        color="#FF1A72"
        current={pageLink}
      >
        <BsInboxes /> <span>Listas</span>
      </MenuButton>

      <MenuButton
        id="orders"
        text="Pedidos"
        link="/admin/orders"
        color="#FB6340"
        current={pageLink}
      >
        <FiFileText /> <span>Pedidos</span>
      </MenuButton>

      {/* <MenuButton
        id="wishlist"
        link="/dashboard/wishlist"
        color="#5878FF"
        current={pageLink}
      >
        <FiBell /> <span>Notificações</span>
      </MenuButton>

      <MenuButton
        id="wishlist"
        link="/dashboard/wishlist"
        color="#45A149"
        current={pageLink}
      >
        <HiOutlineCurrencyDollar /> <span>Financeiro</span>
      </MenuButton>

      <MenuButton
        id="wishlist"
        link="/dashboard/wishlist"
        color="#FF9D00"
        current={pageLink}
      >
        <FiStar /> <span>Novidades</span>
      </MenuButton> */}
    </>
  );
};

export default AdminMenu;
