/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { format, startOfHour } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { zonedTimeToUtc } from 'date-fns-tz';

// import ReactTooltip from 'react-tooltip';

import { FiAlertCircle, FiCheck } from 'react-icons/fi';
import { formatPrice } from '../../../../utils/format';

import { Content } from './styles';

import 'react-tabs/style/react-tabs.css';

import Dashboard from '../../../../components/Dashboard';
import { IState } from '../../../../store';
import TableLoading from '../../../../components/Table/Loading';
import api from '../../../../services/api';
import { item } from '../../../../store/modules/cart/types';
import { IUserInfoState } from '../../../../store/modules/user/types';
import { Text,
          Alert,
          AlertIcon,
          Icon,
        } from '@chakra-ui/react';

const AdminOrderDetail: React.FC = () => {
  const [tableLoad, setTableLoad] = useState<boolean>(true);

  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>(true);
  const [
    descontoPromocionalProdutos,
    setDescontoPromocionalProdutos,
  ] = useState<any>(true);
  const [descontoClienteProdutos, setDescontoClienteProdutos] = useState<any>(
    true,
  );
  const [orderInfo, setOrderInfo] = useState<any>({});
  const [companyInfo, setCompanyInfo] = useState<any>({});
  const [newOrder, setNewOrder] = useState<boolean>(false);
  const [orderDate, setOrderDate] = useState<any>({});
  const [valorTotalVivos, setValorTotalVivos] = useState<any>(true);
  const [descontoPromocionalVivos, setDescontoPromocionalVivos] = useState<any>(
    true,
  );
  const [descontoNotDeliveredVivos, setDescontoNotDeliveredVivos] = useState<any>(
    0
  );
  const [valorFinalProdutos, setValorFinalProdutos] = useState<any>(true);
  // const [totalDicsValue, setTotalDicsValue] = useState<any>(true);
  const [descontoClienteVivos, setDescontoClienteVivos] = useState<any>(true);
  const [valorFinalVivos, setValorFinalVivos] = useState<any>(true);

  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('id');
    const isNew = Boolean(urlParams.get('new'));
    setNewOrder(isNew);
    setTableLoad(true);

    api.get(`/order/single?order_id=${orderId}`).then(response => {
      setOrderInfo(response.data);
      setTableLoad(false);

      api
        .get(`company/find`, {
          params: {
            company_id: response.data.user_info.company_id,
          },
        })
        .then(companyResponse => {
          setCompanyInfo(companyResponse.data);
        });
    });

    if (orderInfo.updated_at) {
      const updatedAtBr = zonedTimeToUtc(
        orderInfo.updated_at,
        'America/Sao_Paulo',
      );

      const updatedDate = startOfHour(updatedAtBr);

      const updatedFormatted = format(updatedDate, 'dd/MM/yyyy - HH:mm', {
        locale: ptBR,
      });

      const createdAtBr = zonedTimeToUtc(
        orderInfo.updated_at,
        'America/Sao_Paulo',
      );

      const createdDate = startOfHour(createdAtBr);

      const createdFormatted = format(createdDate, 'dd/MM/yyyy - HH:mm', {
        locale: ptBR,
      });

      setOrderDate({
        updated_at: updatedFormatted,
        created_at: createdFormatted,
      });
      // const hourFormatted = format(updatedDate, 'HH:mm');

      // const dateFormatted = format(updatedDate, 'dd/MM/yyyy');
    }

    setTableLoad(false);
  }, [orderInfo.updated_at]);

  // const activeUsersList = usersList.filter();

  const cartItem = useSelector<IState, item[]>(state => state.cart.items);

  useEffect(() => {
    if (orderInfo.products) {
      const vivosInfo = orderInfo.products
        .filter((isVivos: any) => {
          if (isVivos.list_product?.product_info?.type < 4) {
            return true;
          }

          return false;
        })
        .map((vivos: any) => {
          return {
            name: vivos.list_product?.name,
            value: vivos.product_price,
            discount_value: vivos.discount_price,
            quantidade: vivos.qtd,
            qtd_delivered: vivos.qtd_delivered
          };
        });

      setValorTotalVivos(
        vivosInfo.reduce(
          (total: any, valor: any) =>
            total + Number(valor.value) * (valor.quantidade),
          0,
        ),
      );


      setDescontoNotDeliveredVivos(
        vivosInfo.reduce(
          (total: any, valor: any) =>
            {
              // console.log(total)
              // console.log(valor.qtd_delivered)
              // console.log(valor.quantidade)
              return Number(total + ((valor.discount_value > 0 ? valor.discount_value : valor.value) * ( valor.qtd_delivered !== null ? (valor.quantidade - valor.qtd_delivered) : 0)
              ))
            },
          0

        )
      )

      setDescontoPromocionalVivos(
        vivosInfo.reduce(
          (total: any, valor: any) =>
            total +
            (valor.discount_value
              ? Number(valor.value) - Number(valor.discount_value)
              : 0) *
              (valor.quantidade),
          0,
        ),
      );

      setDescontoClienteVivos(
        vivosInfo.reduce(
          (total: any, valor: any) =>
            total +
            (valor.discount_value
              ? Number(valor.discount_value) *
                (Number(userData.user.company?.desconto || 0) / 100)
              : Number(valor.value) *
                (Number(userData.user.company?.desconto || 0) / 100)) *
                (valor.qtd_delivered !== null ? valor.qtd_delivered : valor.quantidade),
          0,
        ),
      );

      setValorFinalVivos(
        vivosInfo.reduce(
          (total: any, valor: any) =>
            total +
            (Number(valor.discount_value || valor.value) -
              Number(valor.discount_value || valor.value) *
                (Number(userData.user.company?.desconto || 0) / 100)) *
              (valor.qtd_delivered !== null ? valor.qtd_delivered : valor.quantidade),
          0,
        ),
      );

      const produtosInfo = orderInfo.products
        .filter((isprodutos: any) => {
          if (isprodutos.list_product?.product_info?.type === 4) {
            return true;
          }

          return false;
        })
        .map((produtos: any) => {
          return {
            name: produtos.list_product?.name,
            value: produtos.product_price,
            discount_value: produtos.discount_price,
            quantidade: produtos.qtd,
            qtd_delivered: produtos.qtd_delivered,
          };
        });

      setValorTotalProdutos(
        produtosInfo.reduce(
          (total: any, valor: any) =>
            total + Number(valor.value) * (valor.qtd_delivered !== null ? valor.qtd_delivered : valor.quantidade),
          0,
        ),
      );

      setDescontoPromocionalProdutos(
        produtosInfo.reduce(
          (total: any, valor: any) =>
            total +
            (valor.discount_value
              ? Number(valor.value) - Number(valor.discount_value)
              : 0) *
              (valor.qtd_delivered !== null ? valor.qtd_delivered : valor.quantidade),
          0,
        ),
      );

      setDescontoClienteProdutos(
        produtosInfo.reduce(
          (total: any, valor: any) =>
            total +
            (valor.discount_value
              ? Number(valor.discount_value) *
                (Number(userData.user.company?.desconto || 0) / 100)
              : Number(valor.value) *
                (Number(userData.user.company?.desconto || 0) / 100)) *
              (valor.qtd_delivered !== null ? valor.qtd_delivered : valor.quantidade),
          0,
        ),
      );

      setValorFinalProdutos(
        produtosInfo.reduce(
          (total: any, valor: any) =>
            total +
            (Number(valor.discount_value || valor.value) -
              Number(valor.discount_value || valor.value) *
                (Number(userData.user.company?.desconto || 0) / 100)) *
              (valor.qtd_delivered !== null ? valor.qtd_delivered : valor.quantidade),
          0,
        ),
      );
    }

    const products: any = { products: [] };
    cartItem.map(itemInfo => {
      return products.products.push({
        product_id: itemInfo.product.id,
        qtd: itemInfo.quantidade,
      });
    });

    // setTotalDicsValue(
    //   cartItem
    //     .map(items => {
    //       return items;
    //     })
    //     .reduce(
    //       (total: any, itemInfo: any) =>
    //         total +
    //         (itemInfo.product.discount_value
    //           ? (Number(itemInfo.product.value) -
    //               Number(itemInfo.product.discount_value)) *
    //             itemInfo.quantidade
    //           : 0),
    //       0,
    //     ),
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem, orderInfo, userData.user.company?.desconto]);

  // const orderIdFull = orderInfo.id ? orderInfo.id : '';
  // const pos = orderIdFull.indexOf('-');
  // // const orderId = orderIdFull.substring(0, pos);

  const originalOrderNumber = orderInfo.order_number;
  const rest = 5 - String(originalOrderNumber).length;
  const orderNumber = '0'.repeat(rest > 0 ? rest : 0) + originalOrderNumber;
  // const initialFocusRef = React.useRef()
  return (
    <Dashboard>
      <Content>
        {tableLoad ? (
          <TableLoading />
        ) : (
          <div className="cart_content">
            {newOrder && (
              <span className="new-order-alert">
                <FiCheck /> Novo Pedido feito com sucesso!
              </span>
            )}
            <h1>Detalhes do pedido #{orderNumber}</h1>
            <div className="orderDetail_conatiner">
              <div className="cart_doubleDivider7030">
                <div>
                  <h3>Solicitante</h3>
                  <div className="cart_tableBox_orderDetail cart_tableBox-padding cart_width-auto">
                    <ul>
                      <li>
                        <span>Nome:</span>{' '}
                        <span>{companyInfo.name}</span>
                      </li>
                      <li>
                        <span>Endereço:</span>{' '}
                        <span>{companyInfo.endereco}</span>
                      </li>
                      <li>
                        <span>Bairro:</span>{' '}
                        <span> {companyInfo.bairro ?? '-'} </span>
                      </li>
                      <li>
                        <span>Cidade/Estado:</span>{' '}
                        <span>
                          {companyInfo.cidade} / {companyInfo.UF}
                        </span>
                      </li>
                      <li>
                        <span>Telefone:</span> <span> - </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div>
                  <h3>Dados do Pedido</h3>
                  <div className="cart_tableBox_orderDetail cart_tableBox-padding cart_width-auto">
                    <ul>
                      <li>
                        <span>Status:</span>{' '}
                        <span>{orderInfo.order_status_info?.name}</span>
                      </li>
                      <li>
                        <span>Data do Pedido:</span>{' '}
                        <span>{orderDate.created_at}</span>
                      </li>
                      <li>
                        <span>Ultima atualização:</span>{' '}
                        <span>{orderDate.updated_at}</span>
                      </li>
                        <li>
                          <span>Transportadora:</span>{' '}
                          <Text as="span" >{orderInfo.transportadora_info?.name}</Text>
                        </li>

                      <li>
                            <span>Código de rastreio:</span>{' '}
                            <span>
                              {orderInfo.rastreio ? orderInfo.rastreio : '-'}
                            </span>
                          </li>
                    </ul>
                  </div>
                </div>
              </div>

              {orderInfo?.products?.filter( (product: any) => {return product.qtd !== null && product.qtd !== product.qtd_delivered && product.qtd_delivered !== null} ).length > 0 && (
                <Alert variant="left-accent" status="warning" mt={5}>
                <AlertIcon />
                Alguns dos seus itens sofreram alteração na quantidade. Nós marcamos eles abaixo para você e reajustamos o valor do seu pedido.
              </Alert>
              )}

              <h3 className="cart_titleMargin">Detalhamento</h3>
              <div className="cart_tableBox">
                <table className="cart_table">
                  <thead>
                    <tr>
                      <th className="cart_namemargin">Nome Popular</th>
                      <th>Tamanho</th>
                      <th>Valor Uni.</th>
                      <th>Qtd.</th>
                      <th>Parcial</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderInfo.products &&
                      orderInfo.products.map((product: any) => {

                        const imageUrl =
                          product.list_product?.product_info?.image_url;
                        return (
                          <tr key={product.id} style={{textDecoration: `${product?.qtd_delivered === 0 ? "line-through" : "initial"}`, color: `${product?.qtd_delivered === 0 ? "#ccc" : "#000"}`, backgroundColor: `${product?.qtd_delivered !== null && product?.qtd_delivered !== product?.qtd ? `${product.qtd_delivered === 0 ? "rgba(255, 0, 0, 0.08)" : "rgba(255, 236, 200, 0.58)"}` : ""}`}}>
                            <td>
                              {imageUrl ? (
                                <img
                                  className="cart_imageMargin"
                                  src={imageUrl}
                                  alt={product.name}
                                />
                              ) : (
                                <span className="cart_nameSpace"> </span>
                              )}
                              {product.qtd_delivered !== null && product.qtd !== product.qtd_delivered ? (<><Icon as={FiAlertCircle} mr={2} ml={-5} color={product.qtd_delivered === 0 ? "red.500" : "red.500"} />{product.list_product?.name}</>) : product.list_product?.name}
                            </td>
                            <td>{product.list_product?.product_info?.size}</td>
                            <td>
                              {product.discount_price ? (
                                <>
                                  <del>
                                    {formatPrice(Number(product.product_price))}
                                  </del>{' '}
                                  <span className="promo">
                                    {formatPrice(
                                      Number(product.discount_price),
                                    )}
                                  </span>
                                </>
                              ) : (
                                <span>
                                  {formatPrice(Number(product.product_price))}
                                </span>
                              )}
                            </td>
                            <td>{product.qtd_delivered !== null ? (
                              <>{product.qtd_delivered !== product.qtd ? (
                                <>
                                  <del><Text as="span" color="gray.400">{product.qtd}</Text></del>
                                  <Text ml={3} as="span" color="red.500">{product.qtd_delivered}</Text>
                                </>
                              ) : product.qtd}</>
                            ) : product.qtd}</td>
                            <td>
                              <span className="noPromo">
                                {formatPrice(
                                  product.discount_price
                                    ? Number(product.discount_price) *
                                     (product.qtd_delivered !== null ? product.qtd_delivered  : product.qtd)
                                    : Number(product.product_price) *
                                    (product.qtd_delivered !== null ? product.qtd_delivered  : product.qtd),
                                )}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="cart_doubleDivider">
                <div>
                  <h3 className="cart_titleMargin">Resumo de Vivos</h3>
                  <div className="cart_tableBox cart_tableBox-padding cart_width-auto">
                    {valorTotalVivos === 0 ? (
                      <>Não existem Vivos no carrinho.</>
                    ) : (
                      <ul>
                        <li>
                          <span>Valor dos vivos</span>{' '}
                          <span>{formatPrice(valorTotalVivos)}</span>
                        </li>
                        <li className="cart_listPromo">
                          <span>Desconto promocional</span>{' '}
                          <span>- {formatPrice(descontoPromocionalVivos)}</span>
                        </li>
                          <li className="cart_listPromo">
                          <span>
                            Desconto do cliente (
                            {userData.user.company?.desconto}
                            %)

                          </span>{' '}
                          <span>- {formatPrice(descontoClienteVivos)}</span>
                        </li>
                        {descontoNotDeliveredVivos > 0 && (
                        <li className="cart_listPromo">
                          <span>
                            Não enviado
                          </span>{' '}
                          <span style={{color: "#f00"}}>- {formatPrice(descontoNotDeliveredVivos)}</span>
                        </li>
                        )}
                        <li>
                          <span>Valor a ser pago</span>{' '}
                          <span>{formatPrice(valorFinalVivos)}</span>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>

                <div>
                  <h3 className="cart_titleMargin">
                    Resumo de Produtos (não vivos)
                  </h3>
                  <div className="cart_tableBox cart_tableBox-padding cart_width-auto">
                    {valorTotalProdutos === 0 ? (
                      <>Não existem produtos (não vivos) no carrinho.</>
                    ) : (
                      <ul>
                        <li>
                          <span>Valor dos Produtos</span>{' '}
                          <span>{formatPrice(valorTotalProdutos)}</span>
                        </li>
                        <li className="cart_listPromo">
                          <span>Desconto promocional</span>{' '}
                          <span>
                            - {formatPrice(descontoPromocionalProdutos)}
                          </span>
                        </li>
                        <li className="cart_listPromo">
                          <span>
                            Desconto do cliente (
                            {userData.user.company?.desconto}%)
                          </span>{' '}
                          <span>- {formatPrice(descontoClienteProdutos)}</span>
                        </li>
                        <li>
                          <span>Valor a ser pago</span>{' '}
                          <span>{formatPrice(valorFinalProdutos)}</span>
                        </li>
                      </ul>
                    )}
                  </div>

                  <div className="cart_width100">
                    <h3 className="cart_titleMargin">Resumo Final</h3>
                    <div className="cart_tableBox cart_tableBox-padding cart_width-auto cart_doubleContent">
                      <span className="cart_totalLabel">
                        <p>TOTAL:</p>
                      </span>
                      <span style={{width: "80%"}}>
                        <p className="NewOrderCart_total">
                          {formatPrice(valorFinalProdutos + valorFinalVivos)}
                        </p>
                        <p className="NewOrderCart_discount">
                          Economia de:{' '}
                          {formatPrice(
                            descontoPromocionalProdutos +
                              descontoClienteProdutos +
                              descontoPromocionalVivos +
                              descontoClienteVivos,
                          )}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cart_doubleDivider">
                <div />
              </div>
            </div>
          </div>
        )}
      </Content>
      {/* <ReactTooltip html /> */}
    </Dashboard>
  );
};

export default AdminOrderDetail;
