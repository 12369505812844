/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';

import { ICompanysState } from './types';

const INITIAL_STATE: ICompanysState = {
  companys: null,
  loading: true,
  addCompany: true,
};

const CompanysInfo: Reducer<ICompanysState> = (
  state = INITIAL_STATE,
  action,
) => {
  // eslint-disable-next-line consistent-return
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_COMPANYS_INFO': {
        const { apiCompanysInfo } = action.payload;

        draft = {
          loading: false,
          companys: apiCompanysInfo,
          addCompany: state.addCompany,
        };

        return draft;
      }
      case 'REMOVE_COMPANYS_INFO': {
        draft = {
          companys: null,
          loading: false,
          addCompany: state.addCompany,
        };

        return draft;
      }

      case 'OPEN_ADD_COMPANY': {
        draft.addCompany = false;
        break;
      }

      case 'CLOSE_ADD_COMPANY': {
        draft.addCompany = true;
        break;
      }

      default: {
        // return INITIAL_STATE;
        break;
      }
    }
  });
};

export default CompanysInfo;
