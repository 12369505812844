/* eslint-disable no-nested-ternary */
import React from 'react';
import { ImSpinner2 } from 'react-icons/im';

import { Container } from './styles';

const TableLoading: React.FC = ({ ...rest }) => (
  <Container {...rest}>
    <div>
      <ImSpinner2 />
    </div>
  </Container>
);

export default TableLoading;
