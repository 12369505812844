/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { keyframes, css } from 'styled-components';

// interface ContainerProps {
//  //nada
// }

export const LoadIcon = styled.div`
  svg {
    display: inline-block;
    position: inline-block;
  }
`;

export const Container = styled.div``;
