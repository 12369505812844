import React from 'react';

import { Content } from './styles';

import Dashboard from '../../../components/Dashboard';
import OrderProgress from '../../../components/Dashboard/OrderProgress';
import News from '../../../components/Dashboard/News';

const DefaultUserDashboard: React.FC = () => {
  return (
    <Dashboard>
      <OrderProgress />
      <Content>
        <News />
      </Content>
    </Dashboard>
  );
};

export default DefaultUserDashboard;
