/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';

import { FiMinus, FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';

import { item, product } from '../../store/modules/cart/types';
import {
  addProductToCartRequest,
  decreaseProductToCartRequest,
} from '../../store/modules/cart/actions';
import { IState } from '../../store';

interface productsProps {
  productInformation: product;
}

const ProductCartOptions: React.FC<productsProps> = ({
  // children,
  productInformation,
  // ...rest
}) => {
  const dispatch = useDispatch();
  const handleAddCartItem = useCallback(
    productInfo => {
      dispatch(addProductToCartRequest(productInfo));
    },
    [dispatch],
  );

  const handleRemoveCartItem = useCallback(
    productInfo => {
      dispatch(decreaseProductToCartRequest(productInfo));
    },
    [dispatch],
  );

  const stockInfo = useSelector<IState, item>(state => {
    return state.cart.items
      .filter(items => {
        return items.product.id.includes(productInformation.id);
      })
      .map(items => {
        return items;
      })[0];
  });

  return (
    <Container>
      <div className="newOrder_buttons">
        <button
          onClick={() => {
            stockInfo?.quantidade >= 1 &&
              handleRemoveCartItem(productInformation);
          }}
          className="newOrder_button_remove"
          type="button"
        >
          <FiMinus size={12} />
        </button>
        <input type="number" readOnly value={stockInfo?.quantidade || 0} />
        <button
          type="button"
          onClick={() => handleAddCartItem(productInformation)}
          className="newOrder_button_add"
        >
          <FiPlus size={12} />
        </button>
      </div>
    </Container>
  );
};

export default ProductCartOptions;
