/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, SelectHTMLAttributes } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

// eslint-disable-next-line import/no-unresolved
import { Container, Error } from './styles';

interface InputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  mask?: 'cep' | 'phone' | 'name';
  required?: boolean;
  invisible?: boolean;
}

const Select: React.FC<InputProps> = ({
  required,
  name,
  invisible,
  children,
  icon: Icon,
  ...rest
}) => {
  const inputRef = useRef<HTMLSelectElement>(null);
  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      isErrored={!!error}
      {...(invisible ? "className='InVisible'" : '')}
    >
      {Icon && <Icon size={20} />}
      <select name={name} ref={inputRef} {...rest}>
        {children}
      </select>

      {required ? <span>*</span> : ''}

      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};

export default Select;
