import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import MailSend from '../../../assets/illustrations/email.png';

import logoHub from '../../../assets/logo-hub.svg';

import GlobalStyle, { Container, Content } from './styles';

const RegisterSuccess: React.FC = () => {
  localStorage.removeItem('@Hub:user');
  localStorage.removeItem('@Hub:token');

  return (
    <>
      <Container>
        <Content>
          <img src={logoHub} alt="Hub" />

          <div className="loginBox-alert">
            <h3>Confirmação de e-mail pendente...</h3>
            <p>
              O seu cadastro foi feito com sucesso, mas precisamos que você
              confirme o seu e-mail. Para isso, acesse a sua caixa de e-mail e
              siga as instruções.
            </p>
            <img src={MailSend} alt="wait a contact" />
          </div>

          <p>
            <Link to="/">
              <FiArrowLeft /> <span>VOLTAR</span>
            </Link>
          </p>
        </Content>
      </Container>
      <GlobalStyle />
    </>
  );
};

export default RegisterSuccess;
