import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.header`
  background-color: #000b3a;
  color: #fff;
  padding: 20px;
  width: 100%;
  display: flex;
  align-content: center;
`;

export const HeaderLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;

  svg {
    margin-right: 2px;
  }

  a {
    margin: 0 10px;
    color: #fff;
    font-size: 12px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const HeaderRight = styled.div`
  /* min-width: 30%; */
  align-content: center right;
  text-align: right;

  ul {
    display: flex;
    align-content: center;
    list-style: none;

    li {
      margin-left: 25px;
      display: flex !important;
      align-content: center !important;
      align-items: center;
      white-space: nowrap;
      line-height: 50px;
      cursor: pointer;

      a {
        color: #ffffff;
        display: flex !important;
        align-content: center !important;
        align-items: center;
        white-space: nowrap;
        line-height: 50px;
      }

      p {
      }

      svg {
        font-size: 20px;
        display: inline-flex;
        align-self: center;
      }

      div:first-child span span {
        margin-right: 10px;
      }

      div {
        align-self: center;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      img {
        margin-right: 10px;
        width: 50px;
        height: 50px;
        object-fit: cover;
        border: double 2px transparent;
        border-radius: 50%;
        background-image: linear-gradient(#ffffff00, #ffffff00),
          linear-gradient(to right, #19cdf5, #8742a2);
        background-origin: border-box;
        background-clip: content-box, border-box;
      }
    }

    li:last-child {
      color: #00caf4;
      margin-right: 20px;
    }
  }
`;
