/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  AllHTMLAttributes,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { FiBox, FiLayers, FiCodepen } from 'react-icons/fi';
import { CgRename } from 'react-icons/cg';
import Switch from 'react-switch';
import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { Container, Divisor } from './styles';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import SimpleButton from '../../../../SimpleButton';
import Modal from '../../../../Modal';
import Input from '../../../../Input';
import Select from '../../../../Select';

import { IState } from '../../../../../store';
import { IUserInfoState } from '../../../../../store/modules/user/types';

type IModal = AllHTMLAttributes<HTMLAllCollection> & {
  modalStatus: boolean;
};

const AddProduct: React.FC<IModal> = ({ modalStatus }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(true);
  const [isExclusiveHubProduct, setIsExclusiveHubProduct] = useState(false);

  // const startFormatedTime = format(new Date(), 'HH:mm:ss');

  // const endDate = parseISO(listInfo.end);

  useEffect(() => {
    const modalChange = async (): Promise<void> => {
      if (modalStatus) {
        await setModal(true);
        await setModal(false);
      }
    };

    modalChange();
  }, [modalStatus]);

  const handleFormSubmit = useCallback(
    async (data): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Você precisa informar um nome'),
          // family: Yup.string().required('Você precisa informar uma familia'),
          // scientific_name: Yup.string().required(
          //   'Você precisa informar um nome cientifico',
          // ),
          default_price: Yup.string().required(
            'Você precisa informar um valor padrão',
          ),
          type: Yup.number()
            .typeError('Selecione um tipo')
            .required('Selecione um tipo'),
          size: Yup.string()
            .typeError('Selecione um tamanho')
            .required('Selecione um tamanho'),
        });

        let final_price = data.default_price.replace(/\./g, '');
        final_price = final_price.replace(/,/g, '.');

        await schema.validate(data, {
          abortEarly: false,
        });

        const family = data.family || null;
        const scientific_name = data.scientific_name || null;
        const size = data.size === 'Sem Tamanho' ? null : data.size;
        console.log('exclusive?');
        console.log(isExclusiveHubProduct);
        api.post('/product', {
          name: data.name,
          type: data.type,
          exclusive: isExclusiveHubProduct,
          scientific_name,
          size,
          family,
          default_price: Number(final_price),
        });

        addToast({
          type: 'success',
          title: 'Produto adicionado',
        });

        setModal(true);

        window.location.reload();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let description;

          if (errors.name) {
            description = errors.name;
          }

          if (errors.type) {
            description = errors.type;
          }

          if (errors.scientific_name) {
            description = errors.scientific_name;
          }

          if (errors.family) {
            description = errors.family;
          }

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, isExclusiveHubProduct],
  );

  return (
    <Modal title="Novo Produto" modalSize="50%" modalClose={modal} closable>
      <Container>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <Divisor>
            <div className="double">
              <h5>Nome:</h5>
              <Input
                className="singleList-productSearch"
                name="name"
                icon={FiBox}
                placeholder="nome"
              />
            </div>
            <div className="double">
              <h5>tipo:</h5>
              <Select name="type" icon={FiCodepen}>
                <option value="">Escolha um tipo</option>
                <option value={1}>Peixe</option>
                <option value={3}>Coral</option>
                <option value={2}>Invertebrado</option>
                {userData.user.permission === 3 && (
                  <option value={4}>Produto</option>
                )}
              </Select>
            </div>
          </Divisor>

          <Divisor style={{ marginTop: 20 }}>
            <div className="double">
              <h5>Nome Cientifico:</h5>
              <Input
                className="singleList-productSearch"
                name="scientific_name"
                icon={CgRename}
                placeholder="Nome cientifico"
              />
            </div>
            <div className="double">
              <h5>Familia:</h5>
              <Input
                className="singleList-productSearch"
                name="family"
                icon={CgRename}
                placeholder="Familia"
              />
            </div>
          </Divisor>

          <Divisor style={{ marginTop: 20 }}>
            <div className="double">
              <h5>Tamanho:</h5>
              <Select name="size" icon={FiLayers}>
                <option value="">Escolha um tamanho</option>
                <option>Sem Tamanho</option>
                <option value="TINY">TINY</option>
                <option value="SM">SM</option>
                <option value="SM/SMD">SM/SMD</option>
                <option value="SMD">SMD</option>
                <option value="SMD/MD">SMD/MD</option>
                <option value="MD">MD</option>
                <option value="MD/MDLG">MD/MDLG</option>
                <option value="MDLG">MDLG</option>
                <option value="MDLG/LG">MDLG/LG</option>
                <option value="LG">LG</option>
                <option value="LG/XLG">LG/XLG</option>
                <option value="XLG">XLG</option>
              </Select>
            </div>
            <div className="double">
              <h5>Valor padrão:</h5>
              <Input
                className="singleList-productSearch"
                name="default_price"
                mask="money"
                placeholder="Valor padrão"
              />
            </div>
          </Divisor>
          <Divisor
            style={{
              marginTop: 20,
              display: userData.user.permission === 3 ? 'block' : 'none',
            }}
          >
            <Stack>
              <h5
                onClick={() => setIsExclusiveHubProduct(!isExclusiveHubProduct)}
              >
                Esse produto é exclusivo hub?
              </h5>
              <Box>
                <Switch
                  onColor="#52c75d"
                  onChange={() =>
                    // eslint-disable-next-line prettier/prettier
                    setIsExclusiveHubProduct(!isExclusiveHubProduct)}
                  checked={isExclusiveHubProduct}
                />
              </Box>
            </Stack>
          </Divisor>

          <Divisor style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SimpleButton loading={loading} type="submit">
              Adicionar
            </SimpleButton>
          </Divisor>
        </Form>
      </Container>
    </Modal>
  );
};

export default AddProduct;
