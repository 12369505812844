/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { FiCheck, FiEdit, FiX } from 'react-icons/fi';
import ActionButton from '../../../../components/Table/ActionButton';
import Badge from '../../../../components/Badge';

import { Content } from './styles';

import 'react-tabs/style/react-tabs.css';

import Dashboard from '../../../../components/Dashboard';
import { IState } from '../../../../store';
import { IUsersInfoState } from '../../../../store/modules/users/types';
import TableLoading from '../../../../components/Table/Loading';
import Modal from '../../../../components/Modal';
import AproveUser from '../../../../components/Dashboard/Admin/Users/AproveUser';
import EditUser from '../../../../components/Dashboard/Admin/Users/EditUser';
import {
  closeAproveUsers,
  closeEditUsers,
  closeReproveUsers,
  editUser,
  openAproveUsers,
  openEditUsers,
  openReproveUsers,
} from '../../../../store/modules/users/actions';
import ReproveUser from '../../../../components/Dashboard/Admin/Users/ReproveUser';
import { closeAddCompany, openAddCompany, addCompanysInfo  } from '../../../../store/modules/companys/actions';
import { Flex } from '@chakra-ui/react';
import { ICompanysState } from '../../../../store/modules/companys/types';
import AddCompany from '../../../../components/Dashboard/Admin/Company/AddCompany';


const DefaultUserDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const usersData = useSelector<IState, IUsersInfoState>(
    state => state.usersInfo,
  );
  const [tableLoad, setTableLoad] = useState<boolean>(true);
  const [addCompany, setAddCompany] = useState(true);


  const companyData = useSelector<IState, ICompanysState>(
    state => state.companysInfo,
  );

  useEffect(() => {
    setAddCompany(companyData.addCompany);
  }, [companyData]);

  useEffect(() => {
    !usersData.users ? setTableLoad(true) : setTableLoad(false);
  }, [usersData.users]);

  const openModal = (userInfo: any): void => {
    const handleModalClose = async (): Promise<void> => {
      const user = {
        id: userInfo[0],
        active: userInfo[1],
        name: userInfo[2],
        email: userInfo[3],
        company_name: userInfo[4],
        mobile_phone: userInfo[5],
        whatsapp: userInfo[6],
        phone: userInfo[7],
        company_id: userInfo[9],
      };
      await dispatch(closeAddCompany());
      await dispatch(editUser(user));
      await dispatch(closeEditUsers());
      await dispatch(openEditUsers());
    };

    handleModalClose();
  };

  const openModal2 = (userInfo: any): void => {
    const handleModalClose = async (): Promise<void> => {
      const user = {
        id: userInfo[0],
        active: userInfo[1],
        name: userInfo[2],
        email: userInfo[3],
        company_name: userInfo[4],
        mobile_phone: userInfo[5],
        whatsapp: userInfo[6],
        phone: userInfo[7],
        company_id: userInfo[9],
      };
      await dispatch(closeAddCompany());
      await dispatch(editUser(user));
      await dispatch(closeAproveUsers());
      await dispatch(openAproveUsers());
    };

    handleModalClose();
  };

  const openModalReprove = (userInfo: any): void => {
    const handleModalClose = async (): Promise<void> => {
      const user = {
        id: userInfo[0],
        active: userInfo[1],
        name: userInfo[2],
        email: userInfo[3],
        company_name: userInfo[4],
        mobile_phone: userInfo[5],
        whatsapp: userInfo[6],
        phone: userInfo[7],
        company_id: userInfo[9],
      };
      await dispatch(editUser(user));
      await dispatch(closeReproveUsers());
      await dispatch(openReproveUsers());
    };

    handleModalClose();
  };

  // const activeUsersList = usersList.filter();

  let usuarios: any[] | any[] | string[] = [];
  let colaboradores: any[] | any[] | string[] = [];
  let pendentes: any[] | any[] | string[] = [];
  let reprovados: any[] | any[] | string[] = [];

  if (usersData.users) {
    usuarios = usersData.users
      .filter(function usersFilter(obj: any) {
        if (
          obj.active === true &&
          obj.reproved === false &&
          obj.permission === 1
        ) {
          return true;
        }
        return false;
      })
      .map(function usersMapping(obj: any) {
        let companyName = '-';

        if (obj.company_id) {
          companyName = obj.company.name;
        } else if (obj.company_name) {
          companyName = obj.company_name;
        }

        return [
          obj.id,
          obj.active,
          obj.name,
          obj.email,
          companyName,
          !obj.mobile_phone ? '-' : obj.mobile_phone,
          obj.whatsapp,
          obj.phone,
          obj.reproved,
          obj.company_id,
        ];
      });

    colaboradores = usersData.users
      .filter(function usersFilter(obj: any) {
        if (
          obj.active === true &&
          obj.reproved === false &&
          obj.permission === 3
        ) {
          return true;
        }
        return false;
      })
      .map(function usersMapping(obj: any) {
        return [
          obj.id,
          obj.active,
          obj.name,
          obj.email,
          !obj.company_name ? '-' : obj.company_name,
          !obj.mobile_phone ? '-' : obj.mobile_phone,
          obj.whatsapp,
          obj.phone,
          obj.reproved,
          obj.company_id,
        ];
      });

    pendentes = usersData.users
      .filter(function usersFilter(obj: any) {
        if (obj.active === false && obj.reproved === false) {
          return true;
        }
        return false;
      })
      .map(function usersMapping(obj: any) {
        return [
          obj.id,
          obj.active,
          obj.name,
          obj.email,
          !obj.company_name ? '-' : obj.company_name,
          !obj.mobile_phone ? '-' : obj.mobile_phone,
          obj.whatsapp,
          obj.phone,
          obj.reproved,
          obj.company_id,
        ];
      });

    reprovados = usersData.users
      .filter(function usersFilter(obj: any) {
        if (obj.active === false && obj.reproved === true) {
          return true;
        }
        return false;
      })
      .map(function usersMapping(obj: any) {
        return [
          obj.id,
          obj.active,
          obj.name,
          obj.email,
          !obj.company_name ? '-' : obj.company_name,
          !obj.mobile_phone ? '-' : obj.mobile_phone,
          obj.whatsapp,
          obj.phone,
          obj.reproved,
          // // console.log(obj.reproved),
        ];
      });
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'active',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    'Nome',
    'Email',
    'Empresa',
    'Celular',
    {
      name: 'whatsapp',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'phone',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'reproved',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'company_id',
      options: {
        display: false,
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: '',
      options: {
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div className="table-icons">
              {tableMeta.rowData[1] && !tableMeta.rowData[8] && (
                <ActionButton
                  color="#e9b300"
                  data-tip="Editar"
                  onClick={() => openModal(tableMeta.rowData)}
                >
                  <FiEdit />
                </ActionButton>
              )}
              {!tableMeta.rowData[1] && (
                <ActionButton
                  color="#00a020"
                  data-tip="Aprovar"
                  onClick={() => openModal2(tableMeta.rowData)}
                >
                  <FiCheck />
                </ActionButton>
              )}
              {!tableMeta.rowData[8] && (
                <>
                  <ActionButton
                    color="#d20000"
                    data-tip="Reprovar"
                    onClick={() => openModalReprove(tableMeta.rowData)}
                  >
                    <FiX />
                  </ActionButton>
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown' as any,
    // filter: false,
    print: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    textLabels: {
      body: {
        noMatch: 'Desculpe, nenhum resultado encontrado',
        toolTip: 'Ordenar§',
      },
      pagination: {
        next: 'Próxima pagina',
        previous: 'Pagina anterior',
        rowsPerPage: 'Resultados por pagina:',
        displayRows: 'de', // 1-10 of 30
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'Filtros',
        reset: 'Resetar',
      },
      viewColumns: {
        title: 'Mostrar colunas',
      },
      selectedRows: {
        text: 'resultado(s) detelado(s)',
        delete: 'Deletar',
      },
    },
  };

  const usersHeaderRef = useRef(null);

  useEffect(() => {
    dispatch(closeReproveUsers());
    dispatch(closeEditUsers());
    dispatch(closeAproveUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dashboard>
      <Flex ref={usersHeaderRef}> teste 2</Flex>
      <Content>
        <Tabs defaultIndex={0}>
          <TabList>
            <Tab>Usuarios</Tab>
            <Tab>Colaboradores</Tab>
            <Tab>
              Pendentes{' '}
              {usersData.pendingUsers > 0 && (
                <Badge
                  count={usersData.pendingUsers}
                  textColor="#fff"
                  className="softcap-generic-badge softcap-badge radioactive-badge"
                />
              )}
            </Tab>
            <Tab>Reprovados</Tab>
          </TabList>

          <TabPanel>
            {tableLoad ? (
              <TableLoading />
            ) : (
              <MUIDataTable
                title=""
                data={usuarios !== [] ? usuarios : []}
                columns={columns}
                options={options}
              />
            )}
          </TabPanel>
          <TabPanel>
            <MUIDataTable
              title=""
              data={colaboradores !== [] ? colaboradores : []}
              columns={columns}
              options={options}
            />
          </TabPanel>
          <TabPanel>
            <MUIDataTable
              title=""
              data={pendentes !== [] ? pendentes : []}
              columns={columns}
              options={options}
            />
          </TabPanel>

          <TabPanel>
            <MUIDataTable
              title=""
              data={reprovados !== [] ? reprovados : []}
              columns={columns}
              options={options}
            />
          </TabPanel>
        </Tabs>
      </Content>
      <Modal
        title={`Editando: ${usersData.userEditProfile?.name}`}
        modalSize="50%"
        modalClose={usersData.editUser}
        closable
      >
        <div className="modal-body-div softcap-mb-20">
          <EditUser />
        </div>
      </Modal>

      <Modal
        title={`Aprovar: ${usersData.userEditProfile?.name}`}
        modalSize="50%"
        modalClose={usersData.aprovingUser}
        closable
        isAprove={true}

      >
        <div className="modal-body-div softcap-mb-20">
          <AproveUser usersHeaderRef={usersHeaderRef} />
        </div>
      </Modal>

      <Modal
        title={`Reprovar: ${usersData.userEditProfile?.name}`}
        modalSize="50%"
        modalClose={usersData.reprovingUser}
        closable
      >
        <div className="modal-body-div softcap-mb-20">
          <ReproveUser />
        </div>
      </Modal>


      <Modal
            title="Criar nova empresa"
            modalSize="50%"
            modalClose={addCompany}
            closable
          >
            <AddCompany />
          </Modal>
    </Dashboard>
  );
};

export default DefaultUserDashboard;
