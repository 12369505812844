import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import PendentActivation from '../pages/PendentActivation';
import MailConfirmation from '../pages/MailConfirmation';
import RegisterSucces from '../pages/SignUp/RegisterSuccess';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import AdminUsers from '../pages/Dashboard/AdminUser/Users';
import AdminProducts from '../pages/Dashboard/AdminUser/Products';
import AdminLists from '../pages/Dashboard/AdminUser/Lists';
import AdminOrders from '../pages/Dashboard/AdminUser/Orders';
import AdminOrdersRelease from '../pages/Dashboard/AdminUser/Orders/Release';
import ListProducts from '../pages/Dashboard/AdminUser/ListProducts';
import NewOrder from '../pages/Dashboard/DefaultUser/NewOrder';
import OrderDetail from '../pages/Dashboard/DefaultUser/OrderDetail';
import AdminOrderDetail from '../pages/Dashboard/AdminUser/AdminOrderDetail';

import DefaultUserDashboard from '../pages/Dashboard/DefaultUser';
import AdminUserDashboard from '../pages/Dashboard/AdminUser';
import Cart from '../pages/Dashboard/DefaultUser/Cart';
import UserHistory from '../pages/Dashboard/DefaultUser/History';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route
      path="/pendent-activation"
      exact
      component={PendentActivation}
      isPrivate
    />
    <Route path="/register-success" exact component={RegisterSucces} />
    <Route path="/mail-confirmation" exact component={MailConfirmation} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route
      path="/dashboard/home"
      exact
      component={DefaultUserDashboard}
      isPrivate
      permission={1}
    />

    <Route
      path="/dashboard/new-order"
      component={NewOrder}
      isPrivate
      permission={1}
    />
    <Route
      path="/dashboard/history"
      component={UserHistory}
      isPrivate
      permission={1}
    />
    <Route
      path="/dashboard/order-detail"
      component={AdminOrderDetail}
      isPrivate
      permission={1}
    />
    <Route path="/dashboard/cart" component={Cart} isPrivate permission={1} />
    <Route
      path="/admin/users"
      exact
      component={AdminUsers}
      isPrivate
      permission={3}
    />
    <Route
      path="/admin/products"
      exact
      component={AdminProducts}
      isPrivate
      permission={3}
    />
    <Route
      path="/admin/order-detail"
      component={AdminOrderDetail}
      isPrivate
      permission={3}
    />
    <Route
      path="/admin/lists"
      component={AdminLists}
      isPrivate
      permission={3}
    />
    <Route
      path="/admin/orders"
      component={AdminOrders}
      isPrivate
      permission={3}
    />
    <Route
      path="/admin/release/:id"
      component={AdminOrdersRelease}
      isPrivate
      permission={3}
    />

    <Route
      path="/admin/singleList"
      component={ListProducts}
      isPrivate
      permission={3}
    />

    <Route
      path="/admin/"
      component={AdminUserDashboard}
      isPrivate
      permission={3}
    />
    <Route
      path="/dashboard/"
      component={DefaultUserDashboard}
      isPrivate
      permission={1}
    />
  </Switch>
);

export default Routes;
