import React, { HTMLAttributes, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Container, NewsBox } from './styles';

import { getPrismicClient } from '../../../services/prismic'
import Prismic from '@prismicio/client'

import { RichText } from 'prismic-dom'

import { Grid, Skeleton, SkeletonText } from '@chakra-ui/react';

interface INewsData {
  slug: string | undefined;
  title: string | undefined;
  excerpt: string | undefined;
  updatedAt: string | undefined;
  image: string | undefined;
}

type orderProps = HTMLAttributes<HTMLElement> & {
  type?: string;
  reveal?: number;
};

const prismic = getPrismicClient()

const News: React.FC<orderProps> = () => {
  const [news, setNews] = useState<INewsData[]>([]);
  const [loadingNews, setLoadingNews] = useState<boolean>(false);

  useEffect(() => {

    async function getNews() {

      try {
        setLoadingNews(true);

        const response = await prismic.query([
          Prismic.predicates.at('document.type', 'post_painel_h')
        ], {
          fetch: ['post.title', 'post.content'],
          pageSize: 100,
        })

        // console.log("entrou no ponto 2")
        // console.log(response)

        if (response.results !== undefined) {
          // console.log("entrou no response")
          const thisNews = response?.results?.map(post => {
            // console.log("entrou no map")
            // console.log(post)
            return {
              slug: post.id,
              image: post.data.imagem_de_capa.url,
              title: RichText.asText(post.data.titulo),
              excerpt: post.data.post.find(() => 'paragraph')?.text ?? '',
              updatedAt: new Date(post.last_publication_date ?? "").toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              })
            }
          })
          setNews(thisNews)
        }
      } catch(err) {
      } finally {
        setLoadingNews(false)
      }


    }

    getNews()



  }, [])

  const fakeNews = ["1", "2", "3"]

  return (
    <Container>
      <Grid templateColumns={["1fr", "1fr", "1fr 1fr 1fr", "1fr 1fr 1fr"]} rowGap={5} gap={5} width="100%" maxW="1500px" margin="0 auto">
      {loadingNews && (
        fakeNews.map((key, index) => {
          if (index < 3) {
            return (
            <NewsBox key={`newskeyfake_${key}`}>
              <Skeleton as="img"/>
              {/* <img src={newsTmpImage} alt="News image" /> */}
              <Skeleton as="h3" width="60%" height="25px" marginBottom="0px"/>
              <Skeleton as="h3" width="40%" height="15px" marginTop="5px !important" />
              {/* <h3>Titulo aqui</h3> */}
              <p style={{marginBottom: "40px"}}>
              <SkeletonText mt="0" skeletonHeight="10px" noOfLines={2} spacing="2" />
              </p>
              <Link to="/">
                <Skeleton width="50px" height="10px" />
              </Link>
            </NewsBox>)
          }
        }
        )
      )}
      {news.map((singleNew: INewsData, index) => {
        const newsImage = singleNew.image;
        if (index < 3) {
          return (<NewsBox key={`newsbox_${singleNew.slug}`}>
            <img src={newsImage} alt="News image" />
            <h3>{singleNew.title?.split(' ').splice(0, 5).join(' ')}
            {singleNew.title && singleNew.title?.split(' ').length > 6 && "..."}
            </h3>
            <span>{singleNew.updatedAt}</span>
            <p style={{marginBottom: "40px"}}>{singleNew.excerpt?.split(' ').splice(0, 10).join(' ')}
            {singleNew.excerpt && singleNew.excerpt?.split(' ').length > 6 && "..."}
            </p>
            <Link to="/">Ver Mais</Link>
          </NewsBox>)
        }
      }
      )}
      </Grid>
    </Container>
  );
};

export default News;
