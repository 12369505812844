/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { FiHome, FiBox, FiCalendar } from 'react-icons/fi';

import MenuButton from './MenuButton';

const UserMenu: React.FC = () => {
  const path = window.location.pathname;
  let pageLink = path.split('/')[2];

  if (pageLink === '' || pageLink === undefined) {
    pageLink = 'home';
  }

  return (
    <>
      <MenuButton
        id="home"
        link="/dashboard"
        text="Home"
        color="#7764E4"
        current={pageLink}
      >
        <FiHome /> <span>Home</span>
      </MenuButton>
      <MenuButton
        id="new-order"
        text="Novo Pedido"
        link="/dashboard/new-order"
        color="#11CDEF"
        current={pageLink}
      >
        <FiBox /> <span>Novo Pedido</span>
      </MenuButton>
      <MenuButton
        id="history"
        text="Historico"
        link="/dashboard/history"
        color="#F53C56"
        current={pageLink}
      >
        <FiCalendar /> <span>Histórico de Pedido</span>
      </MenuButton>
      {/* <MenuButton
        id="wishlist"
        link="/dashboard/wishlist"
        color="#FB6340"
        current={pageLink}
      >
        <FiFileText /> <span>Lista de Desejos</span>
      </MenuButton> */}
    </>
  );
};

export default UserMenu;
