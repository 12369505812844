import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Divisor = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.double {
    width: 48% !important;
  }

  .cepError {
    display: flex;
    flex-direction: column;
  }

  .cepError span {
    display: flex;
    align-items: center;
    padding-top: 15px;
    color: #f00;
  }

  .new-company {
    margin-top: 5px;
    border: none;
    background: none;
    text-decoration: underline;
    color: #0074b8;
    margin-top: 15px;
  }
`;
