import { combineReducers } from 'redux';

import orderProgress from './orderprogress/reducer';
import userInfo from './user/reducer';
import usersInfo from './users/reducer';
import companysInfo from './companys/reducer';
import productsInfo from './products/reducer';
import cart from './cart/reducer';
import list from './list/reducer';
import specificList from './specificList/reducer';

export default combineReducers({
  orderProgress,
  userInfo,
  usersInfo,
  companysInfo,
  productsInfo,
  cart,
  list,
  specificList,
});
