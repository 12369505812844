import { createGlobalStyle, keyframes } from 'styled-components';

const pulse = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(212, 19, 13, 0.7);
	}

	70% {
		transform: scale(1.15);
		box-shadow: 0 0 0 7px rgba(212, 19, 13, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(212, 19, 13, 0);
	}
`;

export default createGlobalStyle`

  *{
    transform: translateZ(0)
  }

  .slick-dots li button:before {
    color: #fff;
    font-size: 9px;
  }

  .slick-disabled {
    opacity: 0.2 !important;
    cursor: default !important;
  }

  .slick-dots {
    bottom: -40px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li.slick-active button:before {
    color: #fff;
    font-size: 11px;
  }

    h1, h2, h3, h4, h5 {
      font-weight: 600 !important;
    }


    .image_empityCart {
      width: 90%;
      max-width: 600px;
      margin: 0 auto;
    }

    .neworder-content {
      .MuiPaper-root {
        margin-top: 30px;
      }
    }


    .modal-form_button-divisor {
        display: flex;
        justify-content: center !important;

        button {
          width: 100% !important;
        }
    }

    .modal-question {
      font-weight: 300 !important;
    }

    .whatsappField {
      display: flex;
      align-items: center;
      margin-top: 15px;

      span {
        margin-right: 15px;
      }
    }

    .mt-10 {
      margin-top: 10px;
    }
    .mt-20 {
      margin-top: 20px;
    }
    .mt-30 {
      margin-top: 30px;
    }
    .mt-40 {
      margin-top: 40px;
    }

    .hide {
      display: none;

    }

    .displaymenu {
      max-height: 2000px !important;
    }

    .animated-toglemenu {
      transition: 0.5s ease;
    }

    .hidemenu {
      max-height: 0px !important;
    }

    .menuReveal {
      @media (max-width: 1000px) {
        /* display: block !important; */
        margin-right: 0px !important;
        right: 0px !important;
      }

      svg {
        color: #fff !important;
      }

      @media (min-width: 1000px) {
        display: none !important;
      }
    }

    .menuToggle {
      @media (min-width: 1000px) {
        display: block;
      }

      @media (max-width: 1000px) {
        display: none !important;
      }
    }

    .modal-imagebuttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
    }

    .modal-simplebutton {
      padding: 20px 60px !important;
    }

    .modalbutton-image_edit {
      padding: 10px 40px;
      cursor: pointer;
      display: block;
      margin-top: 10px;
      background: #000B3A;
      border-radius: 5px;
      color: #fff;
      border: none;
    }

    .modalbutton-image_delete {
      margin-top: 10px;
      color: #f00;
      background: none;
      border: none;
    }

    .modal-simplebuttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      padding: 0 30px;
    }

    .modalbutton-cancelar {
      color: #000b3a;
      margin-top: 10px;
      background: none;
      border: none;
      text-decoration: underline;
    }

    .modal-product-image {
      width: 100%;
    }

    .table-img {
      max-width: 300px !important;
    }

    .margin-center {
      margin: 0 auto;
    }

    .flex-column-center {
      display: flex;
      align-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .margin-bottom-10 {
      margin-bottom: 10px;
    }

    .text-center {
      text-align: center;
    }

    .text-center {
      text-align: center;
    }

    .softcap-mb-20 {
      margin-bottom: 20px;
    }

    .Toastify__toast-container .Toastify__toast {
      border-radius: 5px !important;
      font-weight: 600;
  }

  .Toastify__toast {
  }
  .Toastify__toast--rtl {
  }
  .Toastify__toast--dark {
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--info {
    background: #29a1f7;
    color: #ffffff;
  }
  .Toastify__toast--success {
    background: #00b583;
    color: #ffffff;
  }
  .Toastify__toast--warning {
    background: #ffa500;
    color: #ffffff;
  }

  .Toastify__toast--error {
    background: #c53030;
    color: #ffffff;
  }
  .Toastify__toast-body {
  }


    .modal-body-div {
      padding: 0 20px;
    }

    .MuiPaper-rounded {
      border-radius: 10px !important;

    }

    .MuiChip-root {
      margin-bottom: 10px !important;
    }

    .MuiTableBody-root tr:nth-child(even) {
      background-color: #F7FAFC;
    }

    .MuiTableBody-root tr:hover {
      background-color: #DFF1FD !important;
    }

    .MuiTableBody-root tr td, .MuiTableHead-root tr th {
      border-bottom: none !important;
    }

    .MuiPaper-elevation4 {
      box-shadow: 0 3px 6px rgba(44, 40, 40, 0.11) !important;
    }

    .MuiTableRow-head th {
      background-color: #F1F3F9;
      padding: 2px 0px 2px 16px;
      font-size: 11px;
      font-weight: 600;
    }

    .MuiTableRow-head th.MuiTableCell-paddingCheckbox {
      padding: 0px 0px 0px 4px;
    }

    @media (max-width: 959.95px) {
      .MUIDataTableBodyCell-stackedHeader-71 {
        font-weight: 600;
      }

    }
    .MUIDataTableHeadCell-sortAction-51 {
      justify-content: center;
      align-items: center;
    }

    .desconto {
      font-weight: 900;
      color: #29a1f7;

      del {
        font-weight: 300;
        font-size: 12px;
        color: #888;
      }
    }

    .semdesconto {
      font-weight: 900;
    }

    .loginBox-alert {
      img {
        width: 80%;
        margin: 20px auto;
      }
    }

    .newOrder_promologo {
      display: flex;
      align-items: center;
      span {
        width: 20px;
        margin-right: 10px;
        img {
          max-width: 20px;
        }
      }
    }

    .newOrder_buttons {

      display: flex;
      align-items: center;
      text-align: center;


      input {
        width: 60px;
        height: 26px;
        border: 1px solid #172B4D;
        font-size: 13px;
        text-align: center;
        right: 0px !important;
        padding-right: 0px !important;
        padding: 0 0 0 10px !important;
      }

      button {
        height: 26px;
      }

      .newOrder_button_add {
        border-radius: 0 5px 5px 0;
        border: 1px solid #172B4D;
        background-color: #fff;
        border-left: none;
        padding: 0 5px;
      }

      .newOrder_button_remove {
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #172B4D;
        background-color: #fff;
        border-right: none;
        padding: 0 5px;
      }
    }

    .newOrder_product_checkedOut {
      color: #9A9A9A;
      margin-left: auto;
      margin-right: 30px;
    }
    .newOrder_product_checkedIn {
      color: #00b583;
      margin-left: auto;
      margin-right: 30px;
    }

    @keyframes fadeIn {
    0% {
      opacity:0;
      bottom: -200px;
    }
    20% {
      opacity:0;
    }
    100% {
      opacity:1;
      bottom: 0;
      }
    }

    @-moz-keyframes fadeIn {
    0% {
      opacity:0;
      bottom: -200px;
    }
    20% {
      opacity:0;
    }
    100% {
      opacity:1;
      bottom: 0;
      }
    }

    @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
      bottom: -200px;
    }
    20% {
      opacity:0;
    }
    100% {
      opacity:1;
      bottom: 0;
      }
    }

    @-o-keyframes fadeIn {
    0% {
      opacity:0;
      bottom: -200px;
    }
    20% {
      opacity:0;
    }
    100% {
      opacity:1;
      bottom: 0;
      }
    }

    @-ms-keyframes fadeIn {
    0% {
      opacity:0;
      bottom: -200px;
    }
    20% {
      opacity:0;
    }
    100% {
      opacity:1;
      bottom: 0;
      }
    }

    .NewOrderCart {
      width: 100%;
      background-color: #000b3a;
      color: #fff;
      position: fixed;
      bottom: 0px;
      margin-left: -40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 0px 30px 0px;
      flex: 1;
      z-index: 120;

      animation: fadeIn ease 1s;
      -webkit-animation: fadeIn ease 1s;
      -moz-animation: fadeIn ease 1s;
      -o-animation: fadeIn ease 1s;
      -ms-animation: fadeIn ease 1s;
      padding-right: 150px;

      .NewOrderCart_discountTag {
        background-color: #03b4ff;
        padding: 10px 100px 10px 0px;
        text-align: center;
        color: #172B4D;
        width: 100%;
        font-weight: 700;
        position: absolute;
        top: -30px;
        right: 0;

        @media (max-width: 1000px) {
          padding: 10px 0px 10px 0px;
        }
      }
    }

    .cart_content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1 {
        align-self: flex-start;
        margin-left: 120px;
      }

      div {
        h2 {
          text-align: center;
          margin: 30px 0px 50px 0;
        }
      }
    }

    .cart_content {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .cart_titleMargin {
      margin-top: 40px;
    }

    .cart_tableBox-padding {
      padding: 20px !important;
    }

    .cart_conatiner {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: column;
    }

    .orderDetail_conatiner {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 3px 6px rgba(44,40,40,0.11) !important;
      margin-top: 30px;

      .cart_tableBox {
        border: 1px solid #0683dd;
      }
    }

    .cart_width-auto {
      width: 100% !important;
    }

    .promo {
      /* color: #40C4FC; */
      font-weight: 600;
    }

    .noPromo {
      font-weight: 600;
    }

    .cart_width-auto {
      ul {
        width: 100%;
        list-style: none;

        li {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #11111120;
          padding-bottom: 10px;
          padding-top: 10px;
        }

        li:last-child {
          border-bottom: none;
          margin-bottom: none;
          padding-bottom: none;
          border-top: 1px solid #111111;
          font-weight: 900;
        }

        li span:last-child {
          font-weight: 600;
        }

        li span:first-child {
          font-size: 14px;
        }

        li.cart_listPromo span:last-child {
          color: #40C4FC;
          font-weight: 600;
        }
      }
    }

    .cart_doubleDivider {
      display: flex;
      flex: 1 0 50%;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      div {
        width: 48%;
      }
    }

    .w-100 {
      width: 100%;
      display: block;
    }

    .cart_doubleDivider7030 {
      display: flex;
      flex: 1 0 50%;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      div {
        width: 58%;
      }
      div:last-child {
        width: 38%;
      }
    }

    .cart_doubleContent {
      display: flex;
      flex: 1 0 100%;
      flex: wrap;
      width: 100%;
      justify-content: space-between;

      span {
        width: 48%
      }

      span:last-child {
        text-align: right;
      }

    }

    .cartButon_alert {
      color: #c53030;
    }

    .cart_finalButonBox {
      display: flex;
      justify-content: flex-end;
    }

    .cart_finalButtonButton {
      border-radius: 50px;
      padding-top: 20px;
      padding-bottom: 20px;
      align-self: right;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-content: center;
      right: 0px;

      svg {
        font-size: 20px;
        font-weight: 900;
        margin-right: 20px;
      }

      &:disabled {
        background-color: #dcdcdc;
        border-color: #dcdcdc;
        cursor: default;

        &:hover {
          cursor: default;
        }


      }
    }

    .cart_totalLabel {
      font-weight: 600;
    }

    .cart_width100 {
      width: 100% !important;
    }

    .cart_finalButton {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: right !important;
      align-items: right !important;
      padding-top: 40px;
      text-align: right;

      div {
        width: 100%;
        text-align: right;
        align-content: right;
      }
    }

    .cart_spaceBetween {
      display: flex;
      justify-content: space-between;
    }

    .cart_tableBox {
      background: #fff;
      padding: 20px 0px;
      border-radius: 10px;
      margin-top: 20px;
      box-shadow: 0 3px 6px rgba(44,40,40,0.11) !important;
    }

    .cart_tableBox_orderDetail {
      background: #fff;
      padding: 20px 0px;
      border-radius: 10px;
      margin-top: 20px;
      border: 1px solid #0683dd;
      box-shadow: 0 3px 6px rgba(44,40,40,0.11) !important;
    }

    .cart_tableBox_orderDetail ul li{
      border-bottom: 0px;
      border-top: 0px !important;
      font-size: 15px !important;
      justify-content: left !important;
    }

    .cart_tableBox_orderDetail ul li span:first-child{
      font-weight: 600 !important;
      font-size: 15px !important;
    }

    .cart_tableBox_orderDetail ul li span:last-child{
      font-weight: 400 !important;
      margin-left: 15px !important;
    }

    .cart_tableBox_70 {
      width: 110% !important;
    }

    .cart_tableBox_30 {
      width: 80% !important;
    }

    .cart_table {
      width: 100%;
      border-collapse: collapse;

    .cart_namemargin {
      padding-left: 60px;
    }

    .cart_imageMargin {
      margin-right: 18px;
    }

    .cart_nameSpace {
      margin-right: 60px;
    }

      thead tr:first-child {
        padding-bottom: 20px;
        /* border-bottom: 1px solid #172B4D; */
      }

      th {
        text-align: left;
        padding-bottom: 5px;
        font-size: 12px;
        background-color: #f0f3f8;
      }

      td > * {
        vertical-align : middle;
      }

      th {
        padding: 3px 5px;
      }

      td {
        padding: 14px 5px;


        del {
          color: #777;
          font-size: 15 px;
        }

        img {
          margin-left: 10px;
          max-width: 30px;
          border-radius: 100px;
        }
      }

      tbody tr:nth-child(even) {
        background-color: #f7fafc;
      }
    }

    .react-tooltip-lite {
      background: #111111;
      border-radius: 5px;
      color: white;
    }

    .newOrder_productName {
      display: flex;
      align-content: center;
      color: #29a1f7;
      font-weight: 600;
      cursor: pointer;

      svg {
        font-size: 17px;
        margin-right: 10px;
      }

     .react-tooltip-lite {
        min-width: 220px;
        text-align: center;
      }

      img {
        max-width: 200px;
      }
    }

    .cartLink {
      color: #29a1f7;
    }

    .react-tooltip-lite-arrow {
      border-color: #111111;
    }

    .NewOrderCart_content {
      width: 80%;
      margin-top: 30px;
      padding-right: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    @media(max-width: 1000px) {
      .NewOrderCart {
       width: 100%;
       padding-right: 0px;
      }
    }

    .NewOrderCart_total {
      font-weight: 900;
      font-size: 28px;
    }

    .NewOrderCart_discount {
      font-weight: 600;
      color: #40C4FC;
    }

    .NewOrderCart_content-right {
      margin-left: 50px;

      a {
        border: 2px solid #40C4FC;
        color: #40C4FC;
        font-weight: 900;
        font-size: 20px;
        text-decoration: none;
        padding: 10px 45px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        text-transform: uppercase;

        &:hover {
          background: #03b4ff;
          border: 2px solid #03b4ff;
          color: #000d39;
        }

        svg {
          margin-right: 10px;
        }
      }
    }

    .cartBadge {
      background-color: #c53030;
      color: #fff;
      width: 17px;
      height: 17px;
      border-radius: 50px;
      font-size: 10px;
      position: absolute;
      line-height: 10px;
      text-align: center;
      margin-top: -30px;
      margin-left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 900;
    }

    .table-button_new {
      color: #000B3A;
      background-color: #fff;
      border: 2px solid #000B3A;
      padding: 10px 20px;
      border-radius: 5px;
      font-weight: 600;
      svg {
        margin-right: 8px;
      }

      &:hover {
        background-color: #000b3a;
        color: #fff;
      }
    }

    .table-button_new span {
      display: flex;
      align-items: center;
      justify-content: center;
    }


  .softcap-generic-badge {
      top: -28px !important;
      right: -17px !important;
    }

  .listActive {
    background-color: #00b583;
    color: #fff;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: default;
  }

  .listInactive {
    background-color: #ddd;
    color: #777;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: default;
  }
  .listwaiting {
    background-color: #f7c202;
    color: #8a5a00;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: default;
  }

  .radioactive-badge {
	box-shadow: 0 0 0 0 rgba(212, 19, 13, 1);
	transform: scale(1);
	animation: ${pulse} 2s infinite;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Montserrat', sans-serif;
  }

  body {
    background-color: #F1F3F9;
    color: #172B4D;
    -webkit-font-smoothing: antialiased;
    height: 100% !important;

  }

  body, input, button {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  button {
    cursor: pointer;
  }

  .react-tabs__tab-list {
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-bottom: 50px;

    li {
      font-family: Roboto,sans-serif !important;
      font-weight: 500;
      padding: 7px 40px;
      color: #666;
      border-radius: 5px;
      margin: 0 5px;

      @media (max-width: 1000px) {
        padding: 7px 20px;
  }
    }

    li:hover {
      background-color: #000B3A10;
      color: #000;
    }

    li.react-tabs__tab--selected {
      background-color: #000B3A;
      color: #fff;
      font-weight: 600;



    }


  }

  .table-icons {
    display: flex;

    div {
      margin-right: 10px;
      a {
        svg {
          font-size: 16px !important;
        }
      }
    }
  }


`;
