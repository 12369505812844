/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { IList } from './types';

export function addListInfo(apiListInfo: IList[] | undefined) {
  return {
    type: 'ADD_LIST_INFO',
    payload: {
      apiListInfo,
    },
  };
}

export function removeListInfo() {
  return {
    type: 'REMOVE_LIST_INFO',
  };
}
