/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  AllHTMLAttributes,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { FiBox, FiLayers, FiCodepen } from 'react-icons/fi';
import { CgRename } from 'react-icons/cg';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { Container, Divisor } from './styles';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import SimpleButton from '../../../../SimpleButton';
import Modal from '../../../../Modal';
import Input from '../../../../Input';
import Select from '../../../../Select';
import { Box, Stack } from '@chakra-ui/react';
import Switch from 'react-switch'
import { useSelector } from 'react-redux';
import { IState } from '../../../../../store';
import { IUserInfoState } from '../../../../../store/modules/user/types';


type IModal = AllHTMLAttributes<HTMLAllCollection> & {
  modalStatus: boolean;
  productInfo: any[];
};

interface ProductInfoProps {
  id: any,
  type: any,
  scientificName: any,
  family: any,
  name: any,
  size: any,
  defaultPrice: any,
  isExclusive: boolean
}

const EditProduct: React.FC<IModal> = ({ modalStatus ,productInfo }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);

  const [loading, setLoading] = useState(false);
  const [productToEditInfo, setProductToEditInfo] = useState<ProductInfoProps | null>(null);
  const [modal, setModal] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [isExclusiveHubProduct, setIsExclusiveHubProduct] = useState(false);

  // const startFormatedTime = format(new Date(), 'HH:mm:ss');

  // const endDate = parseISO(listInfo.end);

  useEffect(() => {

    setProductToEditInfo({
      id: productInfo[0],
      type: productInfo[2],
      scientificName: productInfo[2] !== 4 ? productInfo[5] : null,
      family: productInfo[3],
      name: productInfo[2] !== 4 ? productInfo[4] : productInfo[3],
      size: productInfo[2] !== 4 ? productInfo[6] : null,
      defaultPrice: productInfo[2] !== 4 ? productInfo[7] : productInfo[5],
      isExclusive: productInfo[2] !== 4 ? productInfo[8] : productInfo[6],
    })

    console.log(productInfo)

    setIsExclusiveHubProduct(productInfo[2] !== 4 ? productInfo[8] : productInfo[6])

    const modalChange = async (): Promise<void> => {
      if (modalStatus) {
        await setModal(true);
        await setModal(false);
      }
    };

    modalChange();
    setLoaded(true)
  }, [modalStatus]);

  const handleFormSubmit = useCallback(
    async (data): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Você precisa informar um nome'),
          // family: Yup.string().required('Você precisa informar uma familia'),
          // scientific_name: Yup.string().required(
          //   'Você precisa informar um nome cientifico',
          // ),
          default_price: Yup.string().required(
            'Você precisa informar um valor padrão',
          ),
          type: Yup.number()
            .typeError('Selecione um tipo')
            .required('Selecione um tipo'),
          size: Yup.string()
            .typeError('Selecione um tamanho')
            .required('Selecione um tamanho'),
        });

        let final_price = data.default_price.replace(/\./g, '');
        final_price = final_price.replace(/,/g, '.');

        await schema.validate(data, {
          abortEarly: false,
        });

        const family = data.family || null;
        const scientific_name = data.scientific_name || "";
        const size = (data.size === "Sem Tamanho") ? " " : data.size;
        console.log(isExclusiveHubProduct)
        api.put('/product/update', {
          id: data.id,
          name: data.name,
          exclusive: isExclusiveHubProduct,
          type: productToEditInfo?.type ?? data.type,
          scientific_name,
          size,
          family,
          default_price: Number(final_price),
        });

        addToast({
          type: 'success',
          title: 'Produto adicionado',
        });



        setModal(true);

        window.location.reload();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let description;

          if (errors.name) {
            description = errors.name;
          }

          if (errors.type) {
            description = errors.type;
          }

          if (errors.scientific_name) {
            description = errors.scientific_name;
          }

          if (errors.family) {
            description = errors.family;
          }

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, isExclusiveHubProduct],
  );

  return (
    <Modal title="Editar Produto" modalSize="50%" modalClose={modal} closable>
    {loaded &&
      <Container>
        <Form ref={formRef} onSubmit={handleFormSubmit}>
          <Divisor>
            <div className="double">
              <h5>Nome:</h5>
              <Input
                className="singleList-productSearch"
                defaultValue={productToEditInfo?.name}
                name="name"
                icon={FiBox}
                placeholder="nome"
              />
              <div style={{display: "none"}}>
              <Input
                disabled
                defaultValue={productToEditInfo?.id}
                name="id"
                icon={FiBox}
                placeholder="nome"
              />
              </div>
            </div>
            <div className="double">
              <h5>tipo:</h5>
              <div style={{opacity: "0.5"}}>
              <Select name="type" icon={FiCodepen} disabled >
                <option value="">Escolha um tipo</option>
                <option value={1} selected={productToEditInfo?.type === 1}>Peixe</option>
                <option value={3} selected={productToEditInfo?.type === 2}>Coral</option>
                <option value={2} selected={productToEditInfo?.type === 3}>Invertebrado</option>
                <option value={4} selected={productToEditInfo?.type === 4}>Produto</option>
              </Select>
              </div>
            </div>
          </Divisor>

          <Divisor style={{ marginTop: 20 }}>
            <div className="double">
              <h5>Nome Cientifico:</h5>
              <Input
                defaultValue={productToEditInfo?.scientificName}
                className="singleList-productSearch"
                name="scientific_name"
                icon={CgRename}
                placeholder="Nome cientifico"
              />
            </div>
            <div className="double">
              <h5>Familia:</h5>
              <Input
                defaultValue={productToEditInfo?.family}
                className="singleList-productSearch"
                name="family"
                icon={CgRename}
                placeholder="Familia"
              />
            </div>
          </Divisor>

          <Divisor style={{ marginTop: 20 }}>
          <div className="double">
              <h5>Tamanho:</h5>
              <Select name="size" icon={FiLayers}>
                <option value="">Escolha um tamanho</option>
                <option value="Sem Tamanho" selected={productToEditInfo?.size === null}>Sem Tamanho</option>
                <option value="TINY" selected={productToEditInfo?.size === "TINY"}>TINY</option>
                <option value="SM" selected={productToEditInfo?.size === "SM"}>SM</option>
                <option value="SM/SMD" selected={productToEditInfo?.size === "SM/SMD"}>SM/SMD</option>
                <option value="SMD" selected={productToEditInfo?.size === "SMD"}>SMD</option>
                <option value="SMD/MD" selected={productToEditInfo?.size === "SMD/MD"}>SMD/MD</option>
                <option value="MD" selected={productToEditInfo?.size === "MD"}>MD</option>
                <option value="MD/MDLG" selected={productToEditInfo?.size === "MD/MDLG"}>MD/MDLG</option>
                <option value="MDLG" selected={productToEditInfo?.size === "MDLG"}>MDLG</option>
                <option value="MDLG/LG" selected={productToEditInfo?.size === "MDLG/LG"}>MDLG/LG</option>
                <option value="LG" selected={productToEditInfo?.size === "LG"}>LG</option>
                <option value="LG/XLG" selected={productToEditInfo?.size === "LG/XLG"}>LG/XLG</option>
                <option value="XLG" selected={productToEditInfo?.size === "XLG"}>XLG</option>
              </Select>
            </div>
            <div className="double">
              <h5>Valor padrão:</h5>
              <Input
                defaultValue={productToEditInfo?.defaultPrice?.replace("R$", "")}
                className="singleList-productSearch"
                name="default_price"
                mask="money"
                placeholder="Valor padrão"
              />
            </div>

          </Divisor>

          <Divisor style={{ marginTop: 20, display: userData.user.permission === 3 ? 'block' : 'none' }}>
          <Stack >
            <h5 onClick={() => setIsExclusiveHubProduct(!isExclusiveHubProduct)}>Esse produto é exclusivo hub?</h5>
            <Box>
              <Switch
                onColor="#52c75d"
                onChange={() => setIsExclusiveHubProduct(!isExclusiveHubProduct)}
                checked={isExclusiveHubProduct}
              />
          </Box>
          </Stack>
          </Divisor>

          <Divisor style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SimpleButton loading={loading} type="submit">
              Editar
            </SimpleButton>
          </Divisor>
        </Form>
      </Container>
    }
    </Modal>
  );
};

export default EditProduct;
