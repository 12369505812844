import React, { createContext, ReactNode, useState } from 'react';

interface OrdersProviderProps {
  children: ReactNode;
}

interface OrdersContextData {
  orderUpdateCount: number;
  ordersLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  orders: object[] | undefined;
  updateOrderCount: () => void;
  setOrders: (orders: any) => void;
  setOrdersLoading: (orders: any) => void;
  setCurrentMonth: (orders: any) => void;
  setCurrentYear: (orders: any) => void;
  currentMonth: number;
  currentYear: number;
}

export const OrdersContext = createContext({} as OrdersContextData);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function OrdersProvider({ children }: OrdersProviderProps) {
  const [orderUpdateCount, setOrderUpdateCount] = useState(0);
  const [orders, setOrders] = useState();
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth(),
  );
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear(),
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const updateOrderCount = () => {
    setOrderUpdateCount(orderUpdateCount + 1);
  };

  return (
    <OrdersContext.Provider
      value={{
        orderUpdateCount,
        updateOrderCount,
        setOrders,
        orders,
        setOrdersLoading,
        ordersLoading,
        setCurrentMonth,
        setCurrentYear,
        currentMonth,
        currentYear,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
}
