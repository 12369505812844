import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { lighten } from 'polished';

import logoHub from '../../assets/sign-in-background.svg';

export default createGlobalStyle`
  body {
    background-color: #11cdef !important;
  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #11cdef;
  background: url('${logoHub}') no-repeat top center #11cdef;
  padding-top: 120px;

  p {
    span {
      color: #f00;
    }
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Content = styled.div`
  max-width: 475px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;
  form {
    background-color: #fdfdfd;
    border-radius: 10px;
    padding: 50px 35px;
    width: 100%;
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      display: flex;
      align-items: center;
      align-content: center;
      min-height: 40px;
      margin: 5px 0px 10px;

      span {
        padding-right: 10px;
      }

      div {
        .whatsappLogo {
          margin-top: 5px;
          margin-left: 8px;
          height: 18px;
          fill: #fff;
        }
      }
    }

    p {
      margin: 10px 0;
      color: ${lighten(0.1, '#455571')};
      text-align: center;

      svg {
        color: #f2b100;
        margin-right: 5px;
      }
    }

    a {
      margin-top: 10px;
      margin-left: auto;

      text-decoration: none;
      color: #172b4d;
      font-size: 13px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  > p {
    margin: 20px 0;

    color: rgba(0, 11, 58, 0.7);
    a {
      font-weight: 700;
      color: #000b3a;
      text-decoration: none;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
