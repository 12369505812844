/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  FiHash,
  FiMail,
  FiPhone,
  FiSmartphone,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import { FaRegBuilding, FaWhatsapp } from 'react-icons/fa';
import Switch from 'react-switch';
import Input from '../../../../Input';
import getValidationErrors from '../../../../../utils/getValidationErrors';

import { IState } from '../../../../../store';
import {
  ICompanysState,
  ICompany,
} from '../../../../../store/modules/companys/types';

import { Container, Divisor } from './styles';
import { useToast } from '../../../../../hooks/toast';
import Select from '../../../../Select';
import api from '../../../../../services/api';
import {
  addCompanysInfo,
  closeAddCompany,
  openAddCompany,
} from '../../../../../store/modules/companys/actions';
import Button from '../../../../Button';
import {
  addUsersInfo,
  closeAproveUsers,
} from '../../../../../store/modules/users/actions';
import { IUsersInfoState } from '../../../../../store/modules/users/types';
import Modal from '../../../../Modal';
import AddCompany from '../../Company/AddCompany';
import { Flex } from '@chakra-ui/react';

interface AproveUserFormData {
  id: string;
  data_id: string;
  active: boolean;
  name: string;
  email: string;
  company_name: string;
  mobile_phone: string;
  whatsapp: boolean;
  phone?: string;
  group?: number;
}

interface inferfaceAproveUser {
  usersHeaderRef: React.RefObject<HTMLDivElement>;
}

function AproveUser({usersHeaderRef}: inferfaceAproveUser) {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const [switchStatus, setSwitchStatus] = useState(false);
  const dispatch = useDispatch();

  const switchToggle = (): void => {
    setSwitchStatus(!switchStatus);
  };

  const handleAddCompanyModal = useCallback(async () => {
    await dispatch(closeAddCompany());
    await dispatch(openAddCompany());
  }, []);

  // const companyData = useSelector<IState, ICompanysState>(
  //   state => state.companysInfo,
  // );

  // useEffect(() => {
  //   setAddCompany(companyData.addCompany);
  // }, [companyData]);

  const handleSubmit = useCallback(
    async (data: AproveUserFormData): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        data.whatsapp = switchStatus;

        if (data.phone === '') {
          // eslint-disable-next-line no-param-reassign
          delete data.phone;
        }

        if (data.group === undefined) {
          delete data.group;
        }

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail valido'),
          name: Yup.string().required('Nome Obrigatório'),
          mobile_phone: Yup.string().required('Celular/Whatsapp Obrigátorio'),
          phone: Yup.string().notOneOf(
            [Yup.ref('mobile_phone')],
            'Os números de telefone não podem ser iguais',
          ),
          company_id: Yup.string()
            .uuid('Selecione uma empresa')
            .required('Selecione uma empresa 2'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .put(`/users/aprove-user`, data)
          .then(async function aproveUser() {
            await api.get(`/users/list-users?permission=100`).then(users => {
              dispatch(addUsersInfo(users.data));
            });

            dispatch(closeAproveUsers());

            addToast({
              type: 'success',
              title: 'usuário aprovado',
            });
          });

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let description;

          if (
            errors.company_id === 'Selecione uma empresa' ||
            errors.company_id === 'company_id must be a valid UUID'
          ) {
            description = 'Você precisa selecionar uma empresa';
          }

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }
      } finally {
        setLoading(false);
      }
    },

    [addToast, dispatch, switchStatus],
  );

  useEffect(() => {
    const profileLoad = async (): Promise<void> => {
      await api.get(`/company`).then(response => {
        dispatch(addCompanysInfo(response.data));

        setLoading(false);
      });
    };

    profileLoad();
  }, [addToast, dispatch]);

  const companysData = useSelector<IState, ICompanysState>(
    state => state.companysInfo,
  );

  const { userEditProfile, aprovingUser } = useSelector<
    IState,
    IUsersInfoState
  >(state => state.usersInfo);

  useEffect(() => {
    userEditProfile && setSwitchStatus(userEditProfile.whatsapp);
  }, [userEditProfile]);

  return (
    <>
      {!aprovingUser ? (
        <Container>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h3> Empresa </h3>

            <Divisor>
              <div className="double">
                <Select
                  icon={FaRegBuilding}
                  id={`companyfromuser${userEditProfile?.id}`}
                  name="company_id"
                >
                  <option>Escolha uma empresa</option>
                  {companysData.companys?.map(function companysMaping(
                    obj: ICompany,
                  ) {
                    return (
                      <option key={obj.id} value={obj.id}>
                        {obj.name}
                      </option>
                    );
                  })}
                </Select>
                <button
                  className="new-company"
                  type="button"
                  onClick={handleAddCompanyModal}
                >
                  + Nova Empresa
                </button>
              </div>
            </Divisor>

            <h3 className="mt-40"> Usuário </h3>

            <Input
              name="user_id"
              icon={FiHash}
              placeholder="id"
              value={userEditProfile?.id}
              display={false}
              readOnly
            />
            <Input
              name="company_name"
              icon={FaRegBuilding}
              placeholder="Nome da empresa"
              value={userEditProfile?.company_name}
              display={false}
              readOnly
            />

            <Divisor>
              <div className="double">
                <Input
                  name="name"
                  mask="name"
                  icon={FiUser}
                  placeholder="Nome"
                  defaultValue={userEditProfile?.name}
                />
              </div>
              <div className="double">
                <Input
                  name="email"
                  icon={FiMail}
                  placeholder="E-mail"
                  defaultValue={userEditProfile?.email}
                />
              </div>
            </Divisor>
            <Divisor>
              <div className="double">
                <Input
                  name="phone"
                  mask="phone"
                  defaultValue={userEditProfile?.phone}
                  icon={FiPhone}
                  placeholder="Telefone"
                />
              </div>
              <div className="double">
                <Input
                  name="mobile_phone"
                  mask="phone"
                  defaultValue={
                    userEditProfile?.mobile_phone === '-'
                      ? ''
                      : userEditProfile?.mobile_phone
                  }
                  icon={switchStatus ? FaWhatsapp : FiSmartphone}
                  placeholder={switchStatus ? 'Whatsapp' : 'Celular'}
                />
                <div className="whatsappField">
                  <span>Esse numero é whatsapp?</span>
                  <Switch
                    onColor="#52c75d"
                    onChange={() => switchToggle()}
                    checked={!!switchStatus}
                  />
                </div>
              </div>
            </Divisor>
            <Divisor>
              <div className="double">
                <Select name="group" icon={FiUsers}>
                  <option value={0}>Escolha um grupo</option>
                  <option value={1}>Grupo 1</option>
                </Select>
              </div>
            </Divisor>

            <Divisor className="modal-form_button-divisor">
              <Button loading={loading} type="submit">
                Aprovar
              </Button>
            </Divisor>
          </Form>



        </Container>
      ) : (
        ''
      )}
    </>
  );
};

export default AproveUser;
