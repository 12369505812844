/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import ReactTooltip from 'react-tooltip';
import { Link, useHistory } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import SearchImage from '../../../../assets/illustrations/not_found.png';

import { formatPrice } from '../../../../utils/format';

import { Content } from './styles';

import 'react-tabs/style/react-tabs.css';

import Dashboard from '../../../../components/Dashboard';
import { IState } from '../../../../store';
import TableLoading from '../../../../components/Table/Loading';
import api from '../../../../services/api';
import { addListInfo } from '../../../../store/modules/list/actions';
import { ICartState, item } from '../../../../store/modules/cart/types';
import ProductCartOptions from '../../../../components/ProductCartOptions';
import { IUserInfoState } from '../../../../store/modules/user/types';
import SimpleButton from '../../../../components/SimpleButton';
import {
  cleanCart,
  updateProductToCartSuccess,
} from '../../../../store/modules/cart/actions';

interface ProductsData {
  product_id: string;
  qtd: number;
}

interface OrderProductsData {
  products: ProductsData[];
}

const Cart: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const [tableLoad, setTableLoad] = useState<boolean>(true);

  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>(true);
  const [descontoPromocionalProdutos, setDescontoPromocionalProdutos] =
    useState<any>(true);
  const [descontoClienteProdutos, setDescontoClienteProdutos] =
    useState<any>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [valorTotalVivos, setValorTotalVivos] = useState<any>(true);
  const [OrderProducts, setOrderProducts] = useState<any>({ products: [] });
  const [descontoPromocionalVivos, setDescontoPromocionalVivos] =
    useState<any>(true);
  const [valorFinalProdutos, setValorFinalProdutos] = useState<any>(true);
  // const [totalDicsValue, setTotalDicsValue] = useState<any>(true);
  const [descontoClienteVivos, setDescontoClienteVivos] = useState<any>(true);
  const [valorFinalVivos, setValorFinalVivos] = useState<any>(true);

  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);

  useEffect(() => {
    setTableLoad(true);

    api.get(`/list/available`).then(response => {
      dispatch(addListInfo(response.data));
      setTableLoad(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const activeUsersList = usersList.filter();

  const cartItem = useSelector<IState, item[]>(state => state.cart.items);

  const totalValue = formatPrice(
    cartItem
      .map(items => {
        return items;
      })
      .reduce(
        (total: any, itemInfo: any) =>
          total +
          Number(itemInfo.product.discount_value || itemInfo.product.value) *
            itemInfo.quantidade,
        0,
      ),
  );

  const cart = useSelector<IState, ICartState>(state => {
    return state.cart;
  });

  const handleUpdateCartItem = useCallback(
    (productInfo, qtd) => {
      dispatch(updateProductToCartSuccess(productInfo, qtd));
    },
    [dispatch],
  );

  // useEffect(() => {
  //   const reviewCart = cart.items.map(async (item: any) => {
  //     try {
  //       await api.get(`/list/product?id=${item.product.id}`).then(
  //         response => {
  //           // console.log('consoles de teste')
  //           if (item.quantidade > response.data.qtd) {
  //             item.quantidade = response.data.qtd
  //             handleUpdateCartItem
  //             // console.log(item.quantidade)
  //             return
  //           }
  //         }
  //       )
  //     } catch {
  //       // console.log('não chamou a api')
  //     }
  //   })
  // } ,[])

  const handleSubmit = useCallback(
    async (data: OrderProductsData): Promise<void> => {
      try {
        setLoading(true);

        await api.post(`/order`, data).then(response => {
          // // console.log(response.data);
          history.push(
            `/dashboard/order-detail?id=${response.data.id}&new=true`,
          );
          toast.success(`Pedido feito com sucesso!`);
          dispatch(cleanCart());
        });

        return;
      } catch (err) {
        cart.items.map(async (thisCartItem: any) => {
          try {
            await api
              .get(`/list/product?id=${thisCartItem.product.id}`)
              .then(response => {
                if (!response.data) {
                  handleUpdateCartItem(thisCartItem.product.id, 0);
                  toast.error(
                    `Um dos seus produtos não está mais na lista. Revise o pedido e confirme novamente`,
                    {
                      autoClose: 15000,
                    },
                  );
                }
                if (thisCartItem.quantidade > response.data.qtd) {
                  handleUpdateCartItem(
                    thisCartItem.product.id,
                    response.data.qtd,
                  );
                  toast.warning(
                    `Um dos produtos do carrinho sofreu alteração! Confirme as mudanças e faça o pedido novamente`,
                    {
                      autoClose: 15000,
                    },
                  );
                }
              });
          } catch (error) {
            handleUpdateCartItem(thisCartItem.product.id, 0);
            toast.error(
              `Um dos seus produtos não está mais na lista. Revise o pedido e confirme novamente`,
              {
                autoClose: 15000,
              },
            );
          }
        });

        return;
      } finally {
        setLoading(false);
      }
    },

    [dispatch, history],
  );

  useEffect(() => {
    const vivosInfo = cartItem
      .filter(isVivos => {
        if (isVivos.product.type < 4) {
          return true;
        }

        return false;
      })
      .map(vivos => {
        return {
          name: vivos.product.name,
          value: vivos.product.value,
          discount_value: vivos.product.discount_value,
          quantidade: vivos.quantidade,
        };
      });

    setValorTotalVivos(
      vivosInfo.reduce(
        (total: any, valor: any) =>
          total + Number(valor.value) * valor.quantidade,
        0,
      ),
    );

    setDescontoPromocionalVivos(
      vivosInfo.reduce(
        (total: any, valor: any) =>
          total +
          (valor.discount_value
            ? Number(valor.value) - Number(valor.discount_value)
            : 0) *
            valor.quantidade,
        0,
      ),
    );

    setDescontoClienteVivos(
      vivosInfo.reduce(
        (total: any, valor: any) =>
          total +
          (valor.discount_value
            ? Number(valor.discount_value) *
              (Number(userData.user.company?.desconto || 0) / 100)
            : Number(valor.value) *
              (Number(userData.user.company?.desconto || 0) / 100)) *
            valor.quantidade,
        0,
      ),
    );

    setValorFinalVivos(
      vivosInfo.reduce(
        (total: any, valor: any) =>
          total +
          (Number(valor.discount_value || valor.value) -
            Number(valor.discount_value || valor.value) *
              (Number(userData.user.company?.desconto || 0) / 100)) *
            valor.quantidade,
        0,
      ),
    );

    const produtosInfo = cartItem
      .filter(isprodutos => {
        if (isprodutos.product.type === 4) {
          return true;
        }

        return false;
      })
      .map(produtos => {
        return {
          name: produtos.product.name,
          value: produtos.product.value,
          discount_value: produtos.product.discount_value,
          quantidade: produtos.quantidade,
        };
      });

    setValorTotalProdutos(
      produtosInfo.reduce(
        (total: any, valor: any) =>
          total + Number(valor.value) * valor.quantidade,
        0,
      ),
    );

    setDescontoPromocionalProdutos(
      produtosInfo.reduce(
        (total: any, valor: any) =>
          total +
          (valor.discount_value
            ? Number(valor.value) - Number(valor.discount_value)
            : 0) *
            valor.quantidade,
        0,
      ),
    );

    setDescontoClienteProdutos(
      produtosInfo.reduce(
        (total: any, valor: any) =>
          total +
          (valor.discount_value
            ? Number(valor.discount_value) *
              (Number(userData.user.company?.desconto || 0) / 100)
            : Number(valor.value) *
              (Number(userData.user.company?.desconto || 0) / 100)) *
            valor.quantidade,
        0,
      ),
    );

    setValorFinalProdutos(
      produtosInfo.reduce(
        (total: any, valor: any) =>
          total +
          (Number(valor.discount_value || valor.value) -
            Number(valor.discount_value || valor.value) *
              (Number(userData.user.company?.desconto || 0) / 100)) *
            valor.quantidade,
        0,
      ),
    );

    const products: any = { products: [] };
    cartItem.map(itemInfo => {
      return products.products.push({
        product_id: itemInfo.product.id,
        qtd: itemInfo.quantidade,
      });
    });

    setOrderProducts(products);

    // setTotalDicsValue(
    //   cartItem
    //     .map(items => {
    //       return items;
    //     })
    //     .reduce(
    //       (total: any, itemInfo: any) =>
    //         total +
    //         (itemInfo.product.discount_value
    //           ? (Number(itemInfo.product.value) -
    //               Number(itemInfo.product.discount_value)) *
    //             itemInfo.quantidade
    //           : 0),
    //       0,
    //     ),
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem, userData.user.company?.desconto]);

  return (
    <Dashboard>
      <Content>
        {totalValue !== 'R$ 0,00' ? (
          <>
            {tableLoad ? (
              <TableLoading />
            ) : (
              <div className="cart_content">
                <div className="cart_conatiner">
                  <h3>Carrinho</h3>
                  <div className="cart_tableBox">
                    <table className="cart_table">
                      <thead>
                        <tr>
                          <th className="cart_namemargin">Nome Popular</th>
                          <th>Tamanho</th>
                          <th>Valor Uni.</th>
                          <th>Qtd.</th>
                          <th>Parcial</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart.items.map(itemInfo => {
                          return (
                            <tr key={itemInfo.product.id}>
                              <td>
                                {itemInfo.product.image_url ? (
                                  <img
                                    className="cart_imageMargin"
                                    src={itemInfo.product.image_url}
                                    alt={itemInfo.product.name}
                                  />
                                ) : (
                                  <span className="cart_nameSpace"> </span>
                                )}
                                {itemInfo.product.name}
                              </td>
                              <td>{itemInfo.product.size}</td>
                              <td>
                                {itemInfo.product.discount_value ? (
                                  <>
                                    <del>
                                      {formatPrice(
                                        Number(itemInfo.product.value),
                                      )}
                                    </del>{' '}
                                    <span className="promo">
                                      {formatPrice(
                                        Number(itemInfo.product.discount_value),
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <span>
                                    {formatPrice(
                                      Number(itemInfo.product.value),
                                    )}
                                  </span>
                                )}
                              </td>
                              <td>
                                <ProductCartOptions
                                  productInformation={itemInfo.product}
                                />
                              </td>
                              <td>
                                <span className="noPromo">
                                  {formatPrice(
                                    itemInfo.product.discount_value
                                      ? Number(
                                          itemInfo.product.discount_value,
                                        ) * itemInfo.quantidade
                                      : Number(itemInfo.product.value) *
                                          itemInfo.quantidade,
                                  )}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="cart_doubleDivider">
                    <div>
                      <h3 className="cart_titleMargin">Resumo de Vivos</h3>
                      <div className="cart_tableBox cart_tableBox-padding cart_width-auto">
                        {valorTotalVivos === 0 ? (
                          <>Não existem Vivos no carrinho.</>
                        ) : (
                          <ul>
                            <li>
                              <span>Valor dos vivos</span>{' '}
                              <span>{formatPrice(valorTotalVivos)}</span>
                            </li>
                            <li className="cart_listPromo">
                              <span>Desconto promocional</span>{' '}
                              <span>
                                - {formatPrice(descontoPromocionalVivos)}
                              </span>
                            </li>
                            <li className="cart_listPromo">
                              <span>
                                Desconto do cliente (
                                {userData.user.company?.desconto}%)
                              </span>{' '}
                              <span>- {formatPrice(descontoClienteVivos)}</span>
                            </li>
                            <li>
                              <span>Valor a ser pago</span>{' '}
                              <span>{formatPrice(valorFinalVivos)}</span>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>

                    <div>
                      <h3 className="cart_titleMargin">
                        Resumo de Produtos (não vivos)
                      </h3>
                      <div className="cart_tableBox cart_tableBox-padding cart_width-auto">
                        {valorTotalProdutos === 0 ? (
                          <>Não existem produtos (não vivos) no carrinho.</>
                        ) : (
                          <ul>
                            <li>
                              <span>Valor dos Produtos</span>{' '}
                              <span>{formatPrice(valorTotalProdutos)}</span>
                            </li>
                            <li className="cart_listPromo">
                              <span>Desconto promocional</span>{' '}
                              <span>
                                - {formatPrice(descontoPromocionalProdutos)}
                              </span>
                            </li>
                            <li className="cart_listPromo">
                              <span>
                                Desconto do cliente (
                                {userData.user.company?.desconto}%)
                              </span>{' '}
                              <span>
                                - {formatPrice(descontoClienteProdutos)}
                              </span>
                            </li>
                            <li>
                              <span>Valor a ser pago</span>{' '}
                              <span>{formatPrice(valorFinalProdutos)}</span>
                            </li>
                          </ul>
                        )}
                      </div>

                      <div className="cart_width100">
                        <h3 className="cart_titleMargin">Finalizar pedido</h3>
                        <div className="cart_tableBox cart_tableBox-padding cart_width-auto cart_doubleContent">
                          <span className="cart_totalLabel">
                            <p>TOTAL:</p>
                          </span>
                          <span>
                            <p className="NewOrderCart_total">
                              {formatPrice(
                                valorFinalProdutos + valorFinalVivos,
                              )}
                            </p>
                            <p className="NewOrderCart_discount">
                              Economia de:{' '}
                              {formatPrice(
                                descontoPromocionalProdutos +
                                  descontoClienteProdutos +
                                  descontoPromocionalVivos +
                                  descontoClienteVivos,
                              )}
                            </p>
                          </span>
                        </div>

                        <div className="cart_finalButton">
                          {valorFinalProdutos + valorFinalVivos < 100 ? (
                            <span className="cartButon_alert">
                              *O valor Total deve ser maior que R$ 1200,00
                            </span>
                          ) : (
                            ' '
                          )}
                          <div className="cart_finalButonBox">
                            <Form
                              ref={formRef}
                              onSubmit={() => handleSubmit(OrderProducts)}
                            >
                              <SimpleButton
                                type="submit"
                                loading={loading}
                                disabled={
                                  valorFinalProdutos + valorFinalVivos < 100
                                }
                                className="cart_finalButtonButton"
                              >
                                <FiCheck /> Fazer Pedido
                              </SimpleButton>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cart_doubleDivider">
                    <div />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="cart_content">
              <div>
                <h2>
                  Aguardando produtos... <br /> Dá um pulinho lá em{' '}
                  <Link to="/dashboard/new-order" className="cartLink">
                    Novo pedido
                  </Link>
                  .
                </h2>
                <img
                  src={SearchImage}
                  className="image_empityCart"
                  alt="Aguardando novos pedidos"
                />
              </div>
            </div>
          </>
        )}
      </Content>
      {/* <ReactTooltip html /> */}
    </Dashboard>
  );
};

export default Cart;
