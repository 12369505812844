import React from 'react';
// import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import WaitingImage from '../../assets/illustrations/esperando.png';

import logoHub from '../../assets/logo-hub.svg';

import GlobalStyle, { Container, Content } from './styles';

const PendentActivation: React.FC = () => {
  localStorage.removeItem('@Hub:user');
  localStorage.removeItem('@Hub:token');

  return (
    <>
      <Container>
        <Content>
          <img src={logoHub} alt="Hub" />

          <div>
            <h3>AGUARDANDO APROVAÇÃO...</h3>
            <p>
              Já estamos analisando a sua solicitação de cadastro, em breve você
              receberá um e-mail de contato da nossa equipe.
            </p>
            <img src={WaitingImage} alt="aguardando confirmação" />
          </div>
          <p>
            <a href="/">
              <FiArrowLeft /> Voltar ao inicio
            </a>
          </p>
        </Content>
      </Container>
      <GlobalStyle />
    </>
  );
};

export default PendentActivation;
