/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Stack } from '@chakra-ui/react';

import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { FiCheckCircle, FiTruck } from 'react-icons/fi';
import { FaRegPaperPlane } from 'react-icons/fa';
import { OrdersCard } from './OrdersCard';
import { OrdersContext } from '../../../../context/Orders';
import { OrderTitle } from './OrderTitle';
import { OrdersGraph } from './OrdersGraph';
import { OrdersTable } from './OrdersTable';

interface IMonthsName {
  [key: number]: string;
}

export function OrdersView() {
  const [pendentOrders, setPendentOrders] = useState<object[] | undefined>();
  const [sendedOrders, setSendedOrders] = useState<object[] | undefined>();
  const [deliveredOrders, setDeliveredOrders] = useState<
    object[] | undefined
  >();

  const { orders, currentMonth, currentYear } = useContext(OrdersContext);

  const monthsName: IMonthsName = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  useEffect(() => {
    setPendentOrders(
      orders?.filter((order: any) => {
        return (
          order.status === 1 &&
          new Date(order.created_at).getFullYear() === currentYear &&
          new Date(order.created_at).getMonth() === currentMonth
        );
      }),
    );
    setSendedOrders(
      orders?.filter((order: any) => {
        return (
          order.status === 2 &&
          new Date(order.created_at).getFullYear() === currentYear &&
          new Date(order.created_at).getMonth() === currentMonth
        );
      }),
    );
    setDeliveredOrders(
      orders?.filter((order: any) => {
        return (
          order.status > 2 &&
          new Date(order.created_at).getFullYear() === currentYear &&
          new Date(order.created_at).getMonth() === currentMonth
        );
      }),
    );
  }, [orders, currentMonth, currentYear]);

  return (
    <>
      <Stack p={10}>
        <OrderTitle title="Pedidos" month={monthsName[currentMonth + 1]} />
        <Grid templateColumns={['1fr', '1fr', '1fr 1fr']} gap={5}>
          <OrdersGraph monthName={monthsName[currentMonth + 1]} />
          <Grid templateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gap={5}>
            <OrdersCard
              number={pendentOrders?.length}
              title="Pedidos Pendentes"
              color="#FEA01C"
              cardIcon={HiOutlineClipboardCopy}
            />
            <OrdersCard
              number={sendedOrders?.length}
              title="Separados/Enviados"
              color="#11AEC3"
              cardIcon={FaRegPaperPlane}
            />
            <OrdersCard
              number={deliveredOrders?.length}
              title="Entregues"
              color="#45A149"
              cardIcon={FiCheckCircle}
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack p={10} pt={5}>
        <OrderTitle title="Ultimos Pedidos" />
        <OrdersTable />
      </Stack>
    </>
  );
}
