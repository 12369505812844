import styled, { createGlobalStyle, keyframes } from 'styled-components';

import logoHub from '../../../assets/sign-in-background.svg';

export default createGlobalStyle`
  body {
    background-color: #11cdef !important;
  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #11cdef;
  background: url('${logoHub}') no-repeat top center #11cdef;
  padding-top: 120px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Content = styled.div`
  max-width: 475px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  div {
    margin-top: 30px;
    min-height: 400px;
    min-width: 600px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 60px;
    border-radius: 15px;

    h3 {
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  > p {
    margin: 20px 0 50px;

    color: rgba(0, 11, 58, 0.7);
    a {
      font-weight: 700 !important;
      color: #000b3a;
      text-decoration: none;
      display: flex;
      align-items: center;
      align-content: center;
      span {
        margin-left: 10px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
