import React, { useCallback, useRef, useState } from 'react';
import { FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoHub from '../../assets/logo-hub.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';
import GlobalStyle, { Container, Content } from './styles';
import api from '../../services/api';

interface ForgotPasswordFormData {
  email: string;
  password: string;
}

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  // const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail valido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // recuperação de senha

        await api.post('/password/forgot', {
          email: data.email,
        });

        addToast({
          type: 'success',
          title: 'E-mail de recuperação enviado!',
          description:
            'Enviamos um e-mail para confirmar a recuperação de senha, cheque a sua caixa de entrada!',
        });

        setSuccess(true);

        // history.push('/dashboard');

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          let description;
          let title = 'Preencha todos os campos';

          if (errors.email === 'E-mail obrigatório') {
            title = 'Preencha todos os campos';
            description = 'O campo e-mail é obrigatório';
          } else if (errors.email === 'Digite um e-mail valido') {
            title = 'Preencha os campos corretamente';
            description = 'O campo de e-mail não parece valido';
          }

          addToast({
            type: 'alert',
            title,
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na recuperação de senha',
          description:
            'Infelizmente não localizamos este e-mail na nossa base de dados',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      <Container>
        <Content>
          <img src={logoHub} alt="Hub" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Recuperar Senha</h1>
            <p>insira o seu e-mail para recuperar a senha</p>

            <Input name="email" icon={FiMail} placeholder="E-mail" />

            <Button success={success} loading={loading} type="submit">
              {' '}
              Recuperar{' '}
            </Button>
          </Form>

          <p>
            Não deseja mais trocar a senha? <Link to="/">Voltar ao login</Link>
          </p>
        </Content>
      </Container>
      <GlobalStyle />
    </>
  );
};

export default ForgotPassword;
