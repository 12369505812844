import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const toastTypeVariations = {
  info: css`
    background: #29a1f7;
    color: #ffffff;
  `,

  success: css`
    background: #00b583;
    color: #ffffff;
  `,

  error: css`
    background: #c53030;
    color: #ffffff;
  `,

  alert: css`
    background: #ffa500;
    color: #ffffff;
  `,
};

interface ContainerProps {
  type?: 'success' | 'error' | 'alert' | 'info';
  hasdescription: number;
}

export const Container = styled(animated.div)<ContainerProps>`
  width: 360px;

  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);

  display: flex;
  z-index: 3002 !important;

  & + div {
    margin-top: 10px;
  }

  ${props => toastTypeVariations[props.type || 'info']}

  > svg {
    margin: 4px 12px 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    color: inherit;
    background: none;

    cursor: pointer;
    transition: opacity 0.4s ease;

    &:hover {
      opacity: 1;
    }
  }

  ${props =>
    !props.hasdescription &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
`;
