import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import '@trendmicro/react-datepicker/dist/react-datepicker.css';

import { ChakraProvider } from '@chakra-ui/react';
import { AnimateSharedLayout } from 'framer-motion';
import store from './store';

import GlobalStyle from './styles/global';

import AppProvider from './hooks';

import Routes from './routes';
import { ListProductsProvider } from './context/ListProducts';
import { OrdersProvider } from './context/Orders';

import { theme } from './styles/theme';

const App: React.FC = () => (
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <AppProvider>
        <ListProductsProvider>
          <OrdersProvider>
            <AnimateSharedLayout>
              <Router>
                <Routes />
              </Router>
            </AnimateSharedLayout>
          </OrdersProvider>
        </ListProductsProvider>
      </AppProvider>
    </ChakraProvider>

    <GlobalStyle />
  </Provider>
);

export default App;
