/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Icon, Flex, Text } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { IconType } from 'react-icons';

interface ordersCardProps {
  number?: number;
  title?: string;
  color?: string;
  cardIcon?: IconType;
}

export function OrdersCard({
  number,
  title,
  color,
  cardIcon,
  ...rest
}: ordersCardProps) {
  return (
    <Flex
      flexDir="column"
      w="100%"
      minH="150px"
      bg="white"
      p="10px"
      pb="20px"
      borderRadius="10px"
      boxShadow="lg"
      alignItems="center"
      justifyContent="space-between"
      opacity={number && number > 0 ? 1 : 0.5}
    >
      <Flex justifyContent="space-between" minW="120px" w="100%">
        <Flex
          padding="13px"
          bg={color && number && number > 0 ? color : 'gray.400'}
          color="white"
          fontSize={30}
          borderRadius="full"
        >
          <Icon as={cardIcon ?? FiInfo} />
        </Flex>
        <Text
          color={color && number && number > 0 ? color : 'gray.400'}
          fontSize={31}
        >
          {number ?? '00'}
        </Text>
      </Flex>
      <Text textAlign="center" fontSize={13}>
        {title ?? 'não definido'}
      </Text>
    </Flex>
  );
}
