/* eslint-disable import/no-duplicates */
import MUIDataTable from 'mui-datatables';
import React, { useContext, useEffect, useState } from 'react';
import { FiPlayCircle } from 'react-icons/fi';
import { format, isBefore, addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { utcToZonedTime } from 'date-fns-tz';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ReactTooltip from 'react-tooltip';

import { Box, Text } from '@chakra-ui/react';
import { isAfter } from 'date-fns/esm';
import { useSelector } from 'react-redux';
import api from '../../../../services/api';

import Dashboard from '../../../../components/Dashboard';
import ActionButton from '../../../../components/Table/ActionButton';
import TableLoading from '../../../../components/Table/Loading';
import { Content } from './styles';

import { options, columns } from './tableConfig';
import { Delivery } from '../../../../components/Dashboard/Admin/Orders/Delivery';
import { OrdersContext } from '../../../../context/Orders';
import { IState } from '../../../../store';
import { IUserInfoState } from '../../../../store/modules/user/types';
import { HiEye } from 'react-icons/hi';

// interface transportadoraProps {
//   id: number;
//   name: string
// }

const Release: React.FC = () => {
  const [tableLoad, setTableLoad] = useState<boolean>(false);
  const [math, setMath] = useState(0);
  const [liberacaoPendente, setLiberacaoPendente] = useState([]);
  const [envioPendente, setEnvioPendente] = useState([]);
  const [enviado, setEnviado] = useState([]);
  const [historicoEnviado, setHistoricoEnviado] = useState([]);
  // const [transportadoras, setTransportadoras] = useState<transportadoraProps[]>();

  const { orderUpdateCount, updateOrderCount } = useContext(OrdersContext);
  const userData = useSelector<IState, IUserInfoState>(state => state.userInfo);
  const columnsConfig = [
    columns.id,
    columns.number,
    columns.autor,
    columns.feitoEm,
    {
      name: 'Forma Pag.',
      options: {
        display: !!(userData.user.permission >= 3 && !userData.loading),
        filter: !!(userData.user.permission >= 3 && !userData.loading),
        draggable: !!(userData.user.permission >= 3 && !userData.loading),
        searchable: !!(userData.user.permission >= 3 && !userData.loading),
        viewColumns: !!(userData.user.permission >= 3 && !userData.loading),
        sort: !!(userData.user.permission >= 3 && !userData.loading),
      },
    },
    columns.atualizacao,
    {
      name: '',
      options: {
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div className="table-icons">
              <Link
                to={`/admin/release/${tableMeta.rowData[0]}`}
                data-tip="Liberar Pedido"
              >
                <ActionButton color="#00c251">
                  <FiPlayCircle />
                </ActionButton>
              </Link>

              <ReactTooltip html />
            </div>
          );
        },
      },
    },
  ];

  const columnsDeliveryConfig = [
    columns.id,
    columns.number,
    columns.autor,
    columns.feitoEm,
    columns.atualizacao,
    {
      name: '',
      options: {
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div className="table-icons">
              {/* <Link
                to={`/admin/release/${tableMeta.rowData[0]}`}
                data-tip="Registrar Envio"
              >
                <ActionButton color="#ff9900">
                </ActionButton>
              </Link> */}

              <Box>
                <Delivery
                  data={{
                    id: tableMeta.rowData[0],
                    autor: tableMeta.rowData[2],
                  }}
                />
              </Box>

              <ReactTooltip html />
            </div>
          );
        },
      },
    },
  ];

  const columnsDeliveredConfig = [
    columns.id,
    columns.number,
    columns.autor,
    columns.feitoEm,
    columns.atualizacao,
    columns.transporte,
    {
      name: '',
      options: {
        filter: false,
        draggable: false,
        searchable: false,
        viewColumns: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div className="table-icons">
              <Link
                to={`/admin/order-detail?id=${tableMeta.rowData[0]}`}
                data-tip="Ver Pedido"
              >
                <ActionButton color="#bbb">
                  <HiEye />
                </ActionButton>
              </Link>

              <ReactTooltip html />
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    async function loadLists(): Promise<void> {
      setTableLoad(true);

      await api.get(`/order`).then(response => {
        const allOrders = response.data;

        const tablePendent = allOrders.filter(
          (order: any) => order.status === 1,
        );
        const tableDeliveryPendent = allOrders.filter(
          (order: any) => order.status === 2,
        );
        const tableDelivered = allOrders.filter(
          (order: any) =>
            !!(
              order.status === 3 &&
              isAfter(addDays(new Date(order.updated_at), 10), new Date())
            ),
        );
        const tableDeliveredHistory = allOrders.filter(
          (order: any) =>
            !!(
              order.status === 3 &&
              isBefore(addDays(new Date(order.updated_at), 10), new Date())
            ),
        );

        // allOrders.map((order: any) => {
        //   // console.log(order.updated_at)
        //   // console.log(addDays(new Date(order.updated_at), 10))
        //   // console.log(order.status)
        //   // console.log(isBefore(addDays(new Date(order.updated_at), 10), new Date()))
        // })

        const tablePendentContent = tablePendent.map(function pendentOrder(
          order: any,
        ) {
          const timeZone = 'America/Sao_Paulo';
          const orderDate = format(
            utcToZonedTime(order.created_at, timeZone),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );

          const orderUpdatedDate = format(
            utcToZonedTime(order.updated_at, timeZone),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );

          const orderNumber = order.order_number;
          const rest = 5 - String(orderNumber).length;

          return [
            order.id,
            '0'.repeat(rest > 0 ? rest : 0) + order.order_number,
            order.user_info.company?.name,
            orderDate,
            order.tipo_pagamento_info?.name,
            orderUpdatedDate,
          ];
        });

        const tableDeliveryPendentContent = tableDeliveryPendent.map(
          function pendentOrder(order: any) {
            const timeZone = 'America/Sao_Paulo';
            const orderDate = format(
              utcToZonedTime(order.created_at, timeZone),
              "dd'/'MM'/'yyyy' às ' HH:mm'h'",
              {
                locale: ptBR,
              },
            );

            const orderUpdatedDate = format(
              utcToZonedTime(order.updated_at, timeZone),
              "dd'/'MM'/'yyyy' às ' HH:mm'h'",
              {
                locale: ptBR,
              },
            );

            const orderNumber = order.order_number;
            const rest = 5 - String(orderNumber).length;

            return [
              order.id,
              '0'.repeat(rest > 0 ? rest : 0) + order.order_number,
              order.user_info.company?.name,
              orderDate,
              orderUpdatedDate,
            ];
          },
        );

        const tableDeliverdContent = tableDelivered.map(function pendentOrder(
          order: any,
        ) {
          const timeZone = 'America/Sao_Paulo';
          const orderDate = format(
            utcToZonedTime(order.created_at, timeZone),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );

          const orderUpdatedDate = format(
            utcToZonedTime(order.updated_at, timeZone),
            "dd'/'MM'/'yyyy' às ' HH:mm'h'",
            {
              locale: ptBR,
            },
          );

          const orderNumber = order.order_number;
          const rest = 5 - String(orderNumber).length;

          return [
            order.id,
            '0'.repeat(rest > 0 ? rest : 0) + order.order_number,
            order.user_info.company?.name,
            orderDate,
            orderUpdatedDate,
            <Text>
              {order.transportadora_info.name}{' '}
              <Text hidden={!order.rastreio} color="#11cdef" as="span">
                {order.rastreio}
              </Text>{' '}
            </Text>,
          ];
        });

        const tableDeliverdHistoryContent = tableDeliveredHistory.map(
          function pendentOrder(order: any) {
            const timeZone = 'America/Sao_Paulo';
            const orderDate = format(
              utcToZonedTime(order.created_at, timeZone),
              "dd'/'MM'/'yyyy' às ' HH:mm'h'",
              {
                locale: ptBR,
              },
            );

            const orderUpdatedDate = format(
              utcToZonedTime(order.updated_at, timeZone),
              "dd'/'MM'/'yyyy' às ' HH:mm'h'",
              {
                locale: ptBR,
              },
            );

            const orderNumber = order.order_number;
            const rest = 5 - String(orderNumber).length;

            return [
              order.id,
              '0'.repeat(rest > 0 ? rest : 0) + order.order_number,
              order.user_info.company?.name,
              orderDate,
              orderUpdatedDate,
              <Text>
                {order.transportadora_info.name}{' '}
                <Text hidden={!order.rastreio} color="#11cdef" as="span">
                  {order.rastreio}
                </Text>{' '}
              </Text>,
            ];
          },
        );

        setMath(math + 1);
        setLiberacaoPendente(tablePendentContent);
        setEnvioPendente(tableDeliveryPendentContent);
        setEnviado(tableDeliverdContent);
        setHistoricoEnviado(tableDeliverdHistoryContent);
      });
    }
    loadLists();

    setTableLoad(false);
  }, [orderUpdateCount]);

  return (
    <Dashboard>
      <Content>
        {tableLoad ? (
          <TableLoading />
        ) : (
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab> Liberação Pendente</Tab>
              <Tab> Envio Pendente</Tab>
              <Tab> Enviado </Tab>
              {userData.user.permission >= 3 && !userData.loading && (
                <Tab> Histórico </Tab>
              )}
            </TabList>

            <TabPanel>
              <MUIDataTable
                title=""
                data={liberacaoPendente}
                columns={columnsConfig}
                options={options}
              />
            </TabPanel>
            <TabPanel>
              <MUIDataTable
                title=""
                data={envioPendente}
                columns={columnsDeliveryConfig}
                options={options}
              />
            </TabPanel>
            <TabPanel>
              <MUIDataTable
                title=""
                data={enviado}
                columns={columnsDeliveredConfig}
                options={options}
              />
            </TabPanel>
            <TabPanel>
              <MUIDataTable
                title=""
                data={historicoEnviado}
                columns={columnsDeliveredConfig}
                options={options}
              />
            </TabPanel>
          </Tabs>
        )}
      </Content>
    </Dashboard>
  );
};

export default Release;
