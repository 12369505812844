import styled from 'styled-components';

interface OrderBoxProps {
  type?: string;
}

interface OrdersProps {
  reveal?: number;
}

export const Container = styled.div`
  max-width: 100%;
  background-color: #000b3a;
  padding: 20px 0px;

  h2 {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-left: 40px;
  }
`;

export const ToggleButton = styled.button`
  color: #11cdef;
  border: 1px solid #11cdef;
  background: none;
  padding: 2px 15px;
  border-radius: 4px;
  margin-left: 10px;
`;

export const Orders = styled.div<OrdersProps>`

  .slick-track{
     margin-left: 0;
      }

  a {
    text-decoration: none !important;
  }
  transition: max-height 0.6s linear, padding 0.6s linear;

  max-height: ${props => (props.reveal ? '500px' : '0px')};
  /* display: flex; */
  max-width: 100%;
  flex-wrap: nowrap;
  /* overflow-x: auto; */
  align-content: center;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-bottom: 20px;
  margin: 0 20px;
  padding: ${props => (props.reveal ? '0 30px 35px 30px' : '0 40px 0px 20px')};

  div {
    transition: opacity 1s linear;
    opacity: ${props => (props.reveal ? '1' : '0')};
  }

  /* width */
  &::-webkit-scrollbar {
    transition: width 0.3s linear;
    transition-delay: 1s;
    width: ${props => (props.reveal ? '100%' : '0px')};
    margin-top: 20px;
    height: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff30;
    border-radius: 50px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ffffff80;
    border-radius: 50px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
`;

export const OrderBox = styled.div`
  background-color: #fff;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  padding: 15px 20px 15px 20px;
  min-width: 300px;
`;

export const OrderInfo = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 22px;
`;
export const OrderDescription = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;

  p {
    color: #777;
    margin-bottom: 5px;
  }

  h3 {
    font-weight: 600;
    color: #333;
  }
`;

export const OrderIcon = styled.div<OrderBoxProps>`
  height: 60px;
  width: 60px;
  background-color: ${props => (props.type ? props.type : '#ccc')};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
    font-size: 32px;
  }
`;

export const OrderProgress = styled.div<OrderBoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 12px;
    color: #777;
    line-height: 17px;

    display: flex;
    align-content: center;

    svg {
      color: ${props => (props.type ? props.type : '#ccc')};
      font-size: 15px;
      margin-right: 5px;
    }

    span {
      color: ${props => (props.type ? props.type : '#ccc')};
      margin: 0 4px;
    }
  }

  progress[value] {
    margin-top: 7px;
    height: 7px;
    width: 100% !important;
    border: none;
  }

  progress[value]::-webkit-progress-bar {
    height: 7px;
    width: 100% !important;
    border-radius: 100px;
    border: none;
    background-color: #ddd !important;
  }

  progress[value]::-webkit-progress-value {
    border-radius: 100px;
    border: none;
    background-color: ${props => (props.type ? props.type : '#ccc')} !important;
  }
`;
