import React, { LiHTMLAttributes, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import GlobalStyle, { Container, LeftSide, RightSide } from './styles';

import Menu from './Menu';
import Header from './Header';
import api from '../../services/api';
import { addUserInfo, removeUserInfo } from '../../store/modules/user/actions';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import 'react-toastify/dist/ReactToastify.css';

const DefaultUserDashboard: React.FC<LiHTMLAttributes<HTMLLIElement>> = ({
  children,
}) => {
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const { signOut } = useAuth();

  const dispatch = useDispatch();

  const path = window.location.pathname;
  const pageLink = path.split('/')[1];

  useEffect(() => {
    const profileLoad = async (): Promise<void> => {
      try {
        await api.get(`/profile`).then(response => {
          dispatch(addUserInfo(response.data));
          const userProfile = response.data;
          if (
            userProfile.permission === 1 &&
            pageLink !== 'dashboard' &&
            pageLink !== 'pendent-activation'
          ) {
            history.push('/dashboard');
            addToast({
              type: 'error',
              title: 'Você não tem permissão para acessar esta pagina!',
            });
          }

          if (
            userProfile.permission > 1 &&
            pageLink !== 'admin' &&
            pageLink !== 'pendent-activation'
          ) {
            history.push('/admin');
          }

          if (!userProfile.active && pageLink !== 'pendent-activation') {
            localStorage.removeItem('@Hub:token');
            localStorage.removeItem('@Hub:user');
            dispatch(removeUserInfo());

            history.push('/pendent-activation');
          }

          setLoading(false);
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Sua sessão expirou, faça login novamente!',
        });
        signOut();
        history.push('/');
      }
    };

    profileLoad();
  }, [addToast, dispatch, history, pageLink, signOut]);

  return (
    <Container>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
      <LeftSide>
        <Menu />
      </LeftSide>
      <RightSide>
        <Header />
        {loading ? <div className="loading-container" /> : children}
      </RightSide>

      <GlobalStyle />
    </Container>
  );
};
export default DefaultUserDashboard;
