/* eslint-disable no-nested-ternary */
import { Box, Tooltip } from '@chakra-ui/react';
import React, { LiHTMLAttributes, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import { motion } from 'framer-motion';

type LinkProps = LiHTMLAttributes<HTMLLIElement> & {
  current?: string;
  text?: string;
  link: string;
  color?: string;
  // icon?: string;
};

const MotionBox = motion(Box)


const MenuButton: React.FC<LinkProps> = ({
  children,
  current,
  link,
  text,
  color,
  id,
  // icon,
  ...rest
}) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {

    if (localStorage.getItem("@Hub:toggleUserMenuStatus",) === 'false') {
      // console.log('entrou no false')
      setIsMenuOpen(false)
      return
    }
    // console.log(localStorage.getItem("@Hub:toggleUserMenuStatus",))
    setIsMenuOpen(true)

  }, [localStorage.getItem("@Hub:toggleUserMenuStatus",)])

  return (

  <Tooltip
            label={text ?? id}
            isDisabled={!isMenuOpen}
            bg="black"
            hasArrow
            placement="left"
            p={2}
            borderRadius="5px"
            color="white"
          >
      <Box>
  <Container color={color} current={Number(current?.toUpperCase() === id?.toUpperCase())} {...rest}>

    {current?.toUpperCase() === id?.toUpperCase() &&
      <MotionBox
        layoutId="menuPin"
        width="3px"
        height="45%"
        left="-1px"
        zIndex="2"
        borderRadius="2px 0 0 2px"
        position="absolute"
        bg={color}
      >
      </MotionBox>
    }
    <Link to={link}>{children}</Link>
  </Container>

  </Box>
  </Tooltip>
)};

export default MenuButton;
