/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  AllHTMLAttributes,
  useContext,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { useDispatch } from 'react-redux';
import { FiArrowLeft, FiBox, FiCheckCircle, FiCopy } from 'react-icons/fi';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { MdDescription } from 'react-icons/md';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { Container, Divisor } from './styles';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import SimpleButton from '../../../../SimpleButton';
import Modal from '../../../../Modal';
import Input from '../../../../Input';
import { addListInfo } from '../../../../../store/modules/list/actions';
import { addSpecificListInfo } from '../../../../../store/modules/specificList/actions';
import { ListProductsContext } from '../../../../../context/ListProducts';

type IModal = AllHTMLAttributes<HTMLAllCollection> & {
  modalStatus: boolean;
  listId: string;
};

const AddListProduct: React.FC<IModal> = ({ modalStatus, listId }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const { generateListProducts } = useContext(ListProductsContext);

  const [tableLoad, setTableLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(true);
  const [allProducts, setAllProducts] = useState<any>();
  const [inputSelected, setInputSelected] = useState([]);
  const [productSelected, setProductSelected] = useState<any>();
  const [inputFirst, setInputFirst] = useState(0);

  // const startFormatedTime = format(new Date(), 'HH:mm:ss');

  // const endDate = parseISO(listInfo.end);

  const dispatch = useDispatch();

  useEffect(() => {
    async function loadProducts(): Promise<void> {
      await api.get('/product').then(async response => {
        setAllProducts(response.data);
      });
    }

    loadProducts();
  }, []);

  useEffect(() => {
    const modalChange = async (): Promise<void> => {
      if (modalStatus) {
        await setModal(true);
        await setModal(false);
      }
    };

    modalChange();
  }, [modalStatus]);

  const handleFormSubmit = useCallback(
    async (data): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Você precisa informar um nome'),
          valor: Yup.string().required('Você precisa informar um valor'),
          qtd: Yup.number()
            .typeError('Informe uma quantidade valida')
            .required('Você precisa informar uma quantidade'),
        });

        let valor = data.valor.replace(/\./g, '-');
        valor = valor.replace(/,/g, '.');
        valor = valor.replace(/-/g, '');

        let valor_desconto = data.valor_desconto.replace(/\./g, '-');
        valor_desconto = valor_desconto.replace(/,/g, '.');
        valor_desconto = valor_desconto.replace(/-/g, '');

        valor_desconto =
          Number(valor_desconto) < 1 ? null : Number(valor_desconto);

        await schema.validate(data, {
          abortEarly: false,
        });

        api.post('/list/product', {
          name: data.name,
          product_id: data.productId,
          list_id: data.listId,
          qtd: data.qtd,
          descricao: data.descricao,
          valor: Number(valor),
          valor_desconto,
        });

        addToast({
          type: 'success',
          title: 'Produto adicionado',
        });

        await api.get(`/list?id=${data.listId}`).then(response => {
          dispatch(addSpecificListInfo(response.data));
          generateListProducts(response.data);
        });

        setModal(true);
        setTableLoad(false);
        setTableLoad(true);
        setProductSelected(undefined);
        setInputSelected([]);
        window.location.reload();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let description;

          if (errors.name) {
            description = errors.name;
          }

          if (errors.valor) {
            description = errors.valor;
          }

          if (errors.qtd) {
            description = errors.qtd;
          }

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, dispatch],
  );

  const handleInputChange = useCallback(
    async (value, productsList): Promise<void> => {
      setInputFirst(value.length);
      const productsFilter = productsList.filter((obj: any) => {
        if (
          obj.name &&
          obj.name?.toUpperCase().indexOf(value.toUpperCase()) >= 0
        ) {
          return true;
        }

        if (
          obj.family &&
          obj.family?.toUpperCase().indexOf(value.toUpperCase()) >= 0
        ) {
          return true;
        }

        if (
          obj.scienrific_name &&
          obj.scienrific_name?.toUpperCase().indexOf(value.toUpperCase()) >= 0
        ) {
          return true;
        }

        return false;
      });

      setInputSelected(productsFilter);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (data): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome do lote é obrigatório'),
          startDate: Yup.string().required('A Data de Inicio é obrigatória'),
          endDate: Yup.string().required('A Data de termino é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const startTimeSplit = data.startTime.split(':');
        const endTimeSplit = data.endTime.split(':');

        if (!data.startTime) {
          data.startTime = '00:00';
        }

        if (!data.endTime) {
          data.endTime = '00:00';
        }

        const sSDate = data.startDate.split('/');
        const sEDate = data.endDate.split('/');

        if (
          Number(sSDate[0]) > 31 ||
          Number(sSDate[1] > 12) ||
          Number(sEDate[0]) > 31 ||
          Number(sEDate[1] > 12)
        ) {
          addToast({
            type: 'alert',
            title: `Informe uma data válida`,
          });
          throw new Error('erro de data');
        }

        if (
          Number(startTimeSplit[0]) > 23 ||
          Number(startTimeSplit[1] > 59) ||
          Number(endTimeSplit[0]) > 23 ||
          Number(endTimeSplit[1] > 59)
        ) {
          addToast({
            type: 'alert',
            title: `Informe um horário valido`,
          });
          throw new Error('erro de tempo');
        }

        const finalStartDate = sSDate[2].concat(
          '-',
          sSDate[1],
          '-',
          sSDate[0],
          ' ',
          data.startTime,
          ':00',
        );

        const finalEndDate = sEDate[2].concat(
          '-',
          sEDate[1],
          '-',
          sEDate[0],
          ' ',
          data.endTime,
          ':00',
        );

        const apiRequestBody = {
          id: data.id,
          name: data.name,
          start: finalStartDate,
          end: finalEndDate,
          groups: data.groups,
        };

        await api.put(`/list`, apiRequestBody).then(async function editList() {
          await api.get(`/list/lists`).then(lists => {
            dispatch(addListInfo(lists.data));
          });

          setModal(true);

          addToast({
            type: 'success',
            title: `Lista '${data.name}' editada`,
          });
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let description;

          if (errors.name) {
            description = errors.name;
          }
          if (errors.startDate) {
            description = errors.startDate;
          }

          if (errors.endDate) {
            description = errors.endDate;
          }

          addToast({
            type: 'alert',
            title: 'Preencha todos os campos corretamente',
            description,
          });

          formRef.current?.setErrors(errors);

          return;
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, dispatch],
  );

  return (
    <Modal
      title="Adicionar novo produto"
      modalSize="50%"
      modalClose={modal}
      closable
    >
      <Container>
        {productSelected?.name ? (
          <>
            <div className="selectedProduct">
              <span>
                <FiCheckCircle /> {productSelected?.name} (
                {productSelected?.size})
              </span>
              <button type="button" onClick={() => setProductSelected({})}>
                <FiArrowLeft /> Voltar
              </button>
            </div>

            <Form
              ref={formRef}
              onSubmit={handleFormSubmit}
              className="addproducts"
            >
              <Divisor>
                <Input
                  name="productId"
                  value={productSelected?.id}
                  display={false}
                  readOnly
                />
                <Input name="listId" value={listId} display={false} readOnly />
              </Divisor>
              <Divisor>
                <h3 style={{ marginTop: 20 }}>Detalhes do Produto:</h3>
              </Divisor>
              <Divisor style={{ marginTop: 30 }}>
                <div className="double">
                  <h5>Nome:</h5>
                  <Input
                    className="singleList-productSearch"
                    name="name"
                    icon={FiBox}
                    defaultValue={productSelected?.name}
                    placeholder="nome"
                  />
                </div>
                <div className="double">
                  <h5>Descrição:</h5>
                  <Input
                    className="singleList-productSearch"
                    name="descricao"
                    icon={MdDescription}
                    placeholder="Descrição"
                  />
                </div>
              </Divisor>
              <Divisor style={{ marginTop: 30 }}>
                <div className="third">
                  <h5>Valor:</h5>
                  <Input
                    className="singleList-productSearch"
                    name="valor"
                    mask="money"
                    icon={FaRegMoneyBillAlt}
                    defaultValue={productSelected?.default_price.replace(
                      '.',
                      ',',
                    )}
                    placeholder="Valor"
                  />
                </div>
                <div className="third">
                  <h5>Valor com desconto:</h5>
                  <Input
                    className="singleList-productSearch"
                    name="valor_desconto"
                    mask="money"
                    icon={FaRegMoneyBillAlt}
                    defaultValue={productSelected?.valor_desconto}
                    placeholder="Valor com desconto"
                  />
                </div>
                <div className="third">
                  <h5>Qtd:</h5>
                  <Input
                    className="singleList-productSearch"
                    name="qtd"
                    type="number"
                    mask="number"
                    step="1"
                    icon={FiCopy}
                    placeholder="Quantidade"
                  />
                </div>
              </Divisor>
              <Divisor style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SimpleButton loading={loading} type="submit">
                  Adicionar
                </SimpleButton>
              </Divisor>
            </Form>
          </>
        ) : (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Divisor>
              <h3>Buscar Produto:</h3>
              <div className="w-100">
                <Input
                  className="singleList-productSearch"
                  name="name"
                  icon={FiBox}
                  placeholder="Digite o nome do produto no estoque"
                  onChange={e => {
                    const { value } = e.currentTarget;
                    handleInputChange(value, allProducts);
                  }}
                />
              </div>
            </Divisor>

            <Divisor>
              {inputSelected.length > 0 && inputSelected.length < 100 && (
                <ul className="productsListTitle">
                  <li>
                    <div>Nome</div>
                    <div className="tamanho">Tamanho</div>
                    <div>Nome cientifico</div>
                    <div>Familia</div>
                  </li>
                </ul>
              )}
              {inputSelected.length > 0 && inputSelected.length < 100 ? (
                <ul className="productsList">
                  {inputSelected.map((obj: any) => {
                    return (
                      <button
                        key={obj.id}
                        type="button"
                        onClick={() => {
                          setProductSelected(obj);
                        }}
                      >
                        <li>
                          <div>{obj.name ? obj.name.toUpperCase() : '-'}</div>
                          <div className="tamanho">
                            {obj.size ? obj.size.toUpperCase() : '-'}
                          </div>
                          <div>
                            {obj.scientific_name
                              ? obj.scientific_name.toUpperCase()
                              : '-'}
                          </div>
                          <div>
                            {obj.family ? obj.family.toUpperCase() : '-'}
                          </div>
                        </li>
                      </button>
                    );
                  })}
                </ul>
              ) : (
                <div
                  style={{ marginTop: 30, width: '100%', textAlign: 'center' }}
                >
                  {!inputSelected ||
                  inputSelected.length > 100 ||
                  inputFirst <= 0
                    ? 'Continue digitando para encontrar produtos...'
                    : 'Nenhum produto encontrado :('}
                </div>
              )}
            </Divisor>
          </Form>
        )}
      </Container>
    </Modal>
  );
};

export default AddListProduct;
