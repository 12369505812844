/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';

import { ISpecificListState } from './types';

const INITIAL_STATE: ISpecificListState = {
  list: {},
  loading: true,
};

const userInfo: Reducer<ISpecificListState> = (
  state = INITIAL_STATE,
  action,
) => {
  // eslint-disable-next-line consistent-return
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_LIST_INFO': {
        const { apiListInfo } = action.payload;

        draft = {
          list: apiListInfo,
          loading: false,
        };
        // console.log('teste');
        // console.log(draft.list);

        return draft;
      }
      case 'REMOVE_LIST_INFO': {
        draft = {
          list: {},
          loading: false,
        };

        return draft;
      }

      default: {
        // return INITIAL_STATE;
        break;
      }
    }
  });
};

export default userInfo;
