import {
  Box,
  Flex,
} from '@chakra-ui/react'
import MUIDataTable from 'mui-datatables';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { OrdersContext } from '../../context/Orders';
import ActionButton from '../Table/ActionButton';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';

import { options, columns } from './tableConfig'
import { HiEye } from 'react-icons/hi';

const columnsConfig = [
  columns.id,
  columns.number,
  columns.autor,
  columns.feitoEm,
  columns.formaPag,
  columns.atualizacao,
  {
    name: '',
    options: {
      filter: false,
      draggable: false,
      searchable: false,
      viewColumns: false,
      customBodyRender: (value: any, tableMeta: any) => {
        return (
          <div className="table-icons">
            <Link
              to={`/dashboard/order-detail?id=${tableMeta.rowData[0]}`}
              data-tip="Ver Pedido"
            >
              <ActionButton color="#bbb">
                <HiEye />
              </ActionButton>
            </Link>

            <ReactTooltip html />
          </div>
        );
      },
    },
  },
];

export function OrdersTable() {

  const { orders } = useContext(OrdersContext)
  const [orderHistory, setOrderHistory] = useState<any[]>([]);



  useEffect(() => {
    console.log(orders);
    const tablePendentContent = orders?.sort((a:any, b:any) => {
      var keyA = new Date(a.updated_at),
      keyB = new Date(b.updated_at);
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    }).map(function pendentOrder(
      order: any,
    ) {
      const timeZone = 'America/Sao_Paulo';
      const orderDate = format(
        utcToZonedTime(order.created_at, timeZone),
        "dd'/'MM'/'yyyy' às ' HH:mm'h'",
        {
          locale: ptBR,
        },
      );

      const orderUpdatedDate = format(
        utcToZonedTime(order.updated_at, timeZone),
        "dd'/'MM'/'yyyy' às ' HH:mm'h'",
        {
          locale: ptBR,
        },
      );

      const orderNumber = order.order_number;
      const rest = 5 - String(orderNumber).length;

      console.log(order)

      return [
        order.id,
        '0'.repeat(rest > 0 ? rest : 0) + order.order_number,
        order.user_info.name,
        orderDate,
        order.tipo_pagamento_info?.name,
        orderUpdatedDate,
      ];
    }) ?? [];

    setOrderHistory(tablePendentContent);
  }, [])


  return (
    <Flex
      bg="white"
      borderRadius="10px"
      pt={5}
      justifyContent="space-between"
      flexDir="column"
      boxShadow="lg"
    >


            <MUIDataTable
                title=""
                data={orderHistory}
                columns={columnsConfig}
                options={options}
              />
    </Flex>
  )
}
